








import Component, { mixins } from 'vue-class-component'
import BaseComponentMixin from '@/components/BaseComponentMixin'

import UserInformationComponent from '@/components/core/UserInformationComponent.vue'
import LoadingInformationComponent from '@/components/LoadingInformationComponent.vue'

@Component({
    components: {
        UserInformationComponent,
        LoadingInformationComponent
    }
})
export default class MainRegionComponent extends mixins(BaseComponentMixin) {
}

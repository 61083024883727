import { SubsidiariesUiState } from '@/states/SubsidiariesUiState'

class SubsidiariesUiStateService {
  private readonly _subsidiariesUiState : SubsidiariesUiState

  private constructor () {
      this._subsidiariesUiState = SubsidiariesUiState.getInstance()
  }

  private static _instance : SubsidiariesUiStateService

  public static getInstance () : SubsidiariesUiStateService {
      if (!SubsidiariesUiStateService._instance) {
          SubsidiariesUiStateService._instance = new SubsidiariesUiStateService()
      }

      return SubsidiariesUiStateService._instance
  }

  get subsidiariesUiState (): SubsidiariesUiState {
      return this._subsidiariesUiState
  }
}

export { SubsidiariesUiStateService }

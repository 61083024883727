import { IAuditedEntity, IPage } from '@/types/BasicRemoteTypes'

interface CreateSubsidiaryModel {
  name : string
  subsidiarySource : ESubsidiarySource
  edataId : number | null
}

interface UpdateSubsidiaryModel {
  id : string
  name : string
  subsidiarySource : ESubsidiarySource
  edataId : number | null
}

enum ESubsidiarySource
{
  Edata='Edata',
  Shoplocator='Shoplocator'
}

interface Subsidiary extends IAuditedEntity {
  name: string
  subsidiarySource : ESubsidiarySource
  edataId : number | null
}

interface SubsidiaryPage extends IPage<Subsidiary>{}

export { CreateSubsidiaryModel, UpdateSubsidiaryModel, ESubsidiarySource, Subsidiary, SubsidiaryPage }

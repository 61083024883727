import { UserService } from '@/services/remote/core/UsersService'
import { SubsidiariesCoreService } from '@/services/remote/core/SubsidiariesCoreService'
import { AbstractServiceFactory } from '@/services/remote/AbstractServiceFactory'

class CoreServiceFactory extends AbstractServiceFactory {
  // @ts-ignore
  private _userService: UserService

  // @ts-ignore
  private _subsidiariesService: SubsidiariesCoreService

  private constructor () {
      super('/remote/core')
  }

  private static _instance: CoreServiceFactory

  static getInstance (): CoreServiceFactory {
      if (!CoreServiceFactory._instance) {
          CoreServiceFactory._instance = new CoreServiceFactory()
      }

      return CoreServiceFactory._instance
  }

  get userService (): UserService {
      if (!this._userService) {
          this._userService = new UserService(this.axiosInstance)
      }

      return this._userService
  }

  get subsidiariesService (): SubsidiariesCoreService {
      if (!this._subsidiariesService) {
          this._subsidiariesService = new SubsidiariesCoreService(this.axiosInstance)
      }

      return this._subsidiariesService
  }
}

export { CoreServiceFactory }

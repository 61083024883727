import { addAll, clearArray } from '@/utils/ArrayUtil'
import { CustomerGroupListSettings, CustomerListSettings } from '@/types/shoplocator/ShopLocatorListSettingsTypes'
import { CustomerEditModeState, CustomerGroupEditModeState } from '@/types/shoplocator/EditModeTypes'
import { Subsidiary } from '@/types/subsidiaries/SubsidiariesTypes'
import { CustomerCreateModeState, CustomerGroupCreateModeState } from '@/types/shoplocator/CreateModeTypes'
import { isSubsidiaryEag } from '@/utils/SubsidiariesUtil'

class ShopLocatorUiState {
  private _assignedSubsidiary : Subsidiary | null
  private readonly _authorizedSubsidiaries : Subsidiary[]
  private _selectedSubsidiary : Subsidiary | null = null

  private _isCustomerGroupsMode : boolean

  private readonly _customerGroupListSettings : CustomerGroupListSettings
  private readonly _customerListSettings : CustomerListSettings
  private readonly _customerOfCustomerGroupListSettings : CustomerListSettings

  private readonly _customerGroupCreateModeState : CustomerGroupCreateModeState
  private readonly _customerGroupEditModeState : CustomerGroupEditModeState

  private readonly _customerCreateModeState : CustomerCreateModeState
  private readonly _customerEditModeState : CustomerEditModeState
  private readonly _customerOfCustomerGroupEditModeState : CustomerEditModeState

  private constructor () {
      this._assignedSubsidiary = null
      this._authorizedSubsidiaries = []
      this._selectedSubsidiary = null
      this._isCustomerGroupsMode = false

      this._customerGroupListSettings = new CustomerGroupListSettings()
      this._customerListSettings = new CustomerListSettings()
      this._customerOfCustomerGroupListSettings = new CustomerListSettings()

      this._customerGroupCreateModeState = new CustomerGroupCreateModeState()
      this._customerGroupEditModeState = new CustomerGroupEditModeState()

      this._customerCreateModeState = new CustomerCreateModeState()
      this._customerEditModeState = new CustomerEditModeState()
      this._customerOfCustomerGroupEditModeState = new CustomerEditModeState()
  }

  private static _instance : ShopLocatorUiState

  static getInstance ():ShopLocatorUiState {
      if (!ShopLocatorUiState._instance) {
          ShopLocatorUiState._instance = new ShopLocatorUiState()
      }

      return ShopLocatorUiState._instance
  }

  get assignedSubsidiary () : Subsidiary {
      if (this._assignedSubsidiary == null) throw new Error('assigned subsidiary is null')
      return this._assignedSubsidiary
  }

  set assignedSubsidiary (value : Subsidiary) {
      this._assignedSubsidiary = value
  }

  get authorizedSubsidiaries () : Subsidiary[] {
      return this._authorizedSubsidiaries
  }

  set authorizedSubsidiaries (value : Subsidiary[]) {
      clearArray(this._authorizedSubsidiaries)
      addAll(this._authorizedSubsidiaries, value)
  }

  get selectedSubsidiaryOptional () : Subsidiary | null {
      return this._selectedSubsidiary
  }

  set selectedSubsidiaryOptional (value : Subsidiary | null) {
      this._selectedSubsidiary = value
  }

  get selectedSubsidiary () : Subsidiary {
      if (this._selectedSubsidiary === null) throw new Error('selected subsidiary is null')
      return this._selectedSubsidiary
  }

  set selectedSubsidiary (value : Subsidiary) {
      this._selectedSubsidiary = value
  }

  get isEag () : boolean {
      return isSubsidiaryEag(this._selectedSubsidiary)
  }

  get isCustomerGroupsMode () : boolean {
      return this._isCustomerGroupsMode
  }

  set isCustomerGroupsMode (value :boolean) {
      this._isCustomerGroupsMode = value
  }

  get customerGroupListSettings (): CustomerGroupListSettings {
      return this._customerGroupListSettings
  }

  get customerListSettings (): CustomerListSettings {
      return this._customerListSettings
  }

  get customerOfCustomerGroupListSettings (): CustomerListSettings {
      return this._customerOfCustomerGroupListSettings
  }

  get customerGroupCreateModeState () : CustomerGroupCreateModeState {
      return this._customerGroupCreateModeState
  }

  get customerGroupEditModeState () : CustomerGroupEditModeState {
      return this._customerGroupEditModeState
  }

  get customerCreateModeState (): CustomerCreateModeState {
      return this._customerCreateModeState
  }

  get customerEditModeState () : CustomerEditModeState {
      return this._customerEditModeState
  }

  get customerOfCustomerGroupEditModeState () : CustomerEditModeState {
      return this._customerOfCustomerGroupEditModeState
  }
}

export { ShopLocatorUiState }

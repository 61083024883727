import { AbstractCreateModeState, CreateModeState } from '@/types/CreateModeTypes'
import { CreateSubsidiaryModel, ESubsidiarySource } from '@/types/subsidiaries/SubsidiariesTypes'

class SubsidiaryCreateModeState extends AbstractCreateModeState implements CreateModeState {
  private _createSubsidiaryModel : CreateSubsidiaryModel | null

  constructor () {
      super()
      this._createSubsidiaryModel = null
  }

  get createSubsidiaryModel (): CreateSubsidiaryModel | null {
      return this._createSubsidiaryModel
  }

  initialize () {
      this._createSubsidiaryModel = { name: '', subsidiarySource: ESubsidiarySource.Shoplocator, edataId: null }

      this.originalItemState = this.getCurrentItemState()
  }

  clearState () {
      super.clearState()
      this._createSubsidiaryModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._createSubsidiaryModel === null ? null : JSON.stringify(this._createSubsidiaryModel)
  }
}

export {
    SubsidiaryCreateModeState
}








import Component, { mixins } from 'vue-class-component'
import BasePageMixin from '@/pages/BasePageMixin'

import DashboardComponent from '@/components/dashboard/DashboardComponent.vue'

@Component({
    components: {
        DashboardComponent
    }
})
export default class HomePage extends mixins(BasePageMixin) {

}

import { AbstractSearchAndFilterableListSettingsImpl, ListFilterSettings } from '@/types/ListSettingsTypes'
import { AbstractSearchableSliceSettingsImpl } from '@/types/SliceSettingsTypes'
import { EQuickUpdateProgressStatus } from '@/types/awms/approvement_list/quick_update/QuickUpdateTypes'

class QuickUpdateOverviewListSettings extends AbstractSearchAndFilterableListSettingsImpl implements QuickUpdateListFilterSettings {
  private _quickUpdateOverviewFilter :QuickUpdateOverviewFilter

  constructor () {
      super({
          sortBy: ['createdAt'],
          sortDesc: [true]
      })

      this._quickUpdateOverviewFilter = {
          createdDateRangeFilter: [],
          closedDateRangeFilter: [],
          progressStatusFilter: EQuickUpdateProgressStatus.NoValue
      }
  }

  get quickUpdateOverviewFilter () : QuickUpdateOverviewFilter {
      return this._quickUpdateOverviewFilter
  }

  get closedDateRangeFilter (): string[] {
      return this._quickUpdateOverviewFilter.closedDateRangeFilter
  }

  set closedDateRangeFilter (value: string[]) {
      this._quickUpdateOverviewFilter.closedDateRangeFilter = value
  }

  get progressStatusFilter (): EQuickUpdateProgressStatus {
      return this._quickUpdateOverviewFilter.progressStatusFilter
  }

  set progressStatusFilter (value: EQuickUpdateProgressStatus) {
      this._quickUpdateOverviewFilter.progressStatusFilter = value
  }

  get createdDateRangeFilter (): string[] {
      return this._quickUpdateOverviewFilter.createdDateRangeFilter
  }

  set createdDateRangeFilter (value: string[]) {
      this._quickUpdateOverviewFilter.createdDateRangeFilter = value
  }

  get hasFilters () : boolean {
      return !this.hasNoProgressStatusFilterSet || !this.hasNoCreatedDateRangeFilterSet || !this.hasNoClosedDateRangeFilterSet
  }

  get hasNoProgressStatusFilterSet () : boolean {
      return this._quickUpdateOverviewFilter.progressStatusFilter === EQuickUpdateProgressStatus.NoValue
  }

  get hasNoCreatedDateRangeFilterSet () : boolean {
      return this._quickUpdateOverviewFilter.createdDateRangeFilter === []
  }

  get hasNoClosedDateRangeFilterSet () : boolean {
      return this._quickUpdateOverviewFilter.closedDateRangeFilter === []
  }

  clearFilters (): void {
      this._quickUpdateOverviewFilter = {
          closedDateRangeFilter: [],
          createdDateRangeFilter: [],
          progressStatusFilter: EQuickUpdateProgressStatus.NoValue
      }
  }
}

class QuickUpdateArticleListSettings extends AbstractSearchableSliceSettingsImpl {
    constructor () {
        super({
            sortBy: ['itemNo'],
            sortDesc: [true]
        },
        {
            itemsPerPageOptions: [5, 10, 25, 50],
            pageText: ''
        })
    }
}

interface QuickUpdateListFilterSettings extends ListFilterSettings{
  progressStatusFilter : EQuickUpdateProgressStatus
}

interface QuickUpdateOverviewFilter {
progressStatusFilter :EQuickUpdateProgressStatus
createdDateRangeFilter :string[]
closedDateRangeFilter :string[]
}

export { QuickUpdateArticleListSettings, QuickUpdateOverviewFilter, QuickUpdateOverviewListSettings }

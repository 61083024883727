import { AbstractServiceFactory } from '@/services/remote/AbstractServiceFactory'
import { SubsidiariesService } from '@/services/remote/subsidiaries/SubsidiariesService'

class SubsidiariesServiceFactory extends AbstractServiceFactory {
  // @ts-ignore
  private _subsidiariesService : SubsidiariesService

  private constructor () {
      super('/remote/subsidiaries')
  }

  private static _instance : SubsidiariesServiceFactory

  static getInstance () : SubsidiariesServiceFactory {
      if (!SubsidiariesServiceFactory._instance) {
          SubsidiariesServiceFactory._instance = new SubsidiariesServiceFactory()
      }

      return SubsidiariesServiceFactory._instance
  }

  get subsidiariesService (): SubsidiariesService {
      if (!this._subsidiariesService) {
          this._subsidiariesService = new SubsidiariesService(this.axiosInstance)
      }

      return this._subsidiariesService
  }
}

export { SubsidiariesServiceFactory }

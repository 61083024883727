import { AbstractCreateModeState, CreateModeState } from '@/types/CreateModeTypes'
import { ChannelWithAssignedUsers } from '@/types/awms/channel/ChannelTypes'
import { TeoEventService } from '@/services/TeoEventService'
import { CreateTeoModel, PersistenceCreateTeoModel } from '@/types/awms/approvement_list/teo/TeoTypes'
import { TeoOverviewListSettings, TeoRelevantArticleListSettings } from '@/types/awms/approvement_list/teo/TeoListSettingsTypes'

class TeoOverviewModeState extends AbstractCreateModeState implements CreateModeState {
  private _filterByCurrentUser: boolean
  private _listSettings: TeoOverviewListSettings

  constructor () {
      super()
      this._filterByCurrentUser = true
      this._listSettings = new TeoOverviewListSettings()
  }

  initialize () {
      this._listSettings = new TeoOverviewListSettings()
      this.originalItemState = this.getCurrentItemState()
  }

  set filterByCurrentUser (value: boolean) {
      this._filterByCurrentUser = value
  }

  get filterByCurrentUser (): boolean {
      return this._filterByCurrentUser
  }

  get listSettings (): TeoOverviewListSettings {
      return this._listSettings
  }

  clearState () {
      super.clearState()
      this._filterByCurrentUser = true
  }

  protected getCurrentItemState (): string | null {
      return this._filterByCurrentUser === null ? null : JSON.stringify(this._filterByCurrentUser)
  }
}

export { TeoOverviewModeState }

import { AbstractSearchableListSettingsImpl } from '@/types/ListSettingsTypes'

class AssortmentDetailListSettings extends AbstractSearchableListSettingsImpl {
    constructor () {
        super({
            sortBy: ['name'],
            sortDesc: [false]
        })
    }
}

export { AssortmentDetailListSettings }

import { AbstractCreateModeState, CreateModeState } from '@/types/CreateModeTypes'
import { QuickUpdateOverviewListSettings } from '@/types/awms/approvement_list/quick_update/QuickUpdateListSettingsTypes'
import { QuickUpdateOverview } from '@/types/awms/approvement_list/quick_update/QuickUpdateTypes'

class QuickUpdateOverviewModeState extends AbstractCreateModeState implements CreateModeState {
  private _quickUpdateOverviewListSettings: QuickUpdateOverviewListSettings
  private _quickUpdates: QuickUpdateOverview[] = []

  constructor () {
      super()
      this._quickUpdateOverviewListSettings = new QuickUpdateOverviewListSettings()
      this._quickUpdates = []
  }

  initialize () {
      this._quickUpdateOverviewListSettings = new QuickUpdateOverviewListSettings()
      this._quickUpdates = []
      this.originalItemState = this.getCurrentItemState()
  }

  get quickUpdates (): QuickUpdateOverview[] {
      return this._quickUpdates
  }

  set quickUpdates (value: QuickUpdateOverview[]) {
      this._quickUpdates = value
  }

  get quickUpdateOverviewListSettings (): QuickUpdateOverviewListSettings {
      return this._quickUpdateOverviewListSettings
  }

  clearState () {
      super.clearState()
  }

  protected getCurrentItemState (): string | null {
      return null
  }
}

export { QuickUpdateOverviewModeState }

import { AdminRole, EditorRole, EditorExtendedRole, AssortmentManagerRole, TeoReceiverRole } from '@/definitions/EwmsCoreDefinitions'
import { IPage } from '@/types/BasicRemoteTypes'

function roleToOrdinal (role: string) {
    if (role === undefined || role === null) return -1

    switch (role) {
    case AdminRole: return 5
    case AssortmentManagerRole: return 4
    case TeoReceiverRole: return 3
    case EditorExtendedRole: return 2
    case EditorRole: return 1
    default: return -1
    }
}

interface UserInfo {
  id: string
  username: string
  firstName: string | null
  lastName: string | null
  email: string | null
  subsidiary: string | null
  moduleAccesses: string[] | null
  extendedAccesses: string[] | null
}

interface UserInfoPage extends IPage<UserInfo> {}

interface User extends UserInfo {
  roles: string[] | null
}

interface UserWithModuleRoles extends UserInfo {
  roles: ModuleAndRoles[] | null
}

interface ModuleAndRoles {
  module: string
  roles: string[]
}

export { UserInfo, UserInfoPage, User, UserWithModuleRoles, roleToOrdinal, ModuleAndRoles }

import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { PotentialList } from '@/types/awms/PotentialListTypes'
import { TeoRelevantArticleSlice } from '@/types/awms/approvement_list/teo/TeoTypes'
import { PaginationQuery } from '@/types/BasicRemoteTypes'

class PotentialListService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/v1/potential-list/', axiosInstance)
    }

    get (channelName: string): Promise<PotentialList> {
        const url = this.contextPath + encodeURIComponent(channelName)
        return new Promise<PotentialList>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    forCreate (channelIds: string[], paginationQuery: PaginationQuery, search: string): Promise<TeoRelevantArticleSlice> {
        const paginationQueryString = paginationQuery.asQueryString(false)

        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        let channelIdsQueryString = ''
        for (const channelId of channelIds) {
            channelIdsQueryString += '&'
            channelIdsQueryString += `channelIds=${encodeURIComponent(channelId)}`
        }

        const url = this.contextPath + 'for-create' + paginationQueryString + searchQueryString + channelIdsQueryString
        return new Promise<TeoRelevantArticleSlice>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    countForCreate (channelIds: string[], search: string): Promise<number> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        let channelIdsQueryString = ''
        for (const channelId of channelIds) {
            channelIdsQueryString += '&'
            channelIdsQueryString += `channelIds=${encodeURIComponent(channelId)}`
        }

        const url = this.contextPath + 'count-for-create?' + searchQueryString + channelIdsQueryString
        return new Promise<number>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    forDraft (channelIds: string[], approvementListIds: string[], paginationQuery: PaginationQuery, search: string): Promise<TeoRelevantArticleSlice> {
        const paginationQueryString = paginationQuery.asQueryString(false)

        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        let channelIdsQueryString = ''
        for (const channelId of channelIds) {
            channelIdsQueryString += '&'
            channelIdsQueryString += `channelIds=${encodeURIComponent(channelId)}`
        }

        let approvementListIdsQueryString = ''
        for (const approvementListId of approvementListIds) {
            approvementListIdsQueryString += '&'
            approvementListIdsQueryString += `approvementListIds=${encodeURIComponent(approvementListId)}`
        }

        const url = this.contextPath + '/for-draft' + paginationQueryString + searchQueryString + channelIdsQueryString + approvementListIdsQueryString
        return new Promise<TeoRelevantArticleSlice>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    countForDraft (channelIds: string[], approvementListIds: string[], search: string): Promise<number> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        let channelIdsQueryString = ''
        for (const channelId of channelIds) {
            channelIdsQueryString += '&'
            channelIdsQueryString += `channelIds=${encodeURIComponent(channelId)}`
        }

        let approvementListIdsQueryString = ''
        for (const approvementListId of approvementListIds) {
            approvementListIdsQueryString += '&'
            approvementListIdsQueryString += `approvementListIds=${encodeURIComponent(approvementListId)}`
        }

        const url = this.contextPath + '/count-for-draft?' + searchQueryString + channelIdsQueryString + approvementListIdsQueryString
        return new Promise<number>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { PotentialListService }

import { ChannelCreateModeState } from '@/types/awms/channel/ChannelCreateModeTypes'
import { ChannelEditModeState } from '@/types/awms/channel/ChannelEditModeTypes'
import { TeoCreateModeState } from '@/types/awms/approvement_list/teo/TeoCreateModeTypes'
import { TeoEditModeState } from '@/types/awms/approvement_list/teo/TeoEditModeTypes'
import { TeoReviewModeState } from '@/types/awms/approvement_list/teo/TeoReviewModeTypes'
import { TeoChangeReceiversModeState } from '@/types/awms/approvement_list/teo/TeoChangeReceiversModeTypes'
import { TeoProcessModeState } from '@/types/awms/approvement_list/teo/TeoProcessModeTypes'
import { QuickUpdateCreateModeState } from '@/types/awms/approvement_list/quick_update/QuickUpdateCreateModeTypes'
import { TeoOverviewModeState } from '@/types/awms/approvement_list/teo/TeoOverviewModeTypes'
import { QuickUpdateOverviewModeState } from '@/types/awms/approvement_list/quick_update/QuickUpdateOverviewModeTypes'
import { AssortmentDetailModeState } from '@/types/awms/assortment/AssortmentDetailModeTypes'
import { PlannedAssortmentOverviewModeState } from '@/types/awms/planned_assortments/PlannedAssortmentOverviewModeTypes'

class AwmsUiState {
  private readonly _channelCreateModeState : ChannelCreateModeState
  private readonly _channelEditModeState : ChannelEditModeState

  private readonly _teoOverviewModeState : TeoOverviewModeState
  private readonly _teoCreateModeState : TeoCreateModeState
  private readonly _teoEditModeState : TeoEditModeState
  private readonly _teoChangeReceiversModeState : TeoChangeReceiversModeState
  private readonly _teoProcessModeState : TeoProcessModeState
  private readonly _teoReviewModeState : TeoReviewModeState

  private readonly _plannedAssortmentOverviewModeState: PlannedAssortmentOverviewModeState

  private readonly _assortmentDetailModeState: AssortmentDetailModeState
  private readonly _assortmentHistoryDetailModeState: AssortmentDetailModeState

  private readonly _quickUpdateOverviewModeState: QuickUpdateOverviewModeState
  private readonly _quickUpdateCreateModeState: QuickUpdateCreateModeState

  private static _instance : AwmsUiState

  private constructor () {
      this._channelCreateModeState = new ChannelCreateModeState()
      this._channelEditModeState = new ChannelEditModeState()
      this._teoOverviewModeState = new TeoOverviewModeState()
      this._teoCreateModeState = new TeoCreateModeState()
      this._teoEditModeState = new TeoEditModeState()
      this._teoChangeReceiversModeState = new TeoChangeReceiversModeState()
      this._teoProcessModeState = new TeoProcessModeState()
      this._teoReviewModeState = new TeoReviewModeState()
      this._quickUpdateOverviewModeState = new QuickUpdateOverviewModeState()
      this._quickUpdateCreateModeState = new QuickUpdateCreateModeState()
      this._assortmentDetailModeState = new AssortmentDetailModeState()
      this._assortmentHistoryDetailModeState = new AssortmentDetailModeState()
      this._plannedAssortmentOverviewModeState = new PlannedAssortmentOverviewModeState()
  }

  static getInstance ():AwmsUiState {
      if (!AwmsUiState._instance) {
          AwmsUiState._instance = new AwmsUiState()
      }

      return AwmsUiState._instance
  }

  get channelCreateModeState (): ChannelCreateModeState {
      return this._channelCreateModeState
  }

  get channelEditModeState (): ChannelEditModeState {
      return this._channelEditModeState
  }

  get teoCreateModeState (): TeoCreateModeState {
      return this._teoCreateModeState
  }

  get teoEditModeState (): TeoEditModeState {
      return this._teoEditModeState
  }

  get teoChangeReceiversModeState (): TeoChangeReceiversModeState {
      return this._teoChangeReceiversModeState
  }

  get teoProcessModeState (): TeoProcessModeState {
      return this._teoProcessModeState
  }

  get teoReviewModeState (): TeoReviewModeState {
      return this._teoReviewModeState
  }

  get teoOverviewModeState (): TeoOverviewModeState {
      return this._teoOverviewModeState
  }

  get quickUpdateCreateModeState () : QuickUpdateCreateModeState {
      return this._quickUpdateCreateModeState
  }

  get quickUpdateOverviewModeState (): QuickUpdateOverviewModeState {
      return this._quickUpdateOverviewModeState
  }

  get assortmentDetailModeState (): AssortmentDetailModeState {
      return this._assortmentDetailModeState
  }

  get assortmentHistoryDetailModeState (): AssortmentDetailModeState {
      return this._assortmentHistoryDetailModeState
  }

  get plannedAssortmentOverviewModeState (): PlannedAssortmentOverviewModeState {
      return this._plannedAssortmentOverviewModeState
  }
}

export { AwmsUiState }

import { AbstractCreateModeState, CreateModeState } from '@/types/CreateModeTypes'
import { ChannelWithAssignedUsers } from '@/types/awms/channel/ChannelTypes'
import { ArticleSummaryWithComment, CreateQuickUpdateModel, PersistenceArticleWithComment, PersistenceCreateQuickUpdateModel, QuickUpdateType } from '@/types/awms/approvement_list/quick_update/QuickUpdateTypes'
import { QuickUpdateEventService } from '@/services/QuickUpdateEventService'
import { QuickUpdateArticleListSettings } from '@/types/awms/approvement_list/quick_update/QuickUpdateListSettingsTypes'
import { PersistencePotentialListEntryWithComment, TeoRelevantArticle } from '@/types/awms/approvement_list/teo/TeoTypes'

class QuickUpdateCreateModeState extends AbstractCreateModeState implements CreateModeState {
  private _createQuickUpdateModel : CreateQuickUpdateModel | null
  private _channels : ChannelWithAssignedUsers[]
  private readonly _quickUpdateEventService: QuickUpdateEventService

  constructor () {
      super()
      this._createQuickUpdateModel = null
      this._channels = []
      this._quickUpdateEventService = QuickUpdateEventService.getInstance()
  }

  get createQuickUpdateModel (): CreateQuickUpdateModel | null {
      return this._createQuickUpdateModel
  }

  initialize () {
      this._createQuickUpdateModel = {
          selectedType: null,
          channel: null,
          selectedArticles: [],
          potentialListArticles: [],
          allArticles: [],
          assortmentArticles: [],
          allArticlesListSettings: new QuickUpdateArticleListSettings(),
          assortmentArticlesListSettings: new QuickUpdateArticleListSettings(),
          potentialListArticlesListSettings: new QuickUpdateArticleListSettings()
      }
      this.originalItemState = this.getCurrentItemState()
  }

  get createQuickUpdateModelForPersistence (): PersistenceCreateQuickUpdateModel | null {
      if (this._createQuickUpdateModel === null) return null
      return {
          channelId: this._createQuickUpdateModel.channel?.id!,
          articles: this._createQuickUpdateModel.selectedArticles.map(selectedArticle => this.createPersistenceArticleWithComment(selectedArticle)),
          type: this.convertNumberToType(this._createQuickUpdateModel.selectedType!)
      }
  }

  createPersistenceArticleWithComment (article: ArticleSummaryWithComment) : PersistenceArticleWithComment {
      return {
          id: article.id,
          comment: article.comment
      }
  }

  set channels (value: ChannelWithAssignedUsers[]) {
      this._channels = value
  }

  get channels () : ChannelWithAssignedUsers[] {
      return this._channels
  }

  get quickUpdateEventService () {
      return this._quickUpdateEventService
  }

  clearState () {
      super.clearState()
      this._createQuickUpdateModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._createQuickUpdateModel === null ? null : JSON.stringify(this._createQuickUpdateModel)
  }

  public convertNumberToType (typeNumber :number) : QuickUpdateType {
      switch (typeNumber) {
      case 0: return QuickUpdateType.add
      case 1: return QuickUpdateType.remove
      default: throw new TypeError('Can#t convert number ' + typeNumber + ' to QuickUpdateType')
      }
  }
}

export { QuickUpdateCreateModeState }

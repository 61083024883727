import { EditTeoModel, PersistenceCreateTeoModel, PersistenceEditTeoModel, PersistencePotentialListEntryWithComment, TeoDraft, TeoRelevantArticle } from '@/types/awms/approvement_list/teo/TeoTypes'
import { AbstractEditModeState, EditModeState } from '@/types/EditModeTypes'
import { TeoRelevantArticleListSettings } from '@/types/awms/approvement_list/teo/TeoListSettingsTypes'
import { ChannelWithAssignedUsers } from '@/types/awms/channel/ChannelTypes'
import { TeoEventService } from '@/services/TeoEventService'

class TeoEditModeState extends AbstractEditModeState implements EditModeState {
  private _editTeoModel : EditTeoModel | null
  private _channels : ChannelWithAssignedUsers[]
  private readonly _teoEventService: TeoEventService
  private _listSettings: TeoRelevantArticleListSettings | null

  constructor () {
      super()
      this._editTeoModel = null
      this._listSettings = null
      this._channels = []
      this._teoEventService = TeoEventService.getInstance()
  }

  get editTeoModel (): EditTeoModel | null {
      return this._editTeoModel
  }

  initialize (approvementLists: TeoDraft[]) {
      this._editTeoModel = {
          selectedChannels: [],
          articles: [],
          relevantArticles: [],
          notRelevantArticles: []
      }

      for (const approvementList of approvementLists) {
        this._editTeoModel!.selectedChannels.push({
            approvementListId: approvementList.id,
            channel: approvementList.assignedChannel,
            teoReceivers: approvementList.assignedTeoReceivers,
            assortmentManagers: approvementList.assignedAssortmentManagers,
            errorAssortmentManager: false,
            errorAssortmentManagerMessage: '',
            errorChannel: false,
            errorChannelMessage: '',
            errorTeoReceiver: false,
            errorTeoReceiverMessage: ''
        })

        this._editTeoModel.relevantArticles.push(...approvementList.relevantApprovementListEntries.map(article => this.createArticle(article, 2)))
        this._editTeoModel.notRelevantArticles.push(...approvementList.revokedApprovementListEntries.map(article => this.createArticle(article, 0)))
      }

      this._listSettings = new TeoRelevantArticleListSettings()
      this.originalItemState = this.getCurrentItemState()
  }

  private createArticle (article: TeoRelevantArticle, relevance: number) : TeoRelevantArticle {
      return {
          id: article.id,
          itemNo: article.itemNo,
          tpgName: article.tpgName,
          description: article.description,
          brand: article.brand,
          relevance: relevance,
          comment: article.comment,
          imageUrl: article.imageUrl,
          channelId: article.channelId,
          approvementListId: article.approvementListId,
          identNo: article.identNo
      }
  }

  get editTeoModelForPersistence (): PersistenceEditTeoModel[] | null {
      if (this._editTeoModel === null) return null

      const approvementLists: PersistenceEditTeoModel[] = []

      for (const channel of this.getExistingApprovementLists()) {
          const approvementList: PersistenceEditTeoModel = {
              approvementListId: channel!.approvementListId!,
              potentialListEntries: this.getPotentListEntriesForChannel(channel.channel!.id),
              ignoredPotentialListEntries: this.getIgnoredPotentListEntriesForChannel(channel.channel!.id),
              assortmentManagerIds: channel.assortmentManagers.map(value => value.id),
              teoReceiverIds: channel.teoReceivers.map(value => value.id)
          }

          approvementLists.push(approvementList)
      }

      return approvementLists
  }

  get createTeoModelForPersistence (): PersistenceCreateTeoModel[] | null {
      if (this._editTeoModel === null) return null

      const approvementLists: PersistenceCreateTeoModel[] = []

      for (const channel of this.getNewApprovementLists()) {
          const approvementList : PersistenceCreateTeoModel = {
              channelId: channel!.channel!.id,
              potentialListEntries: this.getPotentListEntriesForChannel(channel.channel!.id),
              ignoredPotentialListEntries: this.getIgnoredPotentListEntriesForChannel(channel.channel!.id),
              assortmentManagerIds: channel.assortmentManagers.map(value => value.id),
              teoReceiverIds: channel.teoReceivers.map(value => value.id)
          }

          approvementLists.push(approvementList)
      }

      return approvementLists
  }

  getExistingApprovementLists () {
      return this._editTeoModel!.selectedChannels.filter(channel => channel.approvementListId)
  }

  getNewApprovementLists () {
      return this._editTeoModel!.selectedChannels.filter(channel => !channel.approvementListId)
  }

  createPersistencePotentialListEntryWithComment (article: TeoRelevantArticle) : PersistencePotentialListEntryWithComment {
      return {
          id: article.id,
          comment: article.comment
      }
  }

  getPotentListEntriesForApprovementList (approvementListId: string | null): PersistencePotentialListEntryWithComment[] {
      if (approvementListId) {
          return this._editTeoModel!.relevantArticles
              .filter(article => article.approvementListId === approvementListId)
              .map(article => this.createPersistencePotentialListEntryWithComment(article))
      } else {
          return []
      }
  }

  getIgnoredPotentListEntriesForApprovementList (approvementListId: string | null): PersistencePotentialListEntryWithComment[] {
      if (approvementListId) {
          return this._editTeoModel!.notRelevantArticles
              .filter(article => article.approvementListId === approvementListId)
              .map(article => this.createPersistencePotentialListEntryWithComment(article))
      } else {
          return []
      }
  }

  getPotentListEntriesForChannel (channelId: string | null): PersistencePotentialListEntryWithComment[] {
      if (channelId) {
          return this._editTeoModel!.relevantArticles
              .filter(article => article.channelId === channelId)
              .map(article => this.createPersistencePotentialListEntryWithComment(article))
      } else {
          return []
      }
  }

  getIgnoredPotentListEntriesForChannel (channelId: string | null): PersistencePotentialListEntryWithComment[] {
      if (channelId) {
          return this._editTeoModel!.notRelevantArticles
              .filter(article => article.channelId === channelId)
              .map(article => this.createPersistencePotentialListEntryWithComment(article))
      } else {
          return []
      }
  }

  clearState () {
      super.clearState()
      this._editTeoModel = null
      this._listSettings = null
  }

  get listSettings (): TeoRelevantArticleListSettings | null {
      return this._listSettings
  }

  protected getCurrentItemState (): string | null {
      return this._editTeoModel === null ? null : JSON.stringify(this._editTeoModel)
  }

  get channels () : ChannelWithAssignedUsers[] {
      return this._channels
  }

  set channels (value: ChannelWithAssignedUsers[]) {
      this._channels = value
  }

  get teoEventService () {
      return this._teoEventService
  }

  resetArticles () {
    this._editTeoModel!.articles = []
    this.listSettings!.serverItemsLengthUnknown = true
    this.listSettings!.serverItemsLength = 0
    this.listSettings!.dataTablePaginationOptions.page = 1
  }
}

export { TeoEditModeState }

import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import {
    CreateCustomerModel,
    Customer,
    CustomerGroup,
    CustomerWithCityPage,
    ECustomerBlockStatus,
    ECustomerParticipation, UpdateCustomerModel
} from '@/types/shoplocator/ShopLocatorTypes'
import { PaginationQuery } from '@/types/BasicRemoteTypes'

class CustomersService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/rest/v1/subsidiaries/', axiosInstance)
    }

    create (subsidiary: string, model: CreateCustomerModel) : Promise<Customer> {
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customers'

        return new Promise<Customer>((resolve, reject) => {
            this.axiosInstance.post(url, model)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    update (subsidiary : string, model : UpdateCustomerModel) : Promise<Customer> {
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customers/customerGroups/' + encodeURIComponent(model.customerGroupId)

        return new Promise<Customer>((resolve, reject) => {
            this.axiosInstance.put(url, model)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getById (subsidiary: string, customerId: string): Promise<Customer> {
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customers/' + encodeURIComponent(customerId)

        return new Promise<Customer>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getCustomerGroupByCustomerId (subsidiary: string, customerId: string): Promise<CustomerGroup> {
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customers/' + encodeURIComponent(customerId) + '/customerGroup'

        return new Promise<CustomerGroup>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    private generateFilterQueryString (participationFilter : ECustomerParticipation, blockStatusFilter : ECustomerBlockStatus) : string {
        let filterQueryString = ''

        if (participationFilter !== null && participationFilter !== ECustomerParticipation.NoValue) {
            filterQueryString += '&'
            filterQueryString += `participation=${encodeURIComponent(participationFilter)}`
        }

        if (blockStatusFilter !== null && blockStatusFilter !== ECustomerBlockStatus.NoValue) {
            filterQueryString += '&'
            filterQueryString += `blockStatus=${encodeURIComponent(blockStatusFilter)}`
        }

        return filterQueryString
    }

    find (subsidiary: string, participationFilter : ECustomerParticipation, blockStatusFilter : ECustomerBlockStatus, search: string, paginationQuery: PaginationQuery): Promise<CustomerWithCityPage> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customers' + paginationQueryString + searchQueryString + this.generateFilterQueryString(participationFilter, blockStatusFilter)

        return new Promise<CustomerWithCityPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    exportCustomersAsCsv (subsidiary: string, participationFilter : ECustomerParticipation, blockStatusFilter : ECustomerBlockStatus, search: string, paginationQuery: PaginationQuery): Promise<Blob> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(true)

        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customers/export' + paginationQueryString + searchQueryString + this.generateFilterQueryString(participationFilter, blockStatusFilter)

        return new Promise<Blob>((resolve, reject) => {
            this.axiosInstance.get(url, { responseType: 'blob' })
                .then(response => {
                    resolve(new Blob([response.data], { type: 'application/csv' }))
                })
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    findByCustomerGroup (subsidiary: string, participationFilter : ECustomerParticipation, blockStatusFilter : ECustomerBlockStatus, customerGroupId: string, search: string, paginationQuery: PaginationQuery): Promise<CustomerWithCityPage> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customerGroups/' + encodeURIComponent(customerGroupId) + '/customers' + paginationQueryString + searchQueryString + this.generateFilterQueryString(participationFilter, blockStatusFilter)

        return new Promise<CustomerWithCityPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    exportCustomersByCustomerGroupAsCsv (subsidiary: string, participationFilter : ECustomerParticipation, blockStatusFilter : ECustomerBlockStatus, customerGroupId: string, search: string, paginationQuery: PaginationQuery): Promise<Blob> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(true)
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customerGroups/' + encodeURIComponent(customerGroupId) + '/customers/export' + paginationQueryString + searchQueryString + this.generateFilterQueryString(participationFilter, blockStatusFilter)

        return new Promise<Blob>((resolve, reject) => {
            this.axiosInstance.get(url, { responseType: 'blob' })
                .then(response => {
                    resolve(new Blob([response.data], { type: 'application/csv' }))
                })
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { CustomersService }

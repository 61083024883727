import { AbstractEditModeState, EditModeState } from '@/types/EditModeTypes'
import { Subsidiary, UpdateSubsidiaryModel } from '@/types/subsidiaries/SubsidiariesTypes'

class SubsidiaryEditModeState extends AbstractEditModeState implements EditModeState {
  private _subsidiary : Subsidiary | null
  private _updateSubsidiaryModel : UpdateSubsidiaryModel | null

  constructor () {
      super()
      this._subsidiary = null
      this._updateSubsidiaryModel = null
  }

  get subsidiary (): Subsidiary | null {
      return this._subsidiary
  }

  get updateSubsidiaryModel (): UpdateSubsidiaryModel | null {
      return this._updateSubsidiaryModel
  }

  initialize (subsidiary : Subsidiary | null) {
      this._subsidiary = subsidiary
      this._updateSubsidiaryModel = null

      if (this._subsidiary !== null) {
          this._updateSubsidiaryModel = {
              id: this._subsidiary.id,
              name: this._subsidiary.name,
              subsidiarySource: this._subsidiary.subsidiarySource,
              edataId: this._subsidiary.edataId
          }
      }

      this.originalItemState = this.getCurrentItemState()
  }

  clearState () {
      super.clearState()
      this._subsidiary = null
      this._updateSubsidiaryModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._updateSubsidiaryModel === null ? null : JSON.stringify(this._updateSubsidiaryModel)
  }
}

export {
    SubsidiaryEditModeState
}

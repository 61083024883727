import { IPage, ISlice } from '@/types/BasicRemoteTypes'
import { User } from '@/types/awms/UserTypes'
import { ChannelWithAssignedUsers } from '@/types/awms/channel/ChannelTypes'
import { TeoApprovementListEntry, TeoRelevantArticle } from '@/types/awms/approvement_list/teo/TeoTypes'
import { PlannedAssortmentListSettings } from '@/types/awms/planned_assortments/PlannedAssortmentTypes'
import { AbstractListSettingsImpl, AbstractSearchableListSettingsImpl } from '@/types/ListSettingsTypes'
import { QuickUpdateArticleListSettings } from '@/types/awms/approvement_list/quick_update/QuickUpdateListSettingsTypes'

interface CreateQuickUpdateModel {
  selectedType: number | null
  channel:ChannelWithAssignedUsers | null
  allArticles: ArticleSummaryWithComment[]
  potentialListArticles: ArticleSummaryWithComment[]
  assortmentArticles: ArticleSummaryWithComment[]
  selectedArticles: ArticleSummaryWithComment[]

  allArticlesListSettings: QuickUpdateArticleListSettings
  potentialListArticlesListSettings: QuickUpdateArticleListSettings
  assortmentArticlesListSettings: QuickUpdateArticleListSettings
}
interface QuickUpdateOverviewPage extends IPage<QuickUpdateOverview>{}

interface QuickUpdateOverview{
  id: string
  channelName: string
  assortmentManager: User | null
  progressStatus: EQuickUpdateProgressStatus
  createdAt: Date
  closedAt: Date
}
interface ArticleSummarySlice extends ISlice<ArticleSummary>{}
interface ArticleSummary {
  id: string
  itemNo: string
  brand: string
  tpgName: string
  description: string
  identNo: string
  imageUrl: string
}

interface ArticleSummaryWithComment extends ArticleSummary{
  comment: string
}

interface PersistenceCreateQuickUpdateModel {
  channelId: string
  type:QuickUpdateType
  articles: PersistenceArticleWithComment[]
}

interface QuickUpdateApprovementListEntry {
  id: string
  itemNo: string
  tpgName: string
  description: string
  brand : string
  status: ApprovementListEntryProcessStatus
  assortmentManagerComment: string
}

interface QuickUpdateDetail{
  id: string | null
  assignedChannel: string | null
  assignedAssortmentManager: User | null
  approvementListEntries: QuickUpdateApprovementListEntry[]| null
  createdAt: Date| null
  closedAt: Date| null
  progressStatus: EQuickUpdateProgressStatus | null
}

interface PersistenceArticleWithComment {
  id: string
  comment: string
}

interface PersistenceQuickUpdateCommentData {
  assortmentManagerComment: string
}

enum EQuickUpdateProgressStatus
{
  NoValue='no value',
  Draft='DRAFT',
  Closed='CLOSED'
}

enum QuickUpdateType {
  add='ADD',
  remove='REMOVE'
}

enum ApprovementListEntryProcessStatus {
  Outstanding = 'OUTSTANDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  NotRelevant = 'NOT_RELEVANT'
}

export {
    QuickUpdateOverviewPage,
    QuickUpdateOverview,
    CreateQuickUpdateModel,
    PersistenceCreateQuickUpdateModel,
    ArticleSummary,
    ArticleSummarySlice,
    QuickUpdateType,
    EQuickUpdateProgressStatus,
    ArticleSummaryWithComment,
    PersistenceArticleWithComment,
    QuickUpdateDetail,
    QuickUpdateApprovementListEntry,
    PersistenceQuickUpdateCommentData
}

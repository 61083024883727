import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import { ChannelDetail, ChannelFilterValues, ChannelName, ChannelPage, ChannelWithAssignedUsers, CreateChannelModelForPersistence, EditChannelModelForPersistence } from '@/types/awms/channel/ChannelTypes'

class ChannelService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/v1/channels/', axiosInstance)
    }

    all (paginationQuery: PaginationQuery, search: string | null): Promise<ChannelPage> {
        let searchQueryString = ''
        if (search && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false) + searchQueryString
        const url = this.contextPath + paginationQueryString
        return new Promise<ChannelPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    allWithAssignedUsers (): Promise<ChannelWithAssignedUsers[]> {
        const url = this.contextPath + 'with-assigned-users'
        return new Promise<ChannelWithAssignedUsers[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    get (id: string): Promise<ChannelDetail> {
        const url = this.contextPath + encodeURIComponent(id)
        return new Promise<ChannelDetail>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    name (id: string): Promise<string> {
        const url = this.contextPath + encodeURIComponent(id) + '/name'
        return new Promise<string>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    create (channel : CreateChannelModelForPersistence) : Promise<void> {
        const url = this.contextPath
        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.post(url, channel)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject)
                )
        })
    }

    update (channelId: string, channel : EditChannelModelForPersistence) : Promise<void> {
        const url = this.contextPath + channelId
        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.put(url, channel)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    filterValues (): Promise<ChannelFilterValues> {
        const url = this.contextPath + 'filter-values'
        return new Promise<ChannelFilterValues>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    names (): Promise<ChannelName[]> {
        const url = this.contextPath + 'names'
        return new Promise<ChannelName[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { ChannelService }



















import Vue from 'vue'
import Component from 'vue-class-component'
import { LoadingInformation } from '@/types/CustomVuetifyTypes'
import { EventService } from '@/services/EventService'
import { LOADING_INFORMATION_EVENT, LOADING_INFORMATION_RESET_EVENT } from '@/definitions/EventDefinitions'

@Component({})
export default class LoadingInformationComponent extends Vue {
  registered : boolean = false

  isVisible : boolean = false
  absolute : boolean = true
  message : string | null = null

  onLoadingInformation (loadingInformation : LoadingInformation) {
      if (loadingInformation === undefined || loadingInformation === null) return

      this.message = loadingInformation.message
      this.isVisible = true
  }

  onLoadingInformationReset () {
      this.message = null
      this.isVisible = false
  }

  mounted () {
      if (this.registered) return

      EventService.getInstance().addListener(LOADING_INFORMATION_EVENT, (loadingInformation) => { this.onLoadingInformation(loadingInformation) })
      EventService.getInstance().addListener(LOADING_INFORMATION_RESET_EVENT, () => { this.onLoadingInformationReset() })

      this.registered = true
  }
}

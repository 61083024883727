import { AxiosError, AxiosInstance } from 'axios'
import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import { Subsidiary, SubsidiaryPage } from '@/types/subsidiaries/SubsidiariesTypes'

class SubsidiariesCoreService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/rest/v1/subsidiaries', axiosInstance)
    }

    find (search: string, paginationQuery: PaginationQuery): Promise<SubsidiaryPage> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)
        const url = this.contextPath + '/paged' + paginationQueryString + searchQueryString

        return new Promise<SubsidiaryPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getSubsidiaries (): Promise<Subsidiary[]> {
        return new Promise<Subsidiary[]>((resolve, reject) => {
            this.axiosInstance.get(this.contextPath)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { SubsidiariesCoreService }

import { AbstractCreateModeState, CreateModeState } from '@/types/CreateModeTypes'
import { CreateCustomerGroupModel, CreateCustomerModel, CustomerGroup, ECustomerBlockStatus, ECustomerCategory, ECustomerGroupParticipation, ECustomerParticipation } from '@/types/shoplocator/ShopLocatorTypes'
import { Subsidiary } from '@/types/subsidiaries/SubsidiariesTypes'

class CustomerGroupCreateModeState extends AbstractCreateModeState implements CreateModeState {
  private _subsidiary : Subsidiary | null
  private _createCustomerGroupModel : CreateCustomerGroupModel | null

  constructor () {
      super()
      this._subsidiary = null
      this._createCustomerGroupModel = null
  }

  get subsidiary (): Subsidiary | null {
      return this._subsidiary
  }

  get createCustomerGroupModel () : CreateCustomerGroupModel | null {
      return this._createCustomerGroupModel
  }

  get createCustomerGroupModelForPersistence () : CreateCustomerGroupModel | null {
      if (this._createCustomerGroupModel === null) return null

      this._createCustomerGroupModel.displayName = this._createCustomerGroupModel.description

      return this._createCustomerGroupModel
  }

  initialize (subsidiary : Subsidiary) {
      this._subsidiary = subsidiary

      this._createCustomerGroupModel = {
          description: '',
          displayName: null,
          assetUrl: null,
          participation: ECustomerGroupParticipation.DependsOnCustomer
      }

      this.originalItemState = this.getCurrentItemState()
  }

  clearState () {
      super.clearState()
      this._subsidiary = null
      this._createCustomerGroupModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._createCustomerGroupModel === null ? null : JSON.stringify(this._createCustomerGroupModel)
  }
}

class CustomerCreateModeState extends AbstractCreateModeState implements CreateModeState {
  private _subsidiary : Subsidiary | null
  private _customerGroup : CustomerGroup | null
  private _createCustomerModel : CreateCustomerModel | null

  constructor () {
      super()
      this._subsidiary = null
      this._customerGroup = null
      this._createCustomerModel = null
  }

  get subsidiary (): Subsidiary | null {
      return this._subsidiary
  }

  get customerGroup (): CustomerGroup | null {
      return this._customerGroup
  }

  get createCustomerModel (): CreateCustomerModel | null {
      return this._createCustomerModel
  }

  get createCustomerModelForPersistence (): CreateCustomerModel | null {
      if (this._createCustomerModel === null) return null

      this._createCustomerModel.displayName = this._createCustomerModel.name

      return this._createCustomerModel
  }

  initialize (subsidiary : Subsidiary, customerGroup : CustomerGroup) {
      this._subsidiary = subsidiary
      this._customerGroup = customerGroup

      this._createCustomerModel = {
          customerGroupId: this._customerGroup.id,
          name: '',
          displayName: null,
          assetUrl: null,
          participation: ECustomerParticipation.Yes,
          blockStatus: ECustomerBlockStatus.None,
          category: ECustomerCategory.NOT_SET,
          ignoreBlockStatus: false
      }

      this.originalItemState = this.getCurrentItemState()
  }

  clearState () {
      super.clearState()
      this._subsidiary = null
      this._customerGroup = null
      this._createCustomerModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._createCustomerModel === null ? null : JSON.stringify(this._createCustomerModel)
  }
}

export { CustomerGroupCreateModeState, CustomerCreateModeState }

import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import { ETeoProgressStatus, PersistenceCreateTeoModel, PersistenceTeoCommentData, TeoDetail } from '@/types/awms/approvement_list/teo/TeoTypes'
import { QuickUpdateOverviewFilter } from '@/types/awms/approvement_list/quick_update/QuickUpdateListSettingsTypes'
import { ArticleSummarySlice, EQuickUpdateProgressStatus, PersistenceCreateQuickUpdateModel, PersistenceQuickUpdateCommentData, QuickUpdateDetail, QuickUpdateOverviewPage } from '@/types/awms/approvement_list/quick_update/QuickUpdateTypes'

class QuickUpdateService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/v1/approvement-lists/quick-update', axiosInstance)
    }

    all (paginationQuery: PaginationQuery, search: string | null, quickUpdateOverviewFilter:QuickUpdateOverviewFilter): Promise<QuickUpdateOverviewPage> {
        let searchQueryString = ''
        if (search && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + paginationQueryString + searchQueryString + this.generateFilterQueryString(quickUpdateOverviewFilter)
        return new Promise<QuickUpdateOverviewPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    private generateFilterQueryString (quickUpdateOverviewFilter:QuickUpdateOverviewFilter) : string {
        let filterQueryString = ''
        if (!quickUpdateOverviewFilter) {
            return filterQueryString
        }

        if (quickUpdateOverviewFilter.progressStatusFilter !== null && quickUpdateOverviewFilter.progressStatusFilter !== EQuickUpdateProgressStatus.NoValue) {
            filterQueryString += '&'
            filterQueryString += `progressStatus=${encodeURIComponent(quickUpdateOverviewFilter.progressStatusFilter)}`
        }

        if (quickUpdateOverviewFilter.createdDateRangeFilter !== null && quickUpdateOverviewFilter.createdDateRangeFilter.length > 0) {
            filterQueryString += '&'
            filterQueryString += `createdStart=${encodeURIComponent(quickUpdateOverviewFilter.createdDateRangeFilter[0])}`
            filterQueryString += '&'
            if (quickUpdateOverviewFilter.createdDateRangeFilter.length > 1) {
                filterQueryString += `createdEnd=${encodeURIComponent(quickUpdateOverviewFilter.createdDateRangeFilter[1])}`
            } else {
                filterQueryString += `createdEnd=${encodeURIComponent(quickUpdateOverviewFilter.createdDateRangeFilter[0])}`
            }
        }

        if (quickUpdateOverviewFilter.closedDateRangeFilter !== null && quickUpdateOverviewFilter.closedDateRangeFilter.length > 0) {
            filterQueryString += '&'
            filterQueryString += `closedStart=${encodeURIComponent(quickUpdateOverviewFilter.closedDateRangeFilter[0])}`
            filterQueryString += '&'
            if (quickUpdateOverviewFilter.closedDateRangeFilter.length > 1) {
                filterQueryString += `closedEnd=${encodeURIComponent(quickUpdateOverviewFilter.closedDateRangeFilter[1])}`
            } else {
                filterQueryString += `closedEnd=${encodeURIComponent(quickUpdateOverviewFilter.closedDateRangeFilter[0])}`
            }
        }

        return filterQueryString
    }

    allArticles (paginationQuery: PaginationQuery, search: string | null): Promise<ArticleSummarySlice> {
        let searchQueryString = ''
        if (search && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + '/all-articles' + paginationQueryString + searchQueryString
        return new Promise<ArticleSummarySlice>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    countAllArticles (search: string | null): Promise<number> {
        let searchQueryString = ''
        if (search && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const url = this.contextPath + '/count-all-articles?' + searchQueryString
        return new Promise<number>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    potentialListArticles (channelId: string, paginationQuery: PaginationQuery, search: string | null): Promise<ArticleSummarySlice> {
        let searchQueryString = ''
        if (search && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)

        const channelQueryString = `&channelId=${encodeURIComponent(channelId)}`

        const url = this.contextPath + '/potential-list-articles' + paginationQueryString + searchQueryString + channelQueryString
        return new Promise<ArticleSummarySlice>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    countPotentialListArticles (channelId: string, search: string | null): Promise<number> {
        let searchQueryString = ''
        if (search && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const channelQueryString = `&channelId=${encodeURIComponent(channelId)}`

        const url = this.contextPath + '/count-potential-list-articles?' + searchQueryString + channelQueryString
        return new Promise<number>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    assortmentArticles (channelId: string, paginationQuery: PaginationQuery, search: string | null): Promise<ArticleSummarySlice> {
        let searchQueryString = ''
        if (search && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)

        const channelQueryString = `&channelId=${encodeURIComponent(channelId)}`

        const url = this.contextPath + '/assortment-articles' + paginationQueryString + searchQueryString + channelQueryString
        return new Promise<ArticleSummarySlice>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    countAssortmentArticles (channelId: string, search: string | null): Promise<number> {
        let searchQueryString = ''
        if (search && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const channelQueryString = `&channelId=${encodeURIComponent(channelId)}`

        const url = this.contextPath + '/count-assortment-articles?' + searchQueryString + channelQueryString
        return new Promise<number>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    create (approvementList : PersistenceCreateQuickUpdateModel) : Promise<string> {
        const url = this.contextPath
        return new Promise<string>((resolve, reject) => {
            this.axiosInstance.post(url, approvementList)
                .then(response => resolve(response.headers.location)
                )
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject)
                )
        })
    }

    get (approvementListId: string): Promise<QuickUpdateDetail> {
        const url = this.contextPath + '/' + encodeURIComponent(approvementListId)
        return new Promise<QuickUpdateDetail>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    updateComment (approvementListId: string, model : Map<string, PersistenceQuickUpdateCommentData>) : Promise<void> {
        const url = this.contextPath + '/' + encodeURIComponent(approvementListId) + '/comment'
        const objFromMap = Object.fromEntries(model)

        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.put(url, objFromMap)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { QuickUpdateService }

import { AdminRole, AssortmentManagerRole, AwmsModule, EditorExtendedRole, ShoplocatorModule, TeoReceiverRole } from '@/definitions/EwmsCoreDefinitions'

function filterUnknownRolesAndReduceToSingleRole (roles: string[]): string | null {
    const filteredRoles = roles.filter(role => {
        return role.startsWith('EWMS_ROLE_')
    })

    if (filteredRoles.length > 0) return filteredRoles[0]

    return null
}

function hasModuleAccess (moduleAccesses: string[] | null, moduleName: string): boolean {
    if (moduleAccesses === null || moduleAccesses.length === 0) return false
    for (const moduleAccess of moduleAccesses) {
        if (moduleAccess === moduleName) return true
    }

    return false
}

class UserProfile {
  private readonly _id: string | null
  private readonly _firstName: string | null
  private readonly _lastName: string | null
  private readonly _username: string | null
  private readonly _email: string | null
  private readonly _subsidiary: string | null
  private readonly _locale: string | null
  private readonly _role: string | null
  private readonly _moduleAccesses: string[] | null
  private readonly _extendedAccesses: string[] | null

  private readonly _isAdmin: boolean
  private readonly _isEditorExtended: boolean
  private readonly _isAssortmentManager: boolean
  private readonly _isTeoReceiver: boolean

  private readonly _canViewPermissions: boolean
  private readonly _hasShopLocatorAccess: boolean
  private readonly _hasAwmsAccess: boolean

  constructor (id: string | null = null, firstName: string | null = null, lastName: string | null = null, username: string | null = null, email: string | null = null, subsidiary: string | null = null, locale: string | null = null, moduleAccesses: string[] | null, extendedAccesses: string[] | null, roles: string[]) {
      this._id = id
      this._firstName = firstName
      this._lastName = lastName
      this._username = username
      this._email = email
      this._subsidiary = subsidiary
      this._locale = locale
      this._role = filterUnknownRolesAndReduceToSingleRole(roles)
      this._moduleAccesses = moduleAccesses
      this._extendedAccesses = extendedAccesses

      this._isAdmin = this._role === AdminRole
      this._isEditorExtended = this._role === EditorExtendedRole
      this._isAssortmentManager = this._role === AssortmentManagerRole
      this._isTeoReceiver = this._role === TeoReceiverRole

      this._canViewPermissions = this._isAdmin
      this._hasShopLocatorAccess = this._isAdmin || hasModuleAccess(this._moduleAccesses, ShoplocatorModule)
      this._hasAwmsAccess = this._isAdmin || hasModuleAccess(this._moduleAccesses, AwmsModule)
  }

  get id (): string | null {
      return this._id
  }

  get firstName (): string | null {
      return this._firstName
  }

  get lastName (): string | null {
      return this._lastName
  }

  get fullName (): string | null {
      if (this.firstNamePresent) {
          if (this.lastNamePresent) {
              return `${this.firstName} ${this.lastName}`
          }

          return this.firstName
      }

      if (this.lastNamePresent) {
          return this.lastName
      }

      return null
  }

  get username (): string | null {
      return this._username
  }

  get email (): string | null {
      return this._email
  }

  get subsidiary (): string | null {
      return this._subsidiary
  }

  get locale (): string | null {
      return this._locale
  }

  get role (): string | null {
      return this._role
  }

  get moduleAccesses (): string[] | null {
      return this._moduleAccesses
  }

  get extendedAccesses (): string[] | null {
      return this._extendedAccesses
  }

  get isAdmin (): boolean {
      return this._isAdmin
  }

  get isAssortmentManager (): boolean {
      return this._isAssortmentManager
  }

  get isTeoReceiver (): boolean {
      return this._isTeoReceiver
  }

  get isNotAdmin (): boolean {
      return !this.isAdmin
  }

  get isEditorExtended (): boolean {
      return this._isEditorExtended
  }

  get isNotEditorExtended (): boolean {
      return !this.isEditorExtended
  }

  get canViewPermissions (): boolean {
      return this._canViewPermissions
  }

  get hasShopLocatorAccess (): boolean {
      return this._hasShopLocatorAccess
  }

  get hasAwmsAccess (): boolean {
      return this._hasAwmsAccess
  }

  get firstNamePresent (): boolean {
      return this._firstName !== null
  }

  get lastNamePresent (): boolean {
      return this._lastName !== null
  }

  get fullNamePresent (): boolean {
      return this.fullName !== null
  }

  get usernamePresent (): boolean {
      return this._username !== null
  }

  get emailPresent (): boolean {
      return this._email !== null
  }

  get subsidiaryPresent (): boolean {
      return this._subsidiary !== null
  }

  get localePresent (): boolean {
      return this._locale !== null
  }

  get rolePresent (): boolean {
      return this._role !== null
  }

  get hasModuleAccesses (): boolean {
      return this._moduleAccesses !== null && this._moduleAccesses.length > 0
  }

  get hasExtendedAccesses (): boolean {
      return this.isEditorExtended && this._extendedAccesses != null && this._extendedAccesses.length > 0
  }
}

export { UserProfile }

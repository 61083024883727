import { AbstractCreateModeState, CreateModeState } from '@/types/CreateModeTypes'
import { ChannelFilterValues, CreateChannelModel, CreateChannelModelForPersistence } from '@/types/awms/channel/ChannelTypes'
import { User } from '@/types/awms/UserTypes'
import { Filter, FilterList } from '@/types/awms/FilterTypes'

class ChannelCreateModeState extends AbstractCreateModeState implements CreateModeState {
  private _createChannelModel : CreateChannelModel | null
  private _locales : string[]
  private _assortmentManagers : User[]
  private _teoReceivers : User[]
  private _filterValues : ChannelFilterValues | null

  constructor () {
      super()
      this._createChannelModel = null
      this._locales = []
      this._assortmentManagers = []
      this._teoReceivers = []
      this._filterValues = null
  }

  get createChannelModel (): CreateChannelModel | null {
      return this._createChannelModel
  }

  initialize () {
      this._createChannelModel = {
          name: '',
          description: '',
          active: false,
          multiVersion: false,
          locales: [],
          assortmentManagerIds: [],
          teoReceiverIds: [],
          filter: this.defaultFilter
      }

      this.originalItemState = this.getCurrentItemState()
  }

  get createChannelModelForPersistence (): CreateChannelModelForPersistence | null {
      if (this._createChannelModel === null) return null

      return {
          name: this._createChannelModel.name,
          description: this._createChannelModel.description,
          active: this._createChannelModel.active,
          locales: this._createChannelModel.locales,
          assortmentManagerIds: this._createChannelModel.assortmentManagerIds,
          teoReceiverIds: this._createChannelModel.teoReceiverIds,
          multiVersion: this._createChannelModel.multiVersion,
          filter: JSON.stringify(this._createChannelModel.filter)
      }
  }

  set locales (value: string[]) {
      this._locales = value
  }

  get locales () {
      return this._locales
  }

  get filterValues (): ChannelFilterValues | null {
      return this._filterValues
  }

  set filterValues (value: ChannelFilterValues | null) {
      this._filterValues = value
  }

  set teoReceivers (value: User[]) {
      this._teoReceivers = value
  }

  get teoReceivers () {
      return this._teoReceivers
  }

  set assortmentManagers (value: User[]) {
      this._assortmentManagers = value
  }

  get assortmentManagers () {
      return this._assortmentManagers
  }

  clearState () {
      super.clearState()
      this._createChannelModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._createChannelModel === null ? null : JSON.stringify(this._createChannelModel)
  }

  get defaultFilter (): Filter {
      return {
          include: this.defaultFilterList(),
          exclude: this.defaultFilterList()
      }
  }

  private defaultFilterList (): FilterList {
      return {
          itemNos: [],
          identNos: [],
          tpgCodes: [],
          brands: [],
          itemVersionStatus: [],
          assortmentStatus: [],
          types: [],
          sources: [],
          preparedForCountries: [],
          currentSalesVersion: null,
          purchaseOkDate: null,
          firstPurchaseOkDate: null,
          launchDate: null
      }
  }
}

export { ChannelCreateModeState }

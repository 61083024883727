import Vue from 'vue'
import Component from 'vue-class-component'
import { NavigationState } from '@/states/NavigationState'
import { AuthenticationState } from '@/states/AuthenticationState'
import { UserProfile } from '@/types/UserProfile'
import { ShopLocatorUiState } from '@/states/ShopLocatorUiState'
import { SubsidiariesUiState } from '@/states/SubsidiariesUiState'
import { AwmsUiState } from '@/states/AwmsUiState'

@Component
export default class ApplicationStateMixin extends Vue {
  readonly authentication: AuthenticationState = AuthenticationState.getInstance()
  readonly navigation: NavigationState = NavigationState.getInstance()
  readonly subsidiariesUiState : SubsidiariesUiState = SubsidiariesUiState.getInstance()
  readonly shopLocatorUiState : ShopLocatorUiState = ShopLocatorUiState.getInstance()
  readonly awmsUiState : AwmsUiState = AwmsUiState.getInstance()

  userProfile: UserProfile | null = null

  created () {
      this.userProfile = this.authentication.userProfile
  }
}

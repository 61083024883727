// @ts-ignore
import { EventEmitter } from 'events'

class QuickUpdateEventService extends EventEmitter {
  private readonly _eventEmitter: EventEmitter

  private constructor () {
      super()
      this._eventEmitter = new EventEmitter()
  }

  private static _instance: QuickUpdateEventService

  static getInstance (): QuickUpdateEventService {
      if (!QuickUpdateEventService._instance) {
          QuickUpdateEventService._instance = new QuickUpdateEventService()
      }

      return QuickUpdateEventService._instance
  }
}

export { QuickUpdateEventService }

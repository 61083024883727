interface EditModeState {
  hasChanges : boolean

  saveState() : Promise<any>
  clearState() : void
}

abstract class AbstractEditModeState implements EditModeState {
  private _originalItemState : string | null
  private _saveCallback : (() => Promise<any>) | null

  protected constructor () {
      this._originalItemState = null
      this._saveCallback = null
  }

  get saveCallback (): (() => Promise<any>) | null {
      return this._saveCallback
  }

  set saveCallback (value: (() => Promise<any>) | null) {
      this._saveCallback = value
  }

  protected set originalItemState (value : string | null) {
      this._originalItemState = value
  }

  get hasChanges () : boolean {
      let hasChanges = false

      if (this._originalItemState !== null) {
          const curItem = this.getCurrentItemState()

          hasChanges = this._originalItemState !== curItem
      }

      return hasChanges
  }

  clearState (): void {
      this._originalItemState = null
      this._saveCallback = null
  }

  saveState (): Promise<any> {
      return new Promise<any>((resolve, reject) => {
          if (this._saveCallback === null) {
              resolve()
              return
          }

          this._saveCallback()
              .then(resolve)
              .catch(reject)
      })
  }

  protected abstract getCurrentItemState (): string | null;
}

export { EditModeState, AbstractEditModeState }

import Vue from 'vue'
import Component from 'vue-class-component'
import { CoreServiceFactory } from '@/services/remote/core/CoreServiceFactory'
import { UserService } from '@/services/remote/core/UsersService'
import { SubsidiariesCoreService } from '@/services/remote/core/SubsidiariesCoreService'
import { ShopLocatorServiceFactory } from '@/services/remote/shoplocator/ShopLocatorServiceFactory'
import { CustomerGroupsService } from '@/services/remote/shoplocator/CustomerGroupsService'
import { SubsidiariesShopLocatorService } from '@/services/remote/shoplocator/SubsidiariesShopLocatorService'
import { CustomersService } from '@/services/remote/shoplocator/CustomersService'
import { ShopLocatorVirtualService } from '@/services/remote/shoplocator/ShopLocatorVirtualService'
import { CustomerAddressesService } from '@/services/remote/shoplocator/CustomerAddressService'
import { ChangeHistoryEntriesService } from '@/services/remote/shoplocator/ChangeHistoryEntriesService'
import { SubsidiariesServiceFactory } from '@/services/remote/subsidiaries/SubsidiariesServiceFactory'
import { SubsidiariesService } from '@/services/remote/subsidiaries/SubsidiariesService'
import { BulkUpdateService } from '@/services/remote/shoplocator/BulkUpdateService'
import { AwmsServiceFactory } from '@/services/remote/awms/AwmsServiceFactory'
import { ChannelService } from '@/services/remote/awms/ChannelService'
import { TeoService } from '@/services/remote/awms/TeoService'
import { PotentialListService } from '@/services/remote/awms/PotentialListService'
import { AssortmentCandidateService } from '@/services/remote/awms/AssortmentCandidateService'
import { PlannedAssortmentService } from '@/services/remote/awms/PlannedAssortmentService'
import { AssortmentService } from '@/services/remote/awms/AssortmentService'
import { QuickUpdateService } from '@/services/remote/awms/QuickUpdateService'

@Component
export default class RemoteServiceMixin extends Vue {
  coreServiceFactory: CoreServiceFactory | null = null
  subsidiariesServiceFactory : SubsidiariesServiceFactory | null = null
  shopLocatorServiceFactory: ShopLocatorServiceFactory | null = null
  awmsServiceFactory: AwmsServiceFactory | null = null

  userService: UserService | null = null
  subsidiariesCoreService: SubsidiariesCoreService | null = null

  subsidiariesService : SubsidiariesService | null = null

  shopLocatorVirtualService: ShopLocatorVirtualService | null = null
  subsidiariesShopLocatorService: SubsidiariesShopLocatorService | null = null
  customerGroupsService: CustomerGroupsService | null = null
  customersService: CustomersService | null = null
  customerAddressesService: CustomerAddressesService | null = null
  changeHistoryEntriesService : ChangeHistoryEntriesService | null = null
  bulkUpdateService : BulkUpdateService | null = null

  channelService: ChannelService | null = null
  teoService: TeoService | null = null
  potentialListService: PotentialListService | null = null
  assortmentCandidateService: AssortmentCandidateService | null = null
  plannedAssortmentService: PlannedAssortmentService | null = null
  assortmentService: AssortmentService | null = null
  quickUpdateService: QuickUpdateService | null = null

  created () {
      this.coreServiceFactory = CoreServiceFactory.getInstance()
      this.subsidiariesServiceFactory = SubsidiariesServiceFactory.getInstance()
      this.shopLocatorServiceFactory = ShopLocatorServiceFactory.getInstance()
      this.awmsServiceFactory = AwmsServiceFactory.getInstance()

      this.userService = this.coreServiceFactory.userService
      this.subsidiariesCoreService = this.coreServiceFactory.subsidiariesService

      this.subsidiariesService = this.subsidiariesServiceFactory.subsidiariesService

      this.shopLocatorVirtualService = this.shopLocatorServiceFactory.shopLocatorVirtualService
      this.subsidiariesShopLocatorService = this.shopLocatorServiceFactory.subsidiariesService
      this.customerGroupsService = this.shopLocatorServiceFactory.customerGroupsService
      this.customersService = this.shopLocatorServiceFactory.customersService
      this.customerAddressesService = this.shopLocatorServiceFactory.customerAddressesService
      this.changeHistoryEntriesService = this.shopLocatorServiceFactory.changeHistoryEntriesService
      this.bulkUpdateService = this.shopLocatorServiceFactory.bulkUpdateService

      this.channelService = this.awmsServiceFactory.channelService
      this.teoService = this.awmsServiceFactory.teoService
      this.potentialListService = this.awmsServiceFactory.potentialListService
      this.assortmentCandidateService = this.awmsServiceFactory.assortmentCandidateService
      this.plannedAssortmentService = this.awmsServiceFactory.plannedAssortmentService
      this.assortmentService = this.awmsServiceFactory.assortmentService
      this.quickUpdateService = this.awmsServiceFactory.quickUpdateService
  }
}

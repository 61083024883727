import { AxiosError } from 'axios'
import { EmwsError } from '@/types/BasicRemoteTypes'

function transFormAndRejectAxiosError (axiosError: AxiosError, reject: (reason?: any) => void) {
    if (axiosError.response !== undefined) {
        const ewmsError = axiosError.response.data as EmwsError
        reject(ewmsError)
    } else {
        reject(axiosError)
    }
}

export { transFormAndRejectAxiosError }

import dayjs from 'dayjs'

class InternalLocaleService {
    async loadFormatAsync (language:string): Promise<string|void> {
        return import(
        /* webpackChunkName: "lang-[request]" */ `dayjs/locale/${language}`
        ).then(() => dayjs.locale(language))
    }
}

export const LocaleService = new InternalLocaleService()

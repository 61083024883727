









































































import Component, { mixins } from 'vue-class-component'
import BaseComponentMixin from '@/components/BaseComponentMixin'

@Component
export default class HeaderComponent extends mixins(BaseComponentMixin) {
    onHeaderClicked () {
        const curPath = this.$route.path
        const destPath = '/'

        if (curPath === destPath) return

        this.$router.push(destPath)
    }
}

import { NavigationTypes } from '@/types/NavigationTypes'

class NavigationState {
  private _isExpanded: boolean
  private readonly _menuNavigationItems: NavigationTypes[]
  private readonly _dashboardNavigationItems: NavigationTypes[]
  private readonly _awmsNavigationItems: NavigationTypes[]

  private _selectedMenuNavigationItem : number

  private constructor () {
      this._isExpanded = false
      this._menuNavigationItems = []
      this._dashboardNavigationItems = []
      this._awmsNavigationItems = []

      this._selectedMenuNavigationItem = 0
  }

  private static _instance: NavigationState

  static getInstance (): NavigationState {
      if (!NavigationState._instance) {
          NavigationState._instance = new NavigationState()
      }

      return NavigationState._instance
  }

  get isExpanded (): boolean {
      return this._isExpanded
  }

  set isExpanded (value: boolean) {
      this._isExpanded = value
  }

  get menuNavigationItems (): NavigationTypes[] {
      return this._menuNavigationItems
  }

  get dashboardNavigationItems (): NavigationTypes[] {
      return this._dashboardNavigationItems
  }

  get awmsNavigationItems (): NavigationTypes[] {
      return this._awmsNavigationItems
  }

  get selectedMenuNavigationItem (): number {
      return this._selectedMenuNavigationItem
  }

  set selectedMenuNavigationItem (value: number) {
      this._selectedMenuNavigationItem = value
  }

  toggleIsExpanded (): void {
      this._isExpanded = !this._isExpanded
  }

  setSelectedItem (item : NavigationTypes) {
      this.selectedMenuNavigationItem = this._menuNavigationItems.indexOf(item)
  }
}

export { NavigationState }

import { ECustomerBlockStatus, ECustomerGroupParticipation, ECustomerParticipation } from '@/types/shoplocator/ShopLocatorTypes'
import { ListFilterSettings, AbstractSearchAndFilterableListSettingsImpl } from '@/types/ListSettingsTypes'

class CustomerGroupListSettings extends AbstractSearchAndFilterableListSettingsImpl implements CustomerGroupListFilterSettings {
  private _participationFilter : ECustomerGroupParticipation

  constructor () {
      super({
          sortBy: ['navisionId'],
          sortDesc: [false]
      })

      this._participationFilter = ECustomerGroupParticipation.NoValue
  }

  get participationFilter (): ECustomerGroupParticipation {
      return this._participationFilter
  }

  set participationFilter (value: ECustomerGroupParticipation) {
      this._participationFilter = value
  }

  get hasFilters (): boolean {
      return !this.hasNoParticipationFilterSet
  }

  get hasNoParticipationFilterSet () : boolean {
      return this._participationFilter === ECustomerGroupParticipation.NoValue
  }

  clearFilters (): void {
      this._participationFilter = ECustomerGroupParticipation.NoValue
  }
}

class CustomerListSettings extends AbstractSearchAndFilterableListSettingsImpl implements CustomerListFilterSettings {
  private _participationFilter : ECustomerParticipation
  private _blockStatusFilter : ECustomerBlockStatus

  constructor () {
      super({
          sortBy: ['navisionId'],
          sortDesc: [false]
      })

      this._participationFilter = ECustomerParticipation.NoValue
      this._blockStatusFilter = ECustomerBlockStatus.NoValue
  }

  get participationFilter (): ECustomerParticipation {
      return this._participationFilter
  }

  set participationFilter (value: ECustomerParticipation) {
      this._participationFilter = value
  }

  get blockStatusFilter (): ECustomerBlockStatus {
      return this._blockStatusFilter
  }

  set blockStatusFilter (value: ECustomerBlockStatus) {
      this._blockStatusFilter = value
  }

  get hasFilters () : boolean {
      return !this.hasNoParticipationFilterSet || !this.hasNoBlockStatusFilterSet
  }

  get hasNoParticipationFilterSet () : boolean {
      return this._participationFilter === ECustomerParticipation.NoValue
  }

  get hasNoBlockStatusFilterSet () : boolean {
      return this._blockStatusFilter === ECustomerBlockStatus.NoValue
  }

  clearFilters (): void {
      this._participationFilter = ECustomerParticipation.NoValue
      this._blockStatusFilter = ECustomerBlockStatus.NoValue
  }
}

interface CustomerGroupListFilterSettings extends ListFilterSettings {
  participationFilter : ECustomerGroupParticipation
}

interface CustomerListFilterSettings extends ListFilterSettings{
  participationFilter : ECustomerParticipation
  blockStatusFilter : ECustomerBlockStatus
}

export { CustomerGroupListSettings, CustomerListSettings, CustomerGroupListFilterSettings, CustomerListFilterSettings }

function clearArray<TType> (arr: TType[]): void {
    if (arr === undefined || arr === null) return

    arr.splice(0, arr.length)
}

function addAll<TType> (destination: TType[], source: TType[]): void {
    if (destination === undefined || destination === null) return
    if (source === undefined || source === null) return

    for (const element of source) {
        destination.push(element)
    }
}

export { clearArray, addAll }

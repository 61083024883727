import { ESubsidiarySource, Subsidiary } from '@/types/subsidiaries/SubsidiariesTypes'
import { EAG_LC } from '@/definitions/EwmsCoreDefinitions'

function getI18nPathFor (subsidiarySource : ESubsidiarySource) : string {
    switch (subsidiarySource) {
    case ESubsidiarySource.Edata: return 'subsidiaries.subsidiarySource.edata'
    case ESubsidiarySource.Shoplocator: return 'subsidiaries.subsidiarySource.shopLocator'
    default:
        throw new Error('SubsidiarySource is not known')
    }
}

function isShopLocatorSubsidiary (subsidiary : Subsidiary | null) : boolean {
    return hasSubsidiarySource(subsidiary, ESubsidiarySource.Shoplocator)
}

function isEdataSubsidiary (subsidiary : Subsidiary | null) : boolean {
    return hasSubsidiarySource(subsidiary, ESubsidiarySource.Edata)
}

function hasSubsidiarySource (subsidiary : Subsidiary | null, source : ESubsidiarySource) : boolean {
    if (subsidiary === undefined || subsidiary === null) return false
    if (source === undefined || source === null) return false
    if (subsidiary.subsidiarySource === undefined || subsidiary.subsidiarySource === null) return false

    return source === subsidiary.subsidiarySource
}

function isSubsidiaryEag (subsidiary : Subsidiary | null) : boolean {
    if (subsidiary === undefined || subsidiary === null) return false

    const name = subsidiary.name
    if (name === undefined || name === null) return false

    return name.trim().toLowerCase() === EAG_LC
}

export { getI18nPathFor, isShopLocatorSubsidiary, isEdataSubsidiary, isSubsidiaryEag }























import Component, { mixins } from 'vue-class-component'
import BaseComponentMixin from '@/components/BaseComponentMixin'
import TileElement from '@/components/dashboard/elements/TileElement.vue'
import { Prop } from 'vue-property-decorator'
import { NavigationTypes } from '@/types/NavigationTypes'

@Component({
    components: {
        TileElement
    }
})
export default class DashboardComponent extends mixins(BaseComponentMixin) {
  @Prop()
  items! : NavigationTypes[]
}

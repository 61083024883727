















import Component, { mixins } from 'vue-class-component'
import BaseComponentMixin from '@/components/BaseComponentMixin'

import { NavigationTypes } from '@/types/NavigationTypes'
import { Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class NavigationItemElement extends mixins(BaseComponentMixin) {
    @Prop()
    item! : NavigationTypes

    onClickedItem () {
        if (this.navigation === null) return

        this.navigation.toggleIsExpanded()

        if (this.item === null) return

        const curPath = this.$route.path
        const destPath = this.item.destination.url

        if (curPath === destPath) return

        if (this.item.destination.internal) {
            this.$router.push(this.item.destination.url)
            // eslint-disable-next-line handle-callback-err
                .catch(err => {})
        } else {
            window.open(this.item.destination.url)
        }
    }

    @Watch('$route')
    onRouteChanged () {
        const curPath = this.$route.path
        const destPath = this.item.destination.url

        if (curPath !== destPath) return
        this.navigation.setSelectedItem(this.item)
    }
}

import { EventService } from '@/services/EventService'
import { AlertType } from '@/types/VuetifyTypes'
import { AmwsError, EmwsError, instanceOfAwmsError, instanceOfEwmsError } from '@/types/BasicRemoteTypes'
import debounce from 'lodash/debounce'
import {
    LOADING_INFORMATION_EVENT,
    LOADING_INFORMATION_RESET_EVENT, USER_INFORMATION_EVENT,
    USER_INFORMATION_RESET_EVENT
} from '@/definitions/EventDefinitions'

import i18n from '@/i18n/index'

const NOTIFICATION_DEBOUNCE_DELAY = 250

class NotificationService {
  private _eventService: EventService

  private constructor () {
      this._eventService = EventService.getInstance()
  }

  private static _instance: NotificationService

  static getInstance () {
      if (!NotificationService._instance) {
          NotificationService._instance = new NotificationService()
      }

      return NotificationService._instance
  }

  indicateLoading<T> (promise: Promise<T>, loadingText : string, rejectError : boolean = false, resetNotifications : boolean = true): Promise<T> {
      if (resetNotifications) {
          this._eventService.emit(USER_INFORMATION_RESET_EVENT)
      }

      const debouncedLoadingInformation = debounce(() => { this._eventService.emit(LOADING_INFORMATION_EVENT, { message: loadingText }) }, NOTIFICATION_DEBOUNCE_DELAY)
      debouncedLoadingInformation()

      return new Promise<T>((resolve, reject) => {
          promise
              .then(value => resolve(value))
              .catch(error => {
                  this.notifyError(this.createErrorMessage(error))

                  if (rejectError) {
                      reject(error)
                  }
              }).finally(() => {
                  debouncedLoadingInformation.cancel()
                  this._eventService.emit(LOADING_INFORMATION_RESET_EVENT)
              })
      })
  }

  private createErrorMessage (error : any) : string {
      if (instanceOfAwmsError(error)) {
          const key = 'errors.' + error.errorResourceKey
          if (i18n.te(key)) {
              return i18n.t(key) as string
          }

          const awmsError = error as AmwsError
          return `${awmsError.message} (${awmsError.error}): ${awmsError.code}`
      } else if (instanceOfEwmsError(error)) {
          const key = 'errors.' + error.error
          if (i18n.te(key)) {
              return i18n.t(key) as string
          }

          const ewmsError = error as EmwsError
          return `${ewmsError.message} (${ewmsError.code}): ${ewmsError.error}`
      }

      return error as string
  }

  notifyError (text: string) {
      this.notify(text, 'error')
  }

  notifySuccess (text: string) {
      this.notify(text, 'success')
  }

  clear () {
      this._eventService.emit(USER_INFORMATION_RESET_EVENT)
  }

  private notify (text: string, type: AlertType) {
      this._eventService.emit(USER_INFORMATION_RESET_EVENT)
      this._eventService.emit(USER_INFORMATION_EVENT, {
          type: type,
          message: text
      })
  }
}

export { NotificationService }

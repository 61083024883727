import { AbstractSearchAndFilterableListSettingsImpl, ListFilterSettings } from '@/types/ListSettingsTypes'
import { ETeoProgressStatus } from '@/types/awms/approvement_list/teo/TeoTypes'
import { AbstractSearchableSliceSettingsImpl } from '@/types/SliceSettingsTypes'

class TeoOverviewListSettings extends AbstractSearchAndFilterableListSettingsImpl implements TeoListFilterSettings {
  private _teoOverviewFilter :TeoOverviewFilter

  constructor () {
      super({
          sortBy: ['createdAt'],
          sortDesc: [true]
      })

      this._teoOverviewFilter = {
          closedDateRangeFilter: [],
          completedDateRangeFilter: [],
          createdDateRangeFilter: [],
          openedDateRangeFilter: [],
          progressStatusFilter: ETeoProgressStatus.NoValue
      }
  }

  get teoOverviewFilter () : TeoOverviewFilter {
      return this._teoOverviewFilter
  }

  get openedDateRangeFilter (): string[] {
      return this._teoOverviewFilter.openedDateRangeFilter
  }

  set openedDateRangeFilter (value: string[]) {
      this._teoOverviewFilter.openedDateRangeFilter = value
  }

  get completedDateRangeFilter (): string[] {
      return this._teoOverviewFilter.completedDateRangeFilter
  }

  set completedDateRangeFilter (value: string[]) {
      this._teoOverviewFilter.completedDateRangeFilter = value
  }

  get closedDateRangeFilter (): string[] {
      return this._teoOverviewFilter.closedDateRangeFilter
  }

  set closedDateRangeFilter (value: string[]) {
      this._teoOverviewFilter.closedDateRangeFilter = value
  }

  get progressStatusFilter (): ETeoProgressStatus {
      return this._teoOverviewFilter.progressStatusFilter
  }

  set progressStatusFilter (value: ETeoProgressStatus) {
      this._teoOverviewFilter.progressStatusFilter = value
  }

  get createdDateRangeFilter (): string[] {
      return this._teoOverviewFilter.createdDateRangeFilter
  }

  set createdDateRangeFilter (value: string[]) {
      this._teoOverviewFilter.createdDateRangeFilter = value
  }

  get hasFilters () : boolean {
      return !this.hasNoProgressStatusFilterSet || !this.hasNoCreatedDateRangeFilterSet || !this.hasNoOpenedDateRangeFilterSet || !this.hasNoCompletedDateRangeFilterSet || !this.hasNoClosedDateRangeFilterSet
  }

  get hasNoProgressStatusFilterSet () : boolean {
      return this._teoOverviewFilter.progressStatusFilter === ETeoProgressStatus.NoValue
  }

  get hasNoCreatedDateRangeFilterSet () : boolean {
      return this._teoOverviewFilter.createdDateRangeFilter === []
  }

  get hasNoOpenedDateRangeFilterSet () : boolean {
      return this._teoOverviewFilter.openedDateRangeFilter === []
  }

  get hasNoCompletedDateRangeFilterSet () : boolean {
      return this._teoOverviewFilter.completedDateRangeFilter === []
  }

  get hasNoClosedDateRangeFilterSet () : boolean {
      return this._teoOverviewFilter.closedDateRangeFilter === []
  }

  clearFilters (): void {
      this._teoOverviewFilter = {
          closedDateRangeFilter: [],
          completedDateRangeFilter: [],
          createdDateRangeFilter: [],
          openedDateRangeFilter: [],
          progressStatusFilter: ETeoProgressStatus.NoValue
      }
  }
}

class TeoRelevantArticleListSettings extends AbstractSearchableSliceSettingsImpl {
    constructor () {
        super({
            // sortBy: ['itemNo'],
            // sortDesc: [false]
        },
        {
            itemsPerPageOptions: [5, 10, 25, 50],
            pageText: ''
        })
    }
}

interface TeoListFilterSettings extends ListFilterSettings{
  progressStatusFilter : ETeoProgressStatus
}

interface TeoOverviewFilter {
progressStatusFilter :ETeoProgressStatus
createdDateRangeFilter :string[]
openedDateRangeFilter :string[]
completedDateRangeFilter :string[]
closedDateRangeFilter :string[]
}

export { TeoOverviewListSettings, TeoListFilterSettings, TeoOverviewFilter, TeoRelevantArticleListSettings }

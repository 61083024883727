import { AxiosError, AxiosInstance } from 'axios'
import { User, UserInfoPage } from '@/types/core/CoreTypes'
import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import { Subsidiary } from '@/types/subsidiaries/SubsidiariesTypes'

class UserService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/rest/v1/users', axiosInstance)
    }

    searchUsers (search : string, paginationQuery : PaginationQuery) : Promise<UserInfoPage> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(search)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)
        const url = this.contextPath + paginationQueryString + searchQueryString

        return new Promise<UserInfoPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getUserById (userId: string): Promise<User> {
        return new Promise<User>((resolve, reject) => {
            this.axiosInstance.get(this.contextPath + '/' + userId)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    updateUser (userId: string, subsidiary:string, roles: string[], moduleAccesses: string[], extendedAccesses: string[]): Promise<User> {
        return new Promise<User>((resolve, reject) => {
            this.axiosInstance.put(this.contextPath + '/' + userId,
                {
                    roles: roles,
                    moduleAccesses: moduleAccesses,
                    extendedAccesses: extendedAccesses,
                    subsidiary: subsidiary
                }
            )
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getAvailableExtendedAccesses (userId: string): Promise<Subsidiary[]> {
        return new Promise<Subsidiary[]>((resolve, reject) => {
            this.axiosInstance.get(this.contextPath + '/' + userId + '/extendedAccesses/available')
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { UserService }

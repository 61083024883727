import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import { EPlannedAssortmentType, PlannedAssortmentDetail, PlannedAssortmentOverviewPage } from '@/types/awms/planned_assortments/PlannedAssortmentTypes'
import { TeoOverviewFilter } from '@/types/awms/approvement_list/teo/TeoListSettingsTypes'
import { PlannedAssortmentOverviewOutstandingFilter, PlannedAssortmentOverviewReleasedFilter } from '@/types/awms/planned_assortments/PlannedAssortmentListSettingsTypes'
import { ETeoProgressStatus } from '@/types/awms/approvement_list/teo/TeoTypes'

class PlannedAssortmentService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/v1/planned-assortment', axiosInstance)
    }

    release (assortmentCandidateIds: string[]): Promise<void> {
        const url = this.contextPath + '/release'
        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.post(url, assortmentCandidateIds)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getReleased (paginationQuery: PaginationQuery, overviewReleasedFilter: PlannedAssortmentOverviewReleasedFilter): Promise<PlannedAssortmentOverviewPage> {
        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + '/released' + paginationQueryString + this.generateReleasedFilterQueryString(overviewReleasedFilter)
        return new Promise<PlannedAssortmentOverviewPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getOutstanding (paginationQuery: PaginationQuery, overviewOutstandingFilter: PlannedAssortmentOverviewOutstandingFilter): Promise<PlannedAssortmentOverviewPage> {
        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + '/outstanding' + paginationQueryString + this.generateOutstandingFilterQueryString(overviewOutstandingFilter)
        return new Promise<PlannedAssortmentOverviewPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    detail (plannedAssortmentId: string): Promise<PlannedAssortmentDetail> {
        const url = this.contextPath + '/' + encodeURIComponent(plannedAssortmentId)
        return new Promise<PlannedAssortmentDetail>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    exportToXlsx (plannedAssortmentId: string): Promise<AxiosResponse> {
        const url = this.contextPath + '/export/' + encodeURIComponent(plannedAssortmentId)
        return new Promise<AxiosResponse>((resolve, reject) => {
            this.axiosInstance.post(url, null, { responseType: 'blob' })
                .then(response => resolve(response))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    private generateReleasedFilterQueryString (overviewReleasedFilter:PlannedAssortmentOverviewReleasedFilter) : string {
        let filterQueryString = ''
        if (!overviewReleasedFilter) {
            return filterQueryString
        }

        if (overviewReleasedFilter.channelFilter !== null) {
            filterQueryString += '&'
            filterQueryString += `channel=${encodeURIComponent(overviewReleasedFilter.channelFilter)}`
        }

        if (overviewReleasedFilter.typeFilter !== null) {
            filterQueryString += '&'
            filterQueryString += `type=${encodeURIComponent(overviewReleasedFilter.typeFilter)}`
        }

        return filterQueryString
    }

    private generateOutstandingFilterQueryString (overviewOutstandingFilter:PlannedAssortmentOverviewOutstandingFilter) : string {
        let filterQueryString = ''
        if (!overviewOutstandingFilter) {
            return filterQueryString
        }

        if (overviewOutstandingFilter.channelFilter !== null) {
            filterQueryString += '&'
            filterQueryString += `channel=${encodeURIComponent(overviewOutstandingFilter.channelFilter)}`
        }

        return filterQueryString
    }
}

export { PlannedAssortmentService }

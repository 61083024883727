import './router/CustomClassComponentHooks'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './i18n/index'
import VueCookies from 'vue-cookies'

// @ts-ignore
import * as VueGoogleMaps from 'vue2-google-maps'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'typeface-roboto/index.css'
import { SecurityService } from './services/SecurityService'
import { NavigationItemProvider } from '@/services/NavigationItemProvider'

import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY
    }
})
Vue.use(VueCookies, { expire: '7d' })

NavigationItemProvider.getInstance().initialize()
    .then(() => SecurityService.getInstance().initialize())
    .finally(() => {
        new Vue({
            i18n,
            router,
            vuetify,
            render: h => h(App)
        }).$mount('#app')
    })

import { AxiosError, AxiosInstance } from 'axios'
import { transFormAndRejectAxiosError } from '@/utils/AxiosUtil'

abstract class AbstractBackendService {
  private readonly _contextPath: string
  private readonly _axiosInstance: AxiosInstance

  protected constructor (contextPath: string, axiosInstance: AxiosInstance) {
      this._contextPath = contextPath
      this._axiosInstance = axiosInstance
  }

  protected get contextPath (): string {
      return this._contextPath
  }

  protected get axiosInstance (): AxiosInstance {
      return this._axiosInstance
  }

  protected transformAndRejectAxiosError (axiosError: AxiosError, reject: (reason?: any) => void) {
      transFormAndRejectAxiosError(axiosError, reject)
  }
}

export { AbstractBackendService }

import { EditTeoReceiversModel, PersistenceChangeTeoReceiversModel, PersistenceEditTeoModel, TeoReceivers } from '@/types/awms/approvement_list/teo/TeoTypes'
import { AbstractEditModeState, EditModeState } from '@/types/EditModeTypes'

class TeoChangeReceiversModeState extends AbstractEditModeState implements EditModeState {
  private _editTeoReceiverModel : EditTeoReceiversModel | null

  constructor () {
      super()
      this._editTeoReceiverModel = null
  }

  get changeTeoReceiversModel (): EditTeoReceiversModel | null {
      return this._editTeoReceiverModel
  }

  initialize (approvementList: TeoReceivers) {
      this._editTeoReceiverModel = {
          approvementListId: approvementList.id,
          channel: approvementList.assignedChannel,
          teoReceivers: approvementList.assignedTeoReceivers
      }

      this.originalItemState = this.getCurrentItemState()
  }

  get editTeoReceiverModelForPersistence (): PersistenceChangeTeoReceiversModel | null {
      if (this._editTeoReceiverModel === null) return null

      return {
          approvementListId: this._editTeoReceiverModel.approvementListId,
          teoReceiverIds: this._editTeoReceiverModel.teoReceivers.map(value => value.id)
      }
  }

  clearState () {
      super.clearState()
      this._editTeoReceiverModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._editTeoReceiverModel === null ? null : JSON.stringify(this._editTeoReceiverModel)
  }
}

export { TeoChangeReceiversModeState }

import Axios, { AxiosInstance } from 'axios'
import Keycloak, { KeycloakInstance } from 'keycloak-js'
import { SecurityService } from '@/services/SecurityService'

abstract class AbstractServiceFactory {
  private readonly _baseUrl: string

  // @ts-ignore
  private _axiosInstance: AxiosInstance

  protected constructor (baseUrl: string) {
      this._baseUrl = baseUrl
  }

  private get keycloakInstance (): Keycloak {
      return SecurityService.getInstance().keycloakInstance
  }

  private createAxiosInstance (): void {
      this._axiosInstance = Axios.create({
          url: this._baseUrl,
          baseURL: this._baseUrl
      })

      this._axiosInstance.interceptors.request.use(config => new Promise((resolve, reject) => {
          this.keycloakInstance.updateToken(5)
              .then(() => {
                  config.headers.Authorization = 'Bearer ' + this.keycloakInstance.token
                  resolve(config)
              })
      }))
  }

  protected get axiosInstance (): AxiosInstance {
      if (!this._axiosInstance) this.createAxiosInstance()

      return this._axiosInstance
  }
}

export { AbstractServiceFactory }

import { ShopLocatorUiState } from '@/states/ShopLocatorUiState'
import { ShopLocatorServiceFactory } from '@/services/remote/shoplocator/ShopLocatorServiceFactory'

class ShopLocatorUiStateService {
  private readonly _shopLocatorUiState : ShopLocatorUiState

  private static _instance : ShopLocatorUiStateService

  private constructor () {
      this._shopLocatorUiState = ShopLocatorUiState.getInstance()
  }

  public static getInstance () : ShopLocatorUiStateService {
      if (!ShopLocatorUiStateService._instance) {
          ShopLocatorUiStateService._instance = new ShopLocatorUiStateService()
      }

      return ShopLocatorUiStateService._instance
  }

  get shopLocatorUiState () : ShopLocatorUiState {
      return this._shopLocatorUiState
  }

  public initialize (isCustomerGroupsMode? : boolean) : Promise<any> {
      return new Promise<any>((resolve, reject) => {
          if (isCustomerGroupsMode !== undefined && isCustomerGroupsMode !== null) {
              this._shopLocatorUiState.isCustomerGroupsMode = isCustomerGroupsMode
          }

          this.loadAssignedSubsidiary()
              .then(() => {
                  this.loadAuthorizedSubsidiaries()
                      .then(resolve)
              })
              .catch(reject)
      })
  }

  private loadAssignedSubsidiary () : Promise<any> {
      return new Promise<any>((resolve, reject) => {
          const subsidiariesService = ShopLocatorServiceFactory.getInstance().subsidiariesService

          subsidiariesService.getAssigned()
              .then(assigned => {
                  this._shopLocatorUiState.assignedSubsidiary = assigned

                  const selectedSubsidiary = this._shopLocatorUiState.selectedSubsidiaryOptional
                  if (selectedSubsidiary === undefined || selectedSubsidiary === null) {
                      this._shopLocatorUiState.selectedSubsidiary = assigned
                  }
              })
              .then(resolve)
              .catch(reject)
      })
  }

  private loadAuthorizedSubsidiaries () : Promise<any> {
      return new Promise<any>((resolve, reject) => {
          const subsidiariesService = ShopLocatorServiceFactory.getInstance().subsidiariesService

          subsidiariesService.getAuthorized()
              .then(authorized => {
                  this._shopLocatorUiState.authorizedSubsidiaries = authorized
                  this.ensureSelectedSubsidiaryWithinBounds()
              })
              .then(resolve)
              .catch(reject)
      })
  }

  private ensureSelectedSubsidiaryWithinBounds () {
      const authorizedSubsidiaries = this._shopLocatorUiState.authorizedSubsidiaries
      const selectedSubsidiary = this._shopLocatorUiState.selectedSubsidiaryOptional

      if (authorizedSubsidiaries === undefined || authorizedSubsidiaries === null || authorizedSubsidiaries.length === 0) {
          this._shopLocatorUiState.selectedSubsidiaryOptional = null
      } else if (selectedSubsidiary === undefined || selectedSubsidiary === null) {
          this._shopLocatorUiState.selectedSubsidiary = authorizedSubsidiaries[0]
      } else {
          const inBounds = authorizedSubsidiaries.map(x => x.id).indexOf(selectedSubsidiary.id) !== -1
          if (!inBounds) {
              this._shopLocatorUiState.selectedSubsidiary = authorizedSubsidiaries[0]
          }
      }
  }
}

export { ShopLocatorUiStateService }

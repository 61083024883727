import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import {
    CreateSubsidiaryModel,
    Subsidiary,
    SubsidiaryPage,
    UpdateSubsidiaryModel
} from '@/types/subsidiaries/SubsidiariesTypes'

class SubsidiariesService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/rest/v1/subsidiaries', axiosInstance)
    }

    exportAllSubsidiaries () : Promise<any> {
        const url = this.contextPath + '/exportSubsidiaries'
        return new Promise<any>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    create (model : CreateSubsidiaryModel) : Promise<Subsidiary> {
        return new Promise<Subsidiary>((resolve, reject) => {
            this.axiosInstance.post(this.contextPath, model)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    update (model : UpdateSubsidiaryModel) : Promise<Subsidiary> {
        const url = this.contextPath + '/' + encodeURIComponent(model.id)

        return new Promise<Subsidiary>((resolve, reject) => {
            this.axiosInstance.put(url, model)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    delete (subsidiaryId : string) : Promise<any> {
        const url = this.contextPath + '/' + encodeURIComponent(subsidiaryId)

        return new Promise<any>((resolve, reject) => {
            this.axiosInstance.delete(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getById (subsidiaryId : string) : Promise<Subsidiary> {
        const url = this.contextPath + '/' + encodeURIComponent(subsidiaryId)

        return new Promise<Subsidiary>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    find (search: string, paginationQuery: PaginationQuery): Promise<SubsidiaryPage> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)
        const url = this.contextPath + '/paged' + paginationQueryString + searchQueryString

        return new Promise<SubsidiaryPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getSubsidiaries (): Promise<Subsidiary[]> {
        return new Promise<Subsidiary[]>((resolve, reject) => {
            this.axiosInstance.get(this.contextPath)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { SubsidiariesService }

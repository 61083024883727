import { AwmsUiState } from '@/states/AwmsUiState'

class AwmsUiStateService {
  private readonly _awmsUiState : AwmsUiState

  private static _instance : AwmsUiStateService

  private constructor () {
      this._awmsUiState = AwmsUiState.getInstance()
  }

  public static getInstance () : AwmsUiStateService {
      if (!AwmsUiStateService._instance) {
          AwmsUiStateService._instance = new AwmsUiStateService()
      }

      return AwmsUiStateService._instance
  }

  get awmsUiState () : AwmsUiState {
      return this._awmsUiState
  }
}
export { AwmsUiStateService }

import Vue from 'vue'
import Component from 'vue-class-component'
import { SecurityService } from '@/services/SecurityService'
import { EventService } from '@/services/EventService'
import { NotificationService } from '@/services/NotificationService'
import { ShopLocatorUiStateService } from '@/services/ShopLocatorUiStateService'
import { SubsidiariesUiStateService } from '@/services/SubsidiariesUiStateService'
import { AwmsUiState } from '@/states/AwmsUiState'
import { AwmsUiStateService } from '@/services/AwmsUiStateService'

@Component
export default class ApplicationServiceMixin extends Vue {
  readonly securityService: SecurityService = SecurityService.getInstance()
  readonly eventService: EventService = EventService.getInstance()
  readonly notificationService: NotificationService = NotificationService.getInstance()
  readonly subsidiariesUiStateService : SubsidiariesUiStateService = SubsidiariesUiStateService.getInstance()
  readonly shopLocatorUiStateService: ShopLocatorUiStateService = ShopLocatorUiStateService.getInstance()
  readonly awmsUiStateService: AwmsUiStateService = AwmsUiStateService.getInstance()
}

import { IPage, ISlice } from '@/types/BasicRemoteTypes'
import { User } from '@/types/awms/UserTypes'
import { ChannelWithAssignedUsers } from '@/types/awms/channel/ChannelTypes'

interface PagedTeoOverview extends IPage<TeoOverview>{}

interface TeoRelevantArticleSlice extends ISlice<TeoRelevantArticle>{}

interface TeoRelevantArticle {
  id: string
  itemNo: string
  tpgName: string
  brand: string
  description: string
  relevance: number
  comment: string
  imageUrl: string | null
  channelId: string
  approvementListId: string
  identNo: string
}

interface TeoOverview{
  id: string
  channelName: string
  assortmentManagers: User[] | null
  teoReceivers: User[] | null
  progressStatus: ETeoProgressStatus
  createdAt: Date
  openedAt: Date
  completedAt: Date
  closedAt: Date
}

interface CreateTeoModel {
  selectedChannels : CreateTeoChannelModel[]
  articles: TeoRelevantArticle[]
  relevantArticles:TeoRelevantArticle[]
  notRelevantArticles:TeoRelevantArticle[]
}

interface CreateTeoChannelModel {
  channel:ChannelWithAssignedUsers | null
  assortmentManagers: User[]
  teoReceivers: User[]
  errorChannel:boolean
  errorChannelMessage:string|null
  errorAssortmentManager:boolean
  errorAssortmentManagerMessage :string|null
  errorTeoReceiver:boolean
  errorTeoReceiverMessage :string|null
}

interface EditTeoChannelModel extends CreateTeoChannelModel{
  approvementListId: string | null
}

interface TeoDraft {
  id: string
  status: ETeoProgressStatus
  assignedChannel: ChannelWithAssignedUsers
  assignedAssortmentManagers: User[]
  assignedTeoReceivers: User[]
  relevantApprovementListEntries: TeoRelevantArticle[]
  revokedApprovementListEntries: TeoRelevantArticle[]
}

interface TeoDetail{
  id: string | null
  assignedChannel: string | null
  assignedAssortmentManagers: User[]| null
  assignedTeoReceivers: User[]| null
  approvementListEntries: TeoApprovementListEntry[]| null
  createdAt: Date| null
  openedAt: Date| null
  completedAt: Date| null
  closedAt: Date| null
  progressStatus: ETeoProgressStatus | null
}

interface TeoReceivers {
  id: string
  status: string
  assignedChannel: ChannelWithAssignedUsers
  assignedTeoReceivers: User[]
}

interface EditTeoReceiversModel {
  approvementListId: string
  channel: ChannelWithAssignedUsers
  teoReceivers: User[]
}

interface EditTeoModel {
  selectedChannels : EditTeoChannelModel[]
  articles: TeoRelevantArticle[]
  relevantArticles:TeoRelevantArticle[]
  notRelevantArticles:TeoRelevantArticle[]
}

interface PersistencePotentialListEntryWithComment {
  id: string
  comment: string
}

interface PersistenceCreateTeoModel{
  channelId: string
  potentialListEntries: PersistencePotentialListEntryWithComment []
  ignoredPotentialListEntries: PersistencePotentialListEntryWithComment []
  assortmentManagerIds: string []
  teoReceiverIds: string []
}

interface PersistenceEditTeoModel{
  approvementListId: string
  potentialListEntries: PersistencePotentialListEntryWithComment []
  ignoredPotentialListEntries: PersistencePotentialListEntryWithComment []
  assortmentManagerIds: string []
  teoReceiverIds: string []
}

interface PersistenceChangeTeoReceiversModel{
  approvementListId: string
  teoReceiverIds: string []
}

interface ProcessTeoModel {
  approvementLists: ProcessTeoApprovementListModel[]
  approvementListEntries: TeoApprovementListEntry[]
}

interface ProcessTeoApprovementListModel {
  approvementListId: string
  status: ETeoProgressStatus
}

interface ReviewTeoModel {
  approvementLists: ReviewTeoApprovementListModel[]
  approvementListEntries: TeoApprovementListEntry[]
}

interface ReviewTeoApprovementListModel {
  approvementListId: string
  status: ETeoProgressStatus
}

interface TeoProcess {
  id: string
  status: ETeoProgressStatus
  approvementListEntries: TeoApprovementListEntry[]
}

interface TeoReview {
  id: string
  status: ETeoProgressStatus
  approvementListEntries: TeoApprovementListEntry[]
}

interface TeoApprovementListEntry {
  id: string
  itemNo: string
  tpgName: string
  description: string
  brand : string
  status: ApprovementListEntryProcessStatus
  assortmentManagerComment: string
  teoReceiverComment: string
  assetUrl: string | null
  channelId: string
  approvementListId: string
  channelName: string
  identNo: string
}

interface PersistenceProcessTeoModel {
  approvementListId: string
  approvementListEntries: Map<string, PersistenceProcessTeoData>
}

interface PersistenceReviewTeoModel {
  approvementListId: string
  approvementListEntries: Map<string, PersistenceReviewTeoData>
}

interface PersistenceProcessTeoData {
  status: ApprovementListEntryProcessStatus
  assortmentManagerComment: string
  teoReceiverComment: string
}

interface PersistenceReviewTeoData {
  status: ApprovementListEntryProcessStatus
  assortmentManagerComment: string
}

interface PersistenceTeoCommentData {
  assortmentManagerComment: string
  teoReceiverComment: string
}

enum ApprovementListEntryProcessStatus {
  Outstanding = 'OUTSTANDING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  NotRelevant = 'NOT_RELEVANT'
}

enum ETeoProgressStatus
{
  NoValue='no value',
  Draft='DRAFT',
  Open='OPEN',
  Completed='COMPLETED',
  Closed='CLOSED',
  Initial='INITIAL'
}

export {
    TeoOverview,
    PagedTeoOverview,
    CreateTeoModel,
    TeoRelevantArticle,
    PersistenceCreateTeoModel,
    ETeoProgressStatus,
    TeoDraft,
    EditTeoModel,
    PersistenceEditTeoModel,
    TeoReceivers,
    EditTeoReceiversModel,
    PersistenceChangeTeoReceiversModel,
    TeoProcess,
    ProcessTeoModel,
    ApprovementListEntryProcessStatus,
    PersistenceProcessTeoModel,
    TeoDetail,
    ReviewTeoModel,
    TeoReview,
    PersistenceReviewTeoModel,
    TeoRelevantArticleSlice,
    PersistencePotentialListEntryWithComment,
    PersistenceProcessTeoData,
    TeoApprovementListEntry,
    PersistenceReviewTeoData,
    PersistenceTeoCommentData,
    CreateTeoChannelModel,
    EditTeoChannelModel,
    ProcessTeoApprovementListModel
}

import Vue from 'vue'
import Component from 'vue-class-component'
import dayjs from 'dayjs'
import { ETeoProgressStatus } from '@/types/awms/approvement_list/teo/TeoTypes'
import { User } from '@/types/awms/UserTypes'

@Component
export default class DisplayMixin extends Vue {
    public displayDate (date:Date| null):string {
        if (date == null) {
            return '-'
        } else {
            return dayjs(date).format('L')
        }
    }

    public displayDatetime (date:Date| null):string {
        if (date == null) {
            return '-'
        } else {
            return dayjs(date).format('L LT')
        }
    }

    public displayNullable (text: string| null) :string {
        if (text == null || text === '') {
            return '-'
        } else {
            return text
        }
    }

    public displayArrayList (list: string[]| null) :string {
        if (list == null) {
            return '-'
        } else {
            return list.join(', ')
        }
    }

    public displayUsers (users:User[]):string {
        if (!users) {
            return '-'
        }

        if (users.length === 0) {
            return '-'
        } else {
            return users.map(user => user.displayName).join(', ')
        }
    }

    public displayUser (user:User):string {
        if (!user) {
            return '-'
        }

        return user.displayName
    }
}

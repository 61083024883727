






























import Component, { mixins } from 'vue-class-component'
import BaseComponentMixin from '@/components/BaseComponentMixin'

import UserProfileNavigationItemElement from '@/components/navigation/elements/UserProfileNavigationItemElement.vue'
import NavigationItemElement from '@/components/navigation/elements/NavigationItemElement.vue'

@Component({
    components: {
        UserProfileNavigationItemElement,
        NavigationItemElement
    }
})
export default class NavigationComponent extends mixins(BaseComponentMixin) {
    inputHandler (expanded : boolean) : void {
        if (this.navigation === null) return
        this.navigation.isExpanded = expanded
    }
}

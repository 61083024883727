import { DataFooterProperties, DataTablePaginationOptions } from '@/types/VuetifyTypes'
import { DataTableItemsPerPage } from '@/definitions/UiDefinitions'
import { PaginationQuery } from '@/types/BasicRemoteTypes'

interface ListSettings {
  serverItemsLength : number
  loading : boolean
  dataTablePaginationOptions : DataTablePaginationOptions
  dataFooterProperties : DataFooterProperties
  itemsPerPage : number
  createPaginationQuery() : PaginationQuery
}

interface ListSearchSettings extends ListSettings{
  search : string
}

interface ListFilterSettings {
  hasFilters : boolean
  isFilterMenuExpanded : boolean
  clearFilters () : void
}

abstract class AbstractSearchableListSettingsImpl implements ListSearchSettings {
  private _search : string

  private _serverItemsLength : number
  private _loading : boolean
  private _dataTablePaginationOptions : DataTablePaginationOptions
  private _dataFooterProperties : DataFooterProperties
  private _itemsPerPage : number

  protected constructor (dataTablePaginationOptions : DataTablePaginationOptions, dataFooterProperties : DataFooterProperties = {
      itemsPerPageOptions: [5, 10, 25, 50]
  }) {
      this._search = ''

      this._serverItemsLength = 0
      this._loading = true
      this._dataTablePaginationOptions = dataTablePaginationOptions
      this._dataFooterProperties = dataFooterProperties
      this._itemsPerPage = DataTableItemsPerPage
  }

  get search () : string {
      return this._search
  }

  set search (value : string) {
      if (value !== this._search) {
          this._dataTablePaginationOptions.page = 1
      }

      this._search = value
  }

  get serverItemsLength (): number {
      return this._serverItemsLength
  }

  set serverItemsLength (value: number) {
      this._serverItemsLength = value
  }

  get loading (): boolean {
      return this._loading
  }

  set loading (value: boolean) {
      this._loading = value
  }

  get dataTablePaginationOptions (): DataTablePaginationOptions {
      return this._dataTablePaginationOptions
  }

  set dataTablePaginationOptions (value: DataTablePaginationOptions) {
      this._dataTablePaginationOptions = value
  }

  get dataFooterProperties (): DataFooterProperties {
      return this._dataFooterProperties
  }

  set dataFooterProperties (value: DataFooterProperties) {
      this._dataFooterProperties = value
  }

  get itemsPerPage (): number {
      return this._itemsPerPage
  }

  set itemsPerPage (value: number) {
      this._itemsPerPage = value
  }

  createPaginationQuery () : PaginationQuery {
      return new PaginationQuery(
          this.dataTablePaginationOptions.page,
          this.dataTablePaginationOptions.itemsPerPage,
          this.dataTablePaginationOptions.sortBy,
          this.dataTablePaginationOptions.sortDesc
      )
  }
}

abstract class AbstractListSettingsImpl implements ListSettings {
  private _totalElements : number
  private _loading : boolean
  private _dataTablePaginationOptions : DataTablePaginationOptions
  private _dataFooterProperties : DataFooterProperties
  private _itemsPerPage : number

  protected constructor (dataTablePaginationOptions : DataTablePaginationOptions) {
      this._totalElements = 0
      this._loading = true
      this._dataTablePaginationOptions = dataTablePaginationOptions
      this._dataFooterProperties = {
          itemsPerPageOptions: [5, 10, 25, 50]
      }
      this._itemsPerPage = DataTableItemsPerPage
  }

  get serverItemsLength (): number {
      return this._totalElements
  }

  set serverItemsLength (value: number) {
      this._totalElements = value
  }

  get loading (): boolean {
      return this._loading
  }

  set loading (value: boolean) {
      this._loading = value
  }

  get dataTablePaginationOptions (): DataTablePaginationOptions {
      return this._dataTablePaginationOptions
  }

  set dataTablePaginationOptions (value: DataTablePaginationOptions) {
      this._dataTablePaginationOptions = value
  }

  get dataFooterProperties (): DataFooterProperties {
      return this._dataFooterProperties
  }

  set dataFooterProperties (value: DataFooterProperties) {
      this._dataFooterProperties = value
  }

  get itemsPerPage (): number {
      return this._itemsPerPage
  }

  set itemsPerPage (value: number) {
      this._itemsPerPage = value
  }

  createPaginationQuery () : PaginationQuery {
      return new PaginationQuery(
          this.dataTablePaginationOptions.page,
          this.dataTablePaginationOptions.itemsPerPage,
          this.dataTablePaginationOptions.sortBy,
          this.dataTablePaginationOptions.sortDesc
      )
  }
}

abstract class AbstractSearchAndFilterableListSettingsImpl extends AbstractSearchableListSettingsImpl implements ListFilterSettings {
  private _isFilterMenuExpanded : boolean

  protected constructor (dataTablePaginationOptions : DataTablePaginationOptions) {
      super(dataTablePaginationOptions)

      this._isFilterMenuExpanded = false
  }

  get isFilterMenuExpanded (): boolean {
      return this._isFilterMenuExpanded
  }

  public toggleFilterMenuExpand (): void {
      this._isFilterMenuExpanded = !this._isFilterMenuExpanded
  }

  set isFilterMenuExpanded (value: boolean) {
      this._isFilterMenuExpanded = value
  }

  abstract get hasFilters() : boolean
  abstract clearFilters(): void;
}

export { ListSearchSettings, ListFilterSettings, AbstractSearchableListSettingsImpl, AbstractSearchAndFilterableListSettingsImpl, AbstractListSettingsImpl }

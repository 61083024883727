const DefaultLocale = 'en'
const DefaultFallbackLocale = 'en'

const TileHeight = 196
const TileWidth = 196
const TileIconSize = 96

const DataTableItemsPerPage = 10

export {
    DefaultLocale,
    DefaultFallbackLocale,
    TileHeight,
    TileWidth,
    TileIconSize,
    DataTableItemsPerPage
}

import { AbstractServiceFactory } from '@/services/remote/AbstractServiceFactory'
import { ShopLocatorVirtualService } from '@/services/remote/shoplocator/ShopLocatorVirtualService'
import { CustomerGroupsService } from '@/services/remote/shoplocator/CustomerGroupsService'
import { SubsidiariesShopLocatorService } from '@/services/remote/shoplocator/SubsidiariesShopLocatorService'
import { CustomersService } from '@/services/remote/shoplocator/CustomersService'
import { CustomerAddressesService } from '@/services/remote/shoplocator/CustomerAddressService'
import { ChangeHistoryEntriesService } from '@/services/remote/shoplocator/ChangeHistoryEntriesService'
import { BulkUpdateService } from '@/services/remote/shoplocator/BulkUpdateService'

class ShopLocatorServiceFactory extends AbstractServiceFactory {
  // @ts-ignore
  private _shopLocatorVirtualService: ShopLocatorVirtualService

  // @ts-ignore
  private _subsidiariesService: SubsidiariesShopLocatorService

  // @ts-ignore
  private _customerGroupsService: CustomerGroupsService

  // @ts-ignore
  private _customersSerivce: CustomersService

  // @ts-ignore
  private _customerAddressesService: CustomerAddressesService

  // @ts-ignore
  private _changeHistoryEntriesService : ChangeHistoryEntriesService

  // @ts-ignore
  private _bulkUpdateService : BulkUpdateService

  private constructor () {
      super('/remote/shopLocator')
  }

  private static _instance: ShopLocatorServiceFactory

  static getInstance (): ShopLocatorServiceFactory {
      if (!ShopLocatorServiceFactory._instance) {
          ShopLocatorServiceFactory._instance = new ShopLocatorServiceFactory()
      }

      return ShopLocatorServiceFactory._instance
  }

  get shopLocatorVirtualService (): ShopLocatorVirtualService {
      if (!this._shopLocatorVirtualService) {
          this._shopLocatorVirtualService = new ShopLocatorVirtualService(this)
      }

      return this._shopLocatorVirtualService
  }

  get subsidiariesService (): SubsidiariesShopLocatorService {
      if (!this._subsidiariesService) {
          this._subsidiariesService = new SubsidiariesShopLocatorService(this.axiosInstance)
      }

      return this._subsidiariesService
  }

  get customerGroupsService (): CustomerGroupsService {
      if (!this._customerGroupsService) {
          this._customerGroupsService = new CustomerGroupsService(this.axiosInstance)
      }

      return this._customerGroupsService
  }

  get customersService (): CustomersService {
      if (!this._customersSerivce) {
          this._customersSerivce = new CustomersService(this.axiosInstance)
      }

      return this._customersSerivce
  }

  get customerAddressesService (): CustomerAddressesService {
      if (!this._customerAddressesService) {
          this._customerAddressesService = new CustomerAddressesService(this.axiosInstance)
      }

      return this._customerAddressesService
  }

  get changeHistoryEntriesService (): ChangeHistoryEntriesService {
      if (!this._changeHistoryEntriesService) {
          this._changeHistoryEntriesService = new ChangeHistoryEntriesService(this.axiosInstance)
      }

      return this._changeHistoryEntriesService
  }

  get bulkUpdateService (): BulkUpdateService {
      if (!this._bulkUpdateService) {
          this._bulkUpdateService = new BulkUpdateService(this.axiosInstance)
      }

      return this._bulkUpdateService
  }
}

export { ShopLocatorServiceFactory }

import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'

class LocaleService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/v1/locales', axiosInstance)
    }

    all (): Promise<string[]> {
        const url = this.contextPath
        return new Promise<string[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { LocaleService }


import { ChannelDetail, ChannelFilterValues, EditChannelModel, EditChannelModelForPersistence } from '@/types/awms/channel/ChannelTypes'
import { Filter, FilterList } from '@/types/awms/FilterTypes'
import { User } from '@/types/awms/UserTypes'
import { AbstractEditModeState, EditModeState } from '@/types/EditModeTypes'

class ChannelEditModeState extends AbstractEditModeState implements EditModeState {
  private _editChannelModel : EditChannelModel | null

  private _locales : string[]
  private _assortmentManagers : User[]
  private _teoReceivers : User[]
  private _filterValues : ChannelFilterValues | null

  constructor () {
      super()
      this._editChannelModel = null
      this._locales = []
      this._assortmentManagers = []
      this._teoReceivers = []
      this._filterValues = null
  }

  get editChannelModel (): EditChannelModel | null {
      return this._editChannelModel
  }

  initialize (channel: ChannelDetail) {
      this._editChannelModel = {
          id: channel.id,
          name: channel.name,
          description: channel.description,
          active: channel.active,
          locales: channel.locales,
          multiVersion: channel.multiVersion,
          currentAssortmentVersion: channel.currentAssortmentVersion,
          currentAssortmentCreatedAt: channel.currentAssortmentCreatedAt,
          assortmentManagerIds: channel.assortmentManagers.map(value => value.id),
          teoReceiverIds: channel.teoReceivers.map(value => value.id),
          filter: this.initFilter(channel.filter)
      }

      this.originalItemState = this.getCurrentItemState()
  }

  private initFilter (filterString: string | null) : Filter {
      if (filterString === null || filterString === 'null' || filterString === '') {
          return this.defaultFilter()
      }

      const parsedFilter = JSON.parse(filterString)

      return {
          include: this.initFilterList(parsedFilter.include),
          exclude: this.initFilterList(parsedFilter.exclude)
      }
  }

  private initFilterList (filterList: any | null) : FilterList {
      if (filterList == null) {
          return this.defaultFilterList()
      }

      return {
          itemNos: filterList.itemNos || [],
          identNos: filterList.identNos || [],
          tpgCodes: filterList.tpgCodes || [],
          brands: filterList.brands || [],
          itemVersionStatus: filterList.itemVersionStatus || [],
          assortmentStatus: filterList.assortmentStatus || [],
          types: filterList.types || [],
          sources: filterList.sources || [],
          preparedForCountries: filterList.preparedForCountries || [],
          currentSalesVersion: filterList.currentSalesVersion,
          purchaseOkDate: filterList.purchaseOkDate || null,
          firstPurchaseOkDate: filterList.firstPurchaseOkDate || null,
          launchDate: filterList.launchDate || null
      }
  }

  public defaultFilter (): Filter {
      return {
          include: this.defaultFilterList(),
          exclude: this.defaultFilterList()
      }
  }

  private defaultFilterList (): FilterList {
      return {
          itemNos: [],
          identNos: [],
          tpgCodes: [],
          brands: [],
          itemVersionStatus: [],
          assortmentStatus: [],
          types: [],
          sources: [],
          preparedForCountries: [],
          currentSalesVersion: null,
          purchaseOkDate: null,
          firstPurchaseOkDate: null,
          launchDate: null
      }
  }

  get editChannelIdForPersistence (): string | null {
      if (this._editChannelModel === null) return null
      return this._editChannelModel.id
  }

  get editChannelModelForPersistence (): EditChannelModelForPersistence | null {
      if (this._editChannelModel === null) return null

      return {
          name: this._editChannelModel.name,
          description: this._editChannelModel.description,
          active: this._editChannelModel.active,
          locales: this._editChannelModel.locales,
          assortmentManagerIds: this._editChannelModel.assortmentManagerIds,
          teoReceiverIds: this._editChannelModel.teoReceiverIds,
          filter: JSON.stringify(this._editChannelModel.filter)
      }
  }

  set locales (value: string[]) {
      this._locales = value
  }

  get locales () {
      return this._locales
  }

  set teoReceivers (value: User[]) {
      this._teoReceivers = value
  }

  get teoReceivers () {
      return this._teoReceivers
  }

  set assortmentManagers (value: User[]) {
      this._assortmentManagers = value
  }

  get assortmentManagers () {
      return this._assortmentManagers
  }

  get filterValues (): ChannelFilterValues | null {
      return this._filterValues
  }

  set filterValues (value: ChannelFilterValues | null) {
      this._filterValues = value
  }

  clearState () {
      super.clearState()
      this._editChannelModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._editChannelModel === null ? null : JSON.stringify(this._editChannelModel)
  }
}

export { ChannelEditModeState }

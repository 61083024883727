import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import { Subsidiary, SubsidiaryPage } from '@/types/subsidiaries/SubsidiariesTypes'

class SubsidiariesShopLocatorService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/rest/v1/subsidiaries', axiosInstance)
    }

    find (search: string, paginationQuery: PaginationQuery): Promise<SubsidiaryPage> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)
        const url = this.contextPath + '/paged' + paginationQueryString + searchQueryString

        return new Promise<SubsidiaryPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getById (id: string): Promise<Subsidiary> {
        const url = this.contextPath + '/' + encodeURIComponent(`${id}`)
        return new Promise<Subsidiary>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getAssigned (): Promise<Subsidiary> {
        const url = this.contextPath + '/assigned'
        return new Promise<Subsidiary>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getAuthorized (): Promise<Subsidiary[]> {
        const url = this.contextPath + '/authorized'
        return new Promise<Subsidiary[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { SubsidiariesShopLocatorService }

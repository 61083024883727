import Component, { mixins } from 'vue-class-component'

import ApplicationStateMixin from '@/components/mixins/ApplicationStateMixin'
import ApplicationServiceMixin from '@/components/mixins/ApplicationServiceMixin'
import RemoteServiceMixin from '@/components/mixins/RemoteServiceMixin'

import DefaultPageLayout from '@/pages/layouts/DefaultPageLayout.vue'
import DisplayMixin from '@/components/mixins/DisplayMixin'

@Component({
    components: {
        DefaultPageLayout
    }
})
export default class BasePageMixin extends mixins(
    ApplicationStateMixin,
    ApplicationServiceMixin,
    RemoteServiceMixin,
    DisplayMixin
) {
  showPage : boolean = false
}

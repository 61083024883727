import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import { ChangeHistoryEntryPage } from '@/types/shoplocator/ShopLocatorTypes'

class ChangeHistoryEntriesService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/rest/v1/subsidiaries/', axiosInstance)
    }

    getCustomerGroupChanges (subsidiary: string, customerGroupId: string, paginationQuery: PaginationQuery) : Promise<ChangeHistoryEntryPage> {
        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customerGroups/' + encodeURIComponent(customerGroupId) + '/history' + paginationQueryString

        return new Promise<ChangeHistoryEntryPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getCustomerChanges (subsidiary: string, customerId: string, paginationQuery: PaginationQuery) : Promise<ChangeHistoryEntryPage> {
        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customers/' + encodeURIComponent(customerId) + '/history' + paginationQueryString

        return new Promise<ChangeHistoryEntryPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { ChangeHistoryEntriesService }

import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { CustomerAddress } from '@/types/shoplocator/ShopLocatorTypes'

class CustomerAddressesService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/rest/v1/subsidiaries/', axiosInstance)
    }

    get (subsidiary: string, customerId: string): Promise<CustomerAddress[]> {
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customers/' + encodeURIComponent(customerId) + '/addresses'

        return new Promise<CustomerAddress[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    save (subsidiary: string, customerId: string, customerAddresses: CustomerAddress[]): Promise<CustomerAddress[]> {
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customers/' + encodeURIComponent(customerId) + '/addresses'

        return new Promise<CustomerAddress[]>((resolve, reject) => {
            this.axiosInstance.put(url, customerAddresses)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { CustomerAddressesService }

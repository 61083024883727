import { SubsidiariesListSettings } from '@/types/subsidiaries/SubsidiariesListSettingsTypes'
import { SubsidiaryCreateModeState } from '@/types/subsidiaries/CreateModeTypes'
import { SubsidiaryEditModeState } from '@/types/subsidiaries/EditModeTypes'

class SubsidiariesUiState {
  private readonly _subsidiariesListSettings : SubsidiariesListSettings

  private readonly _subsidiaryCreateModeState : SubsidiaryCreateModeState
  private readonly _subsidiaryEditModeState : SubsidiaryEditModeState

  private constructor () {
      this._subsidiariesListSettings = new SubsidiariesListSettings()

      this._subsidiaryCreateModeState = new SubsidiaryCreateModeState()
      this._subsidiaryEditModeState = new SubsidiaryEditModeState()
  }

  private static _instance : SubsidiariesUiState

  static getInstance () :SubsidiariesUiState {
      if (!SubsidiariesUiState._instance) {
          SubsidiariesUiState._instance = new SubsidiariesUiState()
      }

      return SubsidiariesUiState._instance
  }

  get subsidiariesListSettings (): SubsidiariesListSettings {
      return this._subsidiariesListSettings
  }

  get subsidiaryCreateModeState (): SubsidiaryCreateModeState {
      return this._subsidiaryCreateModeState
  }

  get subsidiaryEditModeState (): SubsidiaryEditModeState {
      return this._subsidiaryEditModeState
  }
}

export { SubsidiariesUiState }

import Keycloak, { KeycloakError, KeycloakInstance } from 'keycloak-js'
import { AuthenticationState } from '@/states/AuthenticationState'
import { getProtocolHostNameAndPort } from '@/utils/UrlUtils'
import { EventService } from '@/services/EventService'
import {
    AUTHENTICATED_EVENT,
    AUTHENTICATION_INVALIDATED_EVENT,
    AUTHENTICATION_TOKEN_EXPIRED_EVENT
} from '@/definitions/EventDefinitions'

function keycloakJsonLocation (): string {
    return `${getProtocolHostNameAndPort()}/keycloak.json`
}

class SecurityService {
    private readonly _authenticationState: AuthenticationState
    private readonly _keycloak: Keycloak;

    private constructor () {
        this._authenticationState = AuthenticationState.getInstance()
        this._keycloak = new Keycloak(keycloakJsonLocation())
        this.applyKeycloakEventListeners()
    }

    private static _instance: SecurityService

    static getInstance (): SecurityService {
        if (!SecurityService._instance) {
            SecurityService._instance = new SecurityService()
        }

        return SecurityService._instance
    }

    private get eventService (): EventService {
        return EventService.getInstance()
    }

    private applyKeycloakEventListeners (): void {
        this._keycloak.onAuthSuccess = () => { this.onAuthSuccess() }
        this._keycloak.onAuthError = (err: KeycloakError) => { this.onAuthError(err) }
        this._keycloak.onAuthRefreshError = () => { this.onAuthRefreshError() }
        this._keycloak.onAuthLogout = () => { this.onAuthLogout() }
        this._keycloak.onTokenExpired = () => { this.onTokenExpired() }
    }

    private onAuthSuccess (): void {
    }

    private onAuthError (err: KeycloakError): void {
        if (err === undefined) this._authenticationState.setKeycloakAuthenticationError()
        else this._authenticationState.setKeycloakAuthenticationError(err.error, err.error_description)

        this.eventService.emit(AUTHENTICATION_INVALIDATED_EVENT)
    }

    private onAuthRefreshError (): void {
        this.logoutAsync()
    }

    private onAuthLogout (): void {
        this.logoutAsync()
    }

    private onTokenExpired (): void {
        this.eventService.emit(AUTHENTICATION_TOKEN_EXPIRED_EVENT)
    }

    initialize (): Promise<void> {
        return new Promise<void>((resolve) => {
            this._keycloak.init({
                onLoad: 'login-required',
                checkLoginIframe: false
            }).then(
                () => this.loadUserProfileAsync()
            ).then(() => {
                this._authenticationState.setAuthentication(this._keycloak)
                this.eventService.emit(AUTHENTICATED_EVENT)
            }).then(resolve)
        })
    }

    get keycloakInstance (): Keycloak {
        return this._keycloak
    }

    private loadUserProfileAsync (): Promise<Keycloak.KeycloakProfile> {
        return new Promise<Keycloak.KeycloakProfile>((resolve) => {
            this._keycloak.loadUserProfile()
                .then(resolve)
        })
    }

    logoutAsync (): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this._authenticationState.setAuthentication(null)
            this.eventService.emit(AUTHENTICATION_INVALIDATED_EVENT)

            this._keycloak.logout({ redirectUri: getProtocolHostNameAndPort() })
                .then(resolve)
                .catch(reason => {
                    this._keycloak.clearToken()
                    reject(reason)
                })
        })
    }
}

export { SecurityService }

const AdminRole = 'EWMS_ROLE_ADMIN'

const AssortmentManagerRole = 'EWMS_ROLE_ASSORTMENT_MANAGER'
const TeoReceiverRole = 'EWMS_ROLE_TEO_RECEIVER'
const AwmsRoles = [AssortmentManagerRole, TeoReceiverRole]

const EditorRole = 'EWMS_ROLE_EDITOR'
const EditorExtendedRole = 'EWMS_ROLE_EDITOR_EXTENDED'
const ShopLocatorRoles = [EditorRole, EditorExtendedRole]

const AdminModule = 'ADMIN'
const ShoplocatorModule = 'SHOP_LOCATOR'
const AwmsModule = 'AWMS'

const EAG = 'EAG'
const EAG_LC: string = EAG.toLowerCase()

export { AdminModule, AdminRole, EditorRole, EditorExtendedRole, ShoplocatorModule, AwmsModule, EAG, EAG_LC, AssortmentManagerRole, TeoReceiverRole, AwmsRoles, ShopLocatorRoles }

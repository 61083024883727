import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import Component from 'vue-class-component'

Vue.use(Vuetify)

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate'
])

export default new Vuetify({
    icons: {
        iconfont: 'mdiSvg'
    },
    theme: {
        themes: {
            light: {
                primary: '#555555',
                info: '#64B5F6',
                success: '#81C784',
                warning: '#FFB74D',
                error: '#EF5350',
                accent: '#82B1FF'
            },
            dark: {
                primary: '#ADAFB0',
                info: '#64B5F6',
                success: '#81C784',
                warning: '#FFB74D',
                error: '#EF5350',
                accent: '#82B1FF'
            }
        }
    }
})

function getProtocolHostNameAndPort (): string {
    const href = window.location.href
    const pathname = window.location.pathname

    const position = href.indexOf(pathname)
    if (position === undefined) return href

    return href.substring(0, position)
}

export { getProtocolHostNameAndPort }

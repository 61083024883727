









import Vue from 'vue'
import Component from 'vue-class-component'

import HeaderComponent from '@/components/HeaderComponent.vue'
import NavigationComponent from '@/components/navigation/NavigationComponent.vue'
import MainRegionComponent from '@/components/MainRegionComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import { DefaultLocale } from '@/definitions/UiDefinitions'
import { EventService } from '@/services/EventService'
import { AUTHENTICATED_EVENT, LOCALE_CHANGED_EVENT } from '@/definitions/EventDefinitions'
import { AuthenticationState } from '@/states/AuthenticationState'
import { LocaleService } from '@/services/LocaleService'

@Component({
    components: {
        HeaderComponent,
        NavigationComponent,
        MainRegionComponent,
        FooterComponent
    }
})
export default class App extends Vue {
    private checkForLocaleInUserProfile () {
        const authenticationState = AuthenticationState.getInstance()
        if (!authenticationState.isAuthenticated()) return

        const userProfile = authenticationState.userProfile

        if (userProfile === undefined || userProfile === null) return

        if (!userProfile.localePresent) return

        this.onLocaleChangedHandler(userProfile.locale as string)
    }

    onAuthenticatedHandler () {
        this.checkForLocaleInUserProfile()
    }

    onLocaleChangedHandler (locale : string) {
        const newLocale = locale === undefined || locale === null || locale.trim().length === 0 ? DefaultLocale : locale
        this.loadLocale(newLocale)
    }

    loadLocale (language: string) {
        LocaleService.loadFormatAsync(language)
        this.$i18n.locale = language
    }

    mounted () {
        const eventService = EventService.getInstance()
        eventService.addListener(LOCALE_CHANGED_EVENT, locale => { this.onLocaleChangedHandler(locale) })
        eventService.addListener(AUTHENTICATED_EVENT, () => { this.onAuthenticatedHandler() })

        this.checkForLocaleInUserProfile()
    }
}

import {
    ApprovementListEntryProcessStatus,
    PersistenceProcessTeoData,
    PersistenceProcessTeoModel,
    PersistenceReviewTeoData,
    PersistenceReviewTeoModel, ProcessTeoApprovementListModel,
    ReviewTeoModel,
    TeoApprovementListEntry,
    TeoReview
} from '@/types/awms/approvement_list/teo/TeoTypes'
import { AbstractEditModeState, EditModeState } from '@/types/EditModeTypes'

class TeoReviewModeState extends AbstractEditModeState implements EditModeState {
  private _reviewTeoModel : ReviewTeoModel | null

  constructor () {
      super()
      this._reviewTeoModel = null
  }

  get reviewTeoModel (): ReviewTeoModel | null {
      return this._reviewTeoModel
  }

  initialize (approvementLists: TeoReview[]) {
      this._reviewTeoModel = {
          approvementLists: [],
          approvementListEntries: []
      }

      for (const approvementList of approvementLists) {
      this._reviewTeoModel!.approvementLists.push({
          approvementListId: approvementList.id,
          status: approvementList.status
      })

      this._reviewTeoModel!.approvementListEntries.push(...approvementList.approvementListEntries)
      }
      this.originalItemState = this.getCurrentItemState()
  }

  get reviewTeoModelForPersistence (): PersistenceReviewTeoModel[] | null {
      if (this._reviewTeoModel === null) return null

      const approvementLists: PersistenceReviewTeoModel[] = []

      for (const approvementList of this.getApprovementLists()) {
          const approvementListEntryIdsWithData: Map<string, PersistenceReviewTeoData> = new Map<string, PersistenceProcessTeoData>()

          for (const approvementListEntry of this.getApprovementListEntriesForApprovementListId(approvementList.approvementListId)) {
              approvementListEntryIdsWithData.set(approvementListEntry.id, this.getApprovementListEntryData(approvementListEntry))
          }
          approvementLists.push({
              approvementListId: approvementList.approvementListId,
              approvementListEntries: approvementListEntryIdsWithData
          })
      }

      return approvementLists
  }

  getApprovementLists () : ProcessTeoApprovementListModel[] {
      return this._reviewTeoModel!.approvementLists
  }

  getApprovementListEntriesForApprovementListId (approvementListId: string) : TeoApprovementListEntry[] {
      return this._reviewTeoModel!.approvementListEntries.filter(approvementList => approvementList.approvementListId === approvementListId)
  }

  getApprovementListEntryData (approvementListEntry: TeoApprovementListEntry) : PersistenceReviewTeoData {
      return {
          status: approvementListEntry.status,
          assortmentManagerComment: approvementListEntry.assortmentManagerComment
      }
  }

  clearState () {
      super.clearState()
      this._reviewTeoModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._reviewTeoModel === null ? null : JSON.stringify(this._reviewTeoModel)
  }
}

export { TeoReviewModeState }

import { IAuditedEntity, IPage } from '@/types/BasicRemoteTypes'

enum ECustomerGroupParticipation
{
  NoValue='no value',
  Yes= 'Yes',
  No='No',
  DependsOnCustomer='DependsOnCustomer'
}

enum ECustomerParticipation
{
  NoValue='no value',
  Yes='Yes',
  No='No',
  DependsOnCustomerGroup='DependsOnCustomerGroup'
}

enum ECustomerBlockStatus
{
  NoValue='no value',
  None='None',
  All='All',
  Invoice='Invoice',
  Ship='Ship',
  NoActiveInvoice='NoActiveInvoice',
  BlockedWithUnknownReason='BlockedWithUnknownReason'
}

enum ECustomerCategory
{
  NOT_SET='NOT_SET',
  CORDLESS_POWER_TOOLS='CORDLESS_POWER_TOOLS',
  CORDLESS_GARDEN_TOOLS='CORDLESS_GARDEN_TOOLS',
  CORDLESS_POWER_AND_GARDEN_TOOLS='CORDLESS_POWER_AND_GARDEN_TOOLS',

}

enum EChangeHistoryEntityType {
  CustomerGroup='CustomerGroup',
  Customer='Customer'
}

enum EChangeHistoryModificationType {
  Create='Create',
  Update='Update',
  Delete='Delete'
}

interface BaseCustomerGroupModel {
  description: string
  displayName: string | null
  assetUrl: string | null
  participation: ECustomerGroupParticipation
}

interface CreateCustomerGroupModel extends BaseCustomerGroupModel {
}

interface UpdateCustomerGroupModel extends BaseCustomerGroupModel {
  customerGroupId: string
}

interface BaseCustomerModel {
  customerGroupId: string
  name: string
  displayName: string | null
  assetUrl: string | null
  participation: ECustomerParticipation
  blockStatus: ECustomerBlockStatus
  category:ECustomerCategory
  ignoreBlockStatus: boolean
}

interface CreateCustomerModel extends BaseCustomerModel {

}

interface UpdateCustomerModel extends BaseCustomerModel {
  customerId: string
}

interface CustomerGroup extends IAuditedEntity {
  navisionId: string
  description: string
  displayName: string
  assetUrl: string
  participation: ECustomerGroupParticipation
}

interface Customer extends IAuditedEntity {
   navisionId: string
   participation: ECustomerParticipation
   disabled: boolean
   name: string
   name2: string
   displayName: string
   assetUrl: string
   blockStatus: ECustomerBlockStatus
   category: ECustomerCategory
   ignoreBlockStatus: boolean
   visible:boolean
}

interface CustomerWithCity extends Customer {
  city : string
  zip : string
  countryCode : string
}

interface CustomerAddress extends IAuditedEntity {
  displayName: string

  address: string
  address2: string

  city: string
  zip: string
  countryCode: string

  email: string
  fax: string
  phone: string
  url: string

  latitude: number
  longitude: number

  hint: string
}

interface ChangeHistoryEntry {
  id: string
  createdBy: string
  createdAt: number
  entityType: EChangeHistoryEntityType
  modificationType: EChangeHistoryModificationType
  changes: CustomerGroupUpdateChanges | CustomerGroupCreateDeleteChanges | CustomerUpdateChanges | CustomerCreateDeleteChanges | CustomerUpdateAddressChanges
}

interface PropertyValueChange {
  old: string | null
  new: string | null
}

interface CustomerGroupUpdateChanges {
  description?: PropertyValueChange
  displayName?: PropertyValueChange
  assetUrl?: PropertyValueChange
  participation?: PropertyValueChange
}

interface CustomerGroupCreateDeleteChanges {
  description: string | null
  displayName: string | null
  assetUrl: string | null
  participation: string | null
}

interface CustomerUpdateChanges {
  name?: PropertyValueChange
  displayName?: PropertyValueChange
  assetUrl?: PropertyValueChange
  participation?: PropertyValueChange
  blockStatus?: PropertyValueChange
  category?:PropertyValueChange
  ignoreBlockStatus?:PropertyValueChange
}

interface CustomerCreateDeleteChanges {
  name: string | null
  displayName: string | null
  assetUrl: string | null
  participation: string | null
  blockStatus: string | null
  category: string | null
}

interface CustomerUpdateAddressChanges {
  addresses: CustomerAddressChanges[]
}

interface CustomerAddressChanges {
    modificationType: EChangeHistoryModificationType
    id: string
}

interface CustomerAddressUpdateChanges extends CustomerAddressChanges {
  displayName?: PropertyValueChange

  address?: PropertyValueChange
  address2?: PropertyValueChange

  city?: PropertyValueChange
  zip?: PropertyValueChange
  countryCode?: PropertyValueChange

  email?: PropertyValueChange
  fax?: PropertyValueChange
  phone?: PropertyValueChange
  url?: PropertyValueChange

  latitude?: PropertyValueChange
  longitude?: PropertyValueChange

  hint?: PropertyValueChange
}

interface CustomerAddressCreateDeleteChanges extends CustomerAddressChanges {
  displayName: string | null

  address: string | null
  address2: string | null

  city: string | null
  zip: string | null
  countryCode: string | null

  email: string | null
  fax: string | null
  phone: string | null
  url: string | null

  latitude: string | null
  longitude: string | null

  hint: string | null
}

interface CustomerGroupPage extends IPage<CustomerGroup> {
}

interface CustomerPage extends IPage<Customer> {
}

interface CustomerWithCityPage extends IPage<CustomerWithCity>{
}

function newCustomerAddressType (): CustomerAddress {
    return {
        address: '',
        address2: '',
        city: '',
        countryCode: '',
        createdAt: new Date(),
        createdBy: '',
        displayName: '',
        email: '',
        fax: '',
        id: '',
        lastModifiedAt: new Date(),
        lastModifiedBy: '',
        latitude: 0,
        longitude: 0,
        phone: '',
        url: '',
        zip: '',
        hint: ''
    }
}

interface ChangeHistoryEntryPage extends IPage<ChangeHistoryEntry> {
}

interface LocalizedPropertyValueChange extends PropertyValueChange {
  label: string
}

interface LocalizedPropertyValue {
  label: string
  value: string | null
}

export {
    ECustomerParticipation,
    ECustomerBlockStatus,
    ECustomerGroupParticipation,
    CreateCustomerGroupModel,
    UpdateCustomerGroupModel,
    CustomerGroup,
    CustomerGroupPage,
    CreateCustomerModel,
    UpdateCustomerModel,
    Customer,
    CustomerPage,
    CustomerWithCity,
    CustomerWithCityPage,
    CustomerAddress,
    ECustomerCategory,
    newCustomerAddressType,
    ChangeHistoryEntry,
    EChangeHistoryEntityType,
    EChangeHistoryModificationType,
    ChangeHistoryEntryPage,
    PropertyValueChange,
    CustomerGroupUpdateChanges,
    CustomerGroupCreateDeleteChanges,
    CustomerUpdateChanges,
    CustomerCreateDeleteChanges,
    CustomerUpdateAddressChanges,
    CustomerAddressChanges,
    CustomerAddressUpdateChanges,
    CustomerAddressCreateDeleteChanges,
    LocalizedPropertyValue,
    LocalizedPropertyValueChange
}













import Component, { mixins } from 'vue-class-component'
import BaseComponentMixin from '@/components/BaseComponentMixin'

@Component
export default class FooterComponent extends mixins(BaseComponentMixin) {
}

function hasAnyProperty (obj: object): boolean {
    if (obj === undefined || obj === null) return false

    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) return true
    }
    return false
}

function hasProperties (obj: object, ...properties : string[]) : boolean {
    if (obj === undefined || obj === null) return false
    if (properties === undefined || properties === null) return false

    for (const property of properties) {
        if (!Object.prototype.hasOwnProperty.call(obj, property)) return false
    }

    return true
}

export { hasAnyProperty, hasProperties }

// @ts-ignore
import { EventEmitter } from 'events'

class TeoEventService extends EventEmitter {
  private readonly _eventEmitter: EventEmitter

  private constructor () {
      super()
      this._eventEmitter = new EventEmitter()
  }

  private static _instance: TeoEventService

  static getInstance (): TeoEventService {
      if (!TeoEventService._instance) {
          TeoEventService._instance = new TeoEventService()
      }

      return TeoEventService._instance
  }
}

export { TeoEventService }

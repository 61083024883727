const AWMS_CONTEXT_PATH = '/awms'

const AWMS_ROUTE_PARAM_CHANNEL = 'channel'
const AWMS_ROUTE_PARAM_APPROVEMENT_LIST_ID = 'approvementListId'
const AWMS_ROUTE_PARAM_APPROVEMENT_LIST_IDS = 'approvementListIds'
const AWMS_ROUTE_PARAM_PLANNED_ASSORTMENT_ID = 'plannedAssortmentId'
const AWMS_ROUTE_PARAM_ASSORTMENT_ID = 'assortmentId'

const AWMS_ARTICLE_ITEMS_PER_PAGE = 10

export {
    AWMS_ARTICLE_ITEMS_PER_PAGE,
    AWMS_CONTEXT_PATH,
    AWMS_ROUTE_PARAM_CHANNEL,
    AWMS_ROUTE_PARAM_APPROVEMENT_LIST_ID,
    AWMS_ROUTE_PARAM_APPROVEMENT_LIST_IDS,
    AWMS_ROUTE_PARAM_PLANNED_ASSORTMENT_ID,
    AWMS_ROUTE_PARAM_ASSORTMENT_ID
}

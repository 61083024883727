import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { AssortmentCandidate } from '@/types/awms/assortment_candidate/AssortmentCandidateTypes'

class AssortmentCandidateService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/v1/assortment-candidate', axiosInstance)
    }

    candidatesByChannel (channelId: string): Promise<AssortmentCandidate[]> {
        const url = this.contextPath + '/' + channelId
        return new Promise<AssortmentCandidate[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    exportToXlsx (channelId: string | null): Promise<AxiosResponse> {
        const url = this.contextPath + '/export' + (channelId ? ('/' + encodeURIComponent(channelId)) : '')
        return new Promise<AxiosResponse>((resolve, reject) => {
            this.axiosInstance.post(url, null, { responseType: 'blob' })
                .then(response => resolve(response))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    delete (assortmentCandidateId: string): Promise<void> {
        const url = this.contextPath + '/' + encodeURIComponent(assortmentCandidateId)
        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.delete(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { AssortmentCandidateService }

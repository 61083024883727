// @ts-ignore
import { EventEmitter } from 'events'

class EventService extends EventEmitter {
  private readonly _eventEmitter: EventEmitter

  private constructor () {
      super()
      this._eventEmitter = new EventEmitter()
  }

  private static _instance: EventService

  static getInstance (): EventService {
      if (!EventService._instance) {
          EventService._instance = new EventService()
      }

      return EventService._instance
  }
}

export { EventService }

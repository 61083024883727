import { AbstractCreateModeState, CreateModeState } from '@/types/CreateModeTypes'
import { PlannedAssortmentOverviewOutstandingListSettings, PlannedAssortmentOverviewReleasedListSettings } from '@/types/awms/planned_assortments/PlannedAssortmentListSettingsTypes'

class PlannedAssortmentOverviewModeState extends AbstractCreateModeState implements CreateModeState {
  private _releasedListSettings: PlannedAssortmentOverviewReleasedListSettings
  private _outstandingListSettings: PlannedAssortmentOverviewOutstandingListSettings

  constructor () {
      super()
      this._releasedListSettings = new PlannedAssortmentOverviewReleasedListSettings()
      this._outstandingListSettings = new PlannedAssortmentOverviewOutstandingListSettings()
  }

  initialize () {
      this._releasedListSettings = new PlannedAssortmentOverviewReleasedListSettings()
      this._outstandingListSettings = new PlannedAssortmentOverviewOutstandingListSettings()
      this.originalItemState = this.getCurrentItemState()
  }

  get releasedListSettings (): PlannedAssortmentOverviewReleasedListSettings {
      return this._releasedListSettings
  }

  get outstandingListSettings (): PlannedAssortmentOverviewOutstandingListSettings {
      return this._outstandingListSettings
  }

  clearState () {
      super.clearState()
      this._releasedListSettings = new PlannedAssortmentOverviewReleasedListSettings()
      this._outstandingListSettings = new PlannedAssortmentOverviewOutstandingListSettings()
  }

  protected getCurrentItemState (): string | null {
      return null
  }
}

export { PlannedAssortmentOverviewModeState }

import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import {
    ETeoProgressStatus,
    PersistenceChangeTeoReceiversModel,
    PersistenceCreateTeoModel,
    PersistenceEditTeoModel,
    PersistenceProcessTeoModel,
    TeoDraft,
    TeoDetail,
    PagedTeoOverview,
    TeoProcess,
    TeoReceivers, TeoReview, PersistenceReviewTeoModel, PersistenceTeoCommentData
} from '@/types/awms/approvement_list/teo/TeoTypes'
import { TeoOverviewFilter } from '@/types/awms/approvement_list/teo/TeoListSettingsTypes'
class TeoService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/v1/approvement-lists/teo', axiosInstance)
    }

    all (paginationQuery: PaginationQuery, search: string | null, teoOverviewFilter:TeoOverviewFilter, filterForCurrentUser: boolean | null): Promise<PagedTeoOverview> {
        let searchQueryString = ''
        if (search && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        let filterUserQueryString = ''
        if (filterForCurrentUser) {
            filterUserQueryString += '&'
            filterUserQueryString += `filterByCurrentUser=${encodeURIComponent(filterForCurrentUser)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + paginationQueryString + searchQueryString + filterUserQueryString + this.generateFilterQueryString(teoOverviewFilter)
        return new Promise<PagedTeoOverview>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    private generateFilterQueryString (teoOverviewFilter:TeoOverviewFilter) : string {
        let filterQueryString = ''
        if (!teoOverviewFilter) {
            return filterQueryString
        }

        if (teoOverviewFilter.progressStatusFilter !== null && teoOverviewFilter.progressStatusFilter !== ETeoProgressStatus.NoValue) {
            filterQueryString += '&'
            filterQueryString += `progressStatus=${encodeURIComponent(teoOverviewFilter.progressStatusFilter)}`
        }

        if (teoOverviewFilter.createdDateRangeFilter !== null && teoOverviewFilter.createdDateRangeFilter.length > 0) {
            filterQueryString += '&'
            filterQueryString += `createdStart=${encodeURIComponent(teoOverviewFilter.createdDateRangeFilter[0])}`
            filterQueryString += '&'
            if (teoOverviewFilter.createdDateRangeFilter.length > 1) {
                filterQueryString += `createdEnd=${encodeURIComponent(teoOverviewFilter.createdDateRangeFilter[1])}`
            } else {
                filterQueryString += `createdEnd=${encodeURIComponent(teoOverviewFilter.createdDateRangeFilter[0])}`
            }
        }

        if (teoOverviewFilter.openedDateRangeFilter !== null && teoOverviewFilter.openedDateRangeFilter.length > 0) {
            filterQueryString += '&'
            filterQueryString += `openedStart=${encodeURIComponent(teoOverviewFilter.openedDateRangeFilter[0])}`
            filterQueryString += '&'
            if (teoOverviewFilter.openedDateRangeFilter.length > 1) {
                filterQueryString += `openedEnd=${encodeURIComponent(teoOverviewFilter.openedDateRangeFilter[1])}`
            } else {
                filterQueryString += `openedEnd=${encodeURIComponent(teoOverviewFilter.openedDateRangeFilter[0])}`
            }
        }

        if (teoOverviewFilter.completedDateRangeFilter !== null && teoOverviewFilter.completedDateRangeFilter.length > 0) {
            filterQueryString += '&'
            filterQueryString += `completedStart=${encodeURIComponent(teoOverviewFilter.completedDateRangeFilter[0])}`
            filterQueryString += '&'
            if (teoOverviewFilter.completedDateRangeFilter.length > 1) {
                filterQueryString += `completedEnd=${encodeURIComponent(teoOverviewFilter.completedDateRangeFilter[1])}`
            } else {
                filterQueryString += `completedEnd=${encodeURIComponent(teoOverviewFilter.completedDateRangeFilter[0])}`
            }
        }

        if (teoOverviewFilter.closedDateRangeFilter !== null && teoOverviewFilter.closedDateRangeFilter.length > 0) {
            filterQueryString += '&'
            filterQueryString += `closedStart=${encodeURIComponent(teoOverviewFilter.closedDateRangeFilter[0])}`
            filterQueryString += '&'
            if (teoOverviewFilter.closedDateRangeFilter.length > 1) {
                filterQueryString += `closedEnd=${encodeURIComponent(teoOverviewFilter.closedDateRangeFilter[1])}`
            } else {
                filterQueryString += `closedEnd=${encodeURIComponent(teoOverviewFilter.closedDateRangeFilter[0])}`
            }
        }

        return filterQueryString
    }

    get (approvementListId: string): Promise<TeoDetail> {
        const url = this.contextPath + '/' + encodeURIComponent(approvementListId)
        return new Promise<TeoDetail>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getTeoForCreate (approvementListId: string): Promise<TeoDraft> {
        const url = this.contextPath + '/' + encodeURIComponent(approvementListId) + '/for-draft'
        return new Promise<TeoDraft>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getTeosForCreate (approvementListIds: string[]): Promise<TeoDraft[]> {
        const url = this.contextPath + '/for-draft?ids=' + encodeURIComponent(approvementListIds.join(','))
        return new Promise<TeoDraft[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getTeoReceivers (approvementListId: string): Promise<TeoReceivers> {
        const url = this.contextPath + '/' + encodeURIComponent(approvementListId) + '/teo-receivers'
        return new Promise<TeoReceivers>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getTeoForProcess (approvementListId: string): Promise<TeoProcess> {
        const url = this.contextPath + '/' + encodeURIComponent(approvementListId) + '/for-process'
        return new Promise<TeoProcess>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getTeosForProcess (approvementListIds: string[]): Promise<TeoProcess[]> {
        const url = this.contextPath + '/for-process?ids=' + encodeURIComponent(approvementListIds.join(','))
        return new Promise<TeoProcess[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getTeosForReview (approvementListIds: string[]): Promise<TeoReview[]> {
        const url = this.contextPath + '/for-review?ids=' + encodeURIComponent(approvementListIds.join(','))
        return new Promise<TeoReview[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getTeoForReview (approvementListId: string): Promise<TeoReview> {
        const url = this.contextPath + '/' + encodeURIComponent(approvementListId) + '/for-review'
        return new Promise<TeoProcess>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    delete (approvementListId: string): Promise<void> {
        const url = this.contextPath + '/' + encodeURIComponent(approvementListId)
        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.delete(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    create (approvementList : PersistenceCreateTeoModel) : Promise<string> {
        const url = this.contextPath
        return new Promise<string>((resolve, reject) => {
            this.axiosInstance.post(url, approvementList)
                .then(response => resolve(response.headers.location)
                )
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject)
                )
        })
    }

    createAndOpen (approvementList : PersistenceCreateTeoModel) : Promise<string> {
        const url = this.contextPath + '/open'
        return new Promise<string>((resolve, reject) => {
            this.axiosInstance.post(url, approvementList)
                .then(response => resolve(response.headers.location)
                )
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject)
                )
        })
    }

    update (approvementList : PersistenceEditTeoModel) : Promise<string> {
        const url = this.contextPath
        return new Promise<string>((resolve, reject) => {
            this.axiosInstance.put(url, approvementList)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    updateAndOpen (approvementList : PersistenceEditTeoModel) : Promise<string> {
        const url = this.contextPath + '/open'
        return new Promise<string>((resolve, reject) => {
            this.axiosInstance.put(url, approvementList)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    changeTeoReceivers (model: PersistenceChangeTeoReceiversModel) : Promise<void> {
        const url = this.contextPath + '/' + encodeURIComponent(model.approvementListId) + '/teo-receivers'
        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.put(url, model)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    processTeo (model : PersistenceProcessTeoModel) : Promise<void> {
        const url = this.contextPath + '/' + encodeURIComponent(model.approvementListId) + '/process'
        const objFromMap = Object.fromEntries(model.approvementListEntries)

        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.put(url, objFromMap)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    completeTeo (model : PersistenceProcessTeoModel) : Promise<void> {
        const url = this.contextPath + '/' + encodeURIComponent(model.approvementListId) + '/complete'
        const objFromMap = Object.fromEntries(model.approvementListEntries)

        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.put(url, objFromMap)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    reviewTeo (model : PersistenceReviewTeoModel) : Promise<void> {
        const url = this.contextPath + '/' + encodeURIComponent(model.approvementListId) + '/review'
        const objFromMap = Object.fromEntries(model.approvementListEntries)

        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.put(url, objFromMap)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    closeTeo (model : PersistenceReviewTeoModel) : Promise<void> {
        const url = this.contextPath + '/' + encodeURIComponent(model.approvementListId) + '/close'
        const objFromMap = Object.fromEntries(model.approvementListEntries)

        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.put(url, objFromMap)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    updateComments (approvementListId: string, model : Map<string, PersistenceTeoCommentData>) : Promise<void> {
        const url = this.contextPath + '/' + encodeURIComponent(approvementListId) + '/comment'
        const objFromMap = Object.fromEntries(model)

        return new Promise<void>((resolve, reject) => {
            this.axiosInstance.put(url, objFromMap)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { TeoService }

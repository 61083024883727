import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { UsersForChannelCreation } from '@/types/awms/UserTypes'

class UserService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/v1/users', axiosInstance)
    }

    forChannelCreation (): Promise<UsersForChannelCreation> {
        const url = this.contextPath + '/for-channel-creation'
        return new Promise<UsersForChannelCreation>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { UserService }

import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { AssortmentDetail, AssortmentHistoryOverviewPage, AssortmentOverview } from '@/types/awms/assortment/AssortmentTypes'
import { PaginationQuery } from '@/types/BasicRemoteTypes'

class AssortmentService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/v1/assortment', axiosInstance)
    }

    current (): Promise<AssortmentOverview[]> {
        const url = this.contextPath + '/current'
        return new Promise<AssortmentOverview[]>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    detail (assortmentId: string): Promise<AssortmentDetail> {
        const url = this.contextPath + '/' + encodeURIComponent(assortmentId)
        return new Promise<AssortmentDetail>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    history (channelId: string, paginationQuery: PaginationQuery): Promise<AssortmentHistoryOverviewPage> {
        const paginationQueryString = paginationQuery.asQueryString(false)

        let channelQueryString = ''
        if (channelId) {
            channelQueryString += '&'
            channelQueryString += `channelId=${encodeURIComponent(channelId)}`
        }

        const url = this.contextPath + '/history' + paginationQueryString + channelQueryString
        return new Promise<AssortmentHistoryOverviewPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    exportToXlsx (assortmentId: string): Promise<AxiosResponse> {
        const url = this.contextPath + '/export/' + encodeURIComponent(assortmentId)
        return new Promise<AxiosResponse>((resolve, reject) => {
            this.axiosInstance.post(url, null, { responseType: 'blob' })
                .then(response => resolve(response))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { AssortmentService }

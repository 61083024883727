



















import Component from 'vue-class-component'
import { TileHeight, TileIconSize, TileWidth } from '@/definitions/UiDefinitions'
import { NavigationTypes } from '@/types/NavigationTypes'
import { Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component
export default class TileElement extends Vue {
    tileHeight = TileHeight
    tileWidth = TileWidth
    tileIconSize = TileIconSize

    @Prop()
    navigationItem!: NavigationTypes

    onTileClickedHandler () {
        if (this.navigationItem.destination.internal) {
            this.$router.push(this.navigationItem.destination.url)
            // eslint-disable-next-line handle-callback-err
                .catch(err => {})
        } else {
            window.open(this.navigationItem.destination.url)
        }
    }
}
























import Component, { mixins } from 'vue-class-component'
import BaseComponentMixin from '@/components/BaseComponentMixin'
import { mdiAccount } from '@mdi/js'

@Component
export default class UserProfileNavigationItemElement extends mixins(BaseComponentMixin) {
    avatarIcon : string = mdiAccount

    onClickedItem () {
        if (this.navigation === null) return

        this.navigation.toggleIsExpanded()
    }
}

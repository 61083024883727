import Keycloak, { KeycloakInstance, KeycloakProfile } from 'keycloak-js'
import { UserProfile } from '@/types/UserProfile'

enum AuthenticationErrorType {
  Unknown = 'Unknown',
  KeycloakAuthenticationError = 'KeycloakAuthenticationError',
  KeycloakNotReachableError = 'KeycloakNotReachable',
  KeycloakLogoutError = 'KeycloakLogoutError'
}

class AuthenticationErrorState {
  private _type: AuthenticationErrorType;
  private _error: string | null
  private _description: string | null

  constructor (type: AuthenticationErrorType = AuthenticationErrorType.Unknown, error: string | null = null, description: string | null = null) {
      this._type = type
      this._error = error
      this._description = description
  }

  get type (): AuthenticationErrorType {
      return this._type
  }

  set type (value: AuthenticationErrorType) {
      this._type = value
  }

  get error (): string | null {
      return this._error
  }

  set error (value: string | null) {
      this._error = value
  }

  get description (): string | null {
      return this._description
  }

  set description (value: string | null) {
      this._description = value
  }
}

class AuthenticationState {
  private _authentication: Keycloak | null
  private _userProfile: UserProfile | null
  private _errorState: AuthenticationErrorState | null

  private constructor () {
      this._authentication = null
      this._userProfile = null
      this._errorState = null
  }

  private static _instance: AuthenticationState

  static getInstance (): AuthenticationState {
      if (!AuthenticationState._instance) {
          AuthenticationState._instance = new AuthenticationState()
      }

      return AuthenticationState._instance
  }

  get authentication (): Keycloak | null {
      return this._authentication
  }

  get errorState (): AuthenticationErrorState | null {
      return this._errorState
  }

  get userProfile (): UserProfile | null {
      return this._userProfile
  }

  setAuthentication (authentication: Keycloak | null): void {
      this._authentication = authentication
      this._userProfile = this.createUserProfile()
      this._errorState = null
  }

  setKeycloakAuthenticationError (error: string | null = null, description: string | null = null): void {
      this._authentication = null
      this._userProfile = null
      this._errorState = new AuthenticationErrorState(AuthenticationErrorType.KeycloakAuthenticationError, error, description)
  }

  private createUserProfile (): UserProfile | null {
      if (this._authentication === null) return null
      if (this._authentication.profile === undefined) return null

      const id = this._authentication.subject === undefined ? null : this._authentication.subject

      return new UserProfile(
          id,
          this.getSingleKeycloakProfileValue(profile => profile.firstName),
          this.getSingleKeycloakProfileValue(profile => profile.lastName),
          this.getSingleKeycloakProfileValue(profile => profile.username),
          this.getSingleKeycloakProfileValue(profile => profile.email),
          this.getSubsidiary(),
          this.getLocale(),
          this.getEwmsModuleAccesses(),
          this.getEmwsExtendedAccesses(),
          this.getKeycloakRoles()
      )
  }

  private getSubsidiary (): string | null {
      return this.getSingleKeycloakProfileValue(profile => {
          const attributes = this.getKeycloakProfileAttributes(profile)
          if (attributes === null) return undefined

          if ('subsidiary' in attributes) {
              const subsidiaries = attributes.subsidiary as string[]
              if (subsidiaries === undefined) return undefined
              if (subsidiaries.length > 0) return subsidiaries[0]
          }

          return undefined
      })
  }

  private getLocale (): string | null {
      return this.getSingleKeycloakProfileValue(profile => {
          const attributes = this.getKeycloakProfileAttributes(profile)
          if (attributes === null) return undefined

          if ('locale' in attributes) {
              const locale = attributes.locale as string[]
              if (locale === undefined) return undefined
              if (locale.length > 0) return locale[0]
          }

          return undefined
      })
  }

  private getEwmsModuleAccesses (): string[] {
      return this.getMultiKeycloakProfileValues(profile => {
          const attributes = this.getKeycloakProfileAttributes(profile)
          if (attributes === null) return undefined

          if ('ewms_module_accesses' in attributes) {
              const moduleAccesses = attributes.ewms_module_accesses as string[]
              if (moduleAccesses === undefined) return undefined
              return moduleAccesses
          }

          return undefined
      })
  }

  private getEmwsExtendedAccesses (): string[] {
      return this.getMultiKeycloakProfileValues(profile => {
          const attributes = this.getKeycloakProfileAttributes(profile)
          if (attributes === null) return undefined

          if ('ewms_editor_extended_accesses' in attributes) {
              const extendedAccesses = attributes.ewms_editor_extended_accesses as string[]
              if (extendedAccesses === undefined) return undefined
              return extendedAccesses
          }

          return undefined
      })
  }

  private getSingleKeycloakProfileValue (cb: (keycloakProfile: KeycloakProfile) => string | undefined): string | null {
      if (this._authentication == null) return null
      if (this._authentication.profile == null) return null

      const result = cb(this._authentication.profile)
      if (result === undefined) return null

      return result
  }

  private getMultiKeycloakProfileValues (cb: (keycloakProfile: KeycloakProfile) => string[] | undefined): string[] {
      if (this._authentication == null) return []
      if (this._authentication.profile == null) return []

      const result = cb(this._authentication.profile)
      if (result === undefined) return []

      return result
  }

  private getKeycloakProfileAttributes (keycloakProfile: KeycloakProfile): Record<string, any> | null {
      if ('attributes' in keycloakProfile) {
          // @ts-ignore
          const attributes = keycloakProfile.attributes as Record<string, any>
          if (attributes === undefined) return null

          return attributes
      }

      return null
  }

  private getKeycloakRoles (): string[] {
      if (this._authentication === null) return []

      const keycloakToken = this._authentication.tokenParsed
      if (keycloakToken === undefined) return []
      if (keycloakToken.realm_access === undefined) return []

      return keycloakToken.realm_access.roles
  }

  isAuthenticated (): boolean {
      return this._authentication !== null && this._errorState === null
  }
}

export { AuthenticationState, AuthenticationErrorState, AuthenticationErrorType }

import { AbstractCreateModeState, CreateModeState } from '@/types/CreateModeTypes'
import { ChannelWithAssignedUsers } from '@/types/awms/channel/ChannelTypes'
import { TeoEventService } from '@/services/TeoEventService'
import { CreateTeoModel, PersistenceCreateTeoModel } from '@/types/awms/approvement_list/teo/TeoTypes'
import { TeoOverviewListSettings, TeoRelevantArticleListSettings } from '@/types/awms/approvement_list/teo/TeoListSettingsTypes'
import { AbstractEditModeState } from '@/types/EditModeTypes'
import { AssortmentDetailListSettings } from '@/types/awms/assortment/AssortmentListSettingsTypes'
import { DetailViewModeState } from '@/types/DetailViewModeTypes'

class AssortmentDetailModeState implements DetailViewModeState {
  private _assortmentDetailListSettings: AssortmentDetailListSettings

  constructor () {
      this._assortmentDetailListSettings = new AssortmentDetailListSettings()
  }

  initialize () {
      this._assortmentDetailListSettings = new AssortmentDetailListSettings()
  }

  get assortmentDetailListSettings (): AssortmentDetailListSettings {
      return this._assortmentDetailListSettings
  }

  clearState () {
      this._assortmentDetailListSettings = new AssortmentDetailListSettings()
  }
}

export { AssortmentDetailModeState }

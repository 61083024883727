import { NavigationTypes } from '@/types/NavigationTypes'
import { mdiHome, mdiAccountKey, mdiFactory, mdiHelpBox, mdiPower, mdiHomeGroup, mdiLaptop, mdiToolbox, mdiFileDocumentMultiple, mdiFileReplace, mdiFileMultiple, mdiFileMove, mdiFileClock, mdiArchive, mdiCart, mdiClipboard } from '@mdi/js'

const HomeNavigationItem : NavigationTypes = {
    title: 'navigation.home',
    icon: mdiHome,
    destination: {
        internal: true,
        url: '/'
    }
}

const PermissionsNavigationItem : NavigationTypes = {
    title: 'navigation.permissions',
    icon: mdiAccountKey,
    destination: {
        internal: true,
        url: '/permissions'
    }
}

const SubsidiariesNavigationItem : NavigationTypes = {
    title: 'navigation.subsidiaries',
    icon: mdiHomeGroup,
    destination: {
        internal: true,
        url: '/subsidiaries'
    }
}

const ShopLocatorNavigationItem : NavigationTypes = {
    title: 'navigation.shopLocator',
    icon: mdiFactory,
    destination: {
        internal: true,
        url: '/shoplocator'
    }
}

const HelpNavigationItem : NavigationTypes = {
    title: 'navigation.help',
    icon: mdiHelpBox,
    destination: {
        internal: false,
        url: process.env.VUE_APP_HELP_CENTER_URL
    }
}

const LogoutNavigationItem : NavigationTypes = {
    title: 'navigation.logout',
    icon: mdiPower,
    destination: {
        internal: true,
        url: '/logout'
    }
}

const AwmsNavigationItem: NavigationTypes = {
    title: 'navigation.awms.home',
    icon: mdiToolbox,
    destination: {
        internal: true,
        url: '/awms'
    }
}

const AwmsChannelItem: NavigationTypes = {
    title: 'navigation.awms.channel',
    icon: mdiLaptop,
    destination: {
        internal: true,
        url: '/awms/channel'
    }
}

const AwmsAssortmentCandidateItem: NavigationTypes = {
    title: 'navigation.awms.assortmentCandidate',
    icon: mdiFileMove,
    destination: {
        internal: true,
        url: '/awms/assortment/candidates'
    }
}

const AwmsPlannedAssortmentItem: NavigationTypes = {
    title: 'navigation.awms.plannedAssortment',
    icon: mdiFileClock,
    destination: {
        internal: true,
        url: '/awms/assortment/planned'
    }
}

const AwmsAssortmentItem: NavigationTypes = {
    title: 'navigation.awms.assortment',
    icon: mdiClipboard,
    destination: {
        internal: true,
        url: '/awms/assortment/current'
    }
}

const AwmsTeoItem: NavigationTypes = {
    title: 'navigation.awms.teo',
    icon: mdiFileDocumentMultiple,
    destination: {
        internal: true,
        url: '/awms/teo'
    }
}

const AwmsQuickUpdateItem: NavigationTypes = {
    title: 'navigation.awms.quickUpdate',
    icon: mdiFileReplace,
    destination: {
        internal: true,
        url: '/awms/quick-update'
    }
}
export {
    HomeNavigationItem,
    PermissionsNavigationItem,
    SubsidiariesNavigationItem,
    ShopLocatorNavigationItem,
    HelpNavigationItem,
    LogoutNavigationItem,
    AwmsNavigationItem,
    AwmsChannelItem,
    AwmsAssortmentItem,
    AwmsTeoItem,
    AwmsQuickUpdateItem,
    AwmsAssortmentCandidateItem,
    AwmsPlannedAssortmentItem
}

import { AbstractServiceFactory } from '@/services/remote/AbstractServiceFactory'
import { ChannelService } from '@/services/remote/awms/ChannelService'
import { LocaleService } from '@/services/remote/awms/LocaleService'
import { UserService } from '@/services/remote/awms/UserService'
import { TeoService } from '@/services/remote/awms/TeoService'
import { PotentialListService } from '@/services/remote/awms/PotentialListService'
import { AssortmentCandidateService } from '@/services/remote/awms/AssortmentCandidateService'
import { PlannedAssortmentService } from '@/services/remote/awms/PlannedAssortmentService'
import { AssortmentService } from '@/services/remote/awms/AssortmentService'
import { QuickUpdateService } from '@/services/remote/awms/QuickUpdateService'

class AwmsServiceFactory extends AbstractServiceFactory {
  // @ts-ignore
  private _channelService: ChannelService;
  // @ts-ignore
  private _localeService: LocaleService;
  // @ts-ignore
  private _teoService: TeoService;
  // @ts-ignore
  private _potentialListService: PotentialListService;
  // @ts-ignore
  private _userService: UserService;

  // @ts-ignore
  private _assortmentCandidateService: AssortmentCandidateService;
  // @ts-ignore
  private _plannedAssortmentService: PlannedAssortmentService;
  // @ts-ignore
  private _assortmentService: AssortmentService;
  // @ts-ignore
  private _quickUpdateService: QuickUpdateService;

  private constructor () {
      super('/remote/awms')
  }

  private static _instance: AwmsServiceFactory

  static getInstance (): AwmsServiceFactory {
      if (!AwmsServiceFactory._instance) {
          AwmsServiceFactory._instance = new AwmsServiceFactory()
      }

      return AwmsServiceFactory._instance
  }

  get channelService (): ChannelService {
      if (!this._channelService) {
          this._channelService = new ChannelService(this.axiosInstance)
      }

      return this._channelService
  }

  get localeService (): LocaleService {
      if (!this._localeService) {
          this._localeService = new LocaleService(this.axiosInstance)
      }

      return this._localeService
  }

  get teoService (): TeoService {
      if (!this._teoService) {
          this._teoService = new TeoService(this.axiosInstance)
      }

      return this._teoService
  }

  get potentialListService (): PotentialListService {
      if (!this._potentialListService) {
          this._potentialListService = new PotentialListService(this.axiosInstance)
      }

      return this._potentialListService
  }

  get assortmentCandidateService (): AssortmentCandidateService {
      if (!this._assortmentCandidateService) {
          this._assortmentCandidateService = new AssortmentCandidateService(this.axiosInstance)
      }

      return this._assortmentCandidateService
  }

  get plannedAssortmentService (): PlannedAssortmentService {
      if (!this._plannedAssortmentService) {
          this._plannedAssortmentService = new PlannedAssortmentService(this.axiosInstance)
      }

      return this._plannedAssortmentService
  }

  get assortmentService (): AssortmentService {
      if (!this._assortmentService) {
          this._assortmentService = new AssortmentService(this.axiosInstance)
      }

      return this._assortmentService
  }

  get userService (): UserService {
      if (!this._userService) {
          this._userService = new UserService(this.axiosInstance)
      }

      return this._userService
  }

  get quickUpdateService (): QuickUpdateService {
      if (!this._quickUpdateService) {
          this._quickUpdateService = new QuickUpdateService(this.axiosInstance)
      }

      return this._quickUpdateService
  }
}

export { AwmsServiceFactory }

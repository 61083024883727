import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import {
    CreateCustomerGroupModel,
    CustomerGroup,
    CustomerGroupPage,
    ECustomerGroupParticipation, UpdateCustomerGroupModel
} from '@/types/shoplocator/ShopLocatorTypes'
import { PaginationQuery } from '@/types/BasicRemoteTypes'

class CustomerGroupsService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/rest/v1/subsidiaries/', axiosInstance)
    }

    create (subsidiary: string, model : CreateCustomerGroupModel) : Promise<CustomerGroup> {
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customerGroups/'

        return new Promise<CustomerGroup>((resolve, reject) => {
            this.axiosInstance.post(url, model)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    update (subsidiary: string, model :UpdateCustomerGroupModel) : Promise<CustomerGroup> {
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customerGroups/' + encodeURIComponent(model.customerGroupId)

        return new Promise<CustomerGroup>((resolve, reject) => {
            this.axiosInstance.put(url, model)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    getById (subsidiary: string, customerGroupId: string): Promise<CustomerGroup> {
        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customerGroups/' + encodeURIComponent(customerGroupId)

        return new Promise<CustomerGroup>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    find (subsidiary: string, participationFilter: ECustomerGroupParticipation, search: string, paginationQuery: PaginationQuery): Promise<CustomerGroupPage> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        let filterQueryString = ''
        if (participationFilter !== null && participationFilter !== ECustomerGroupParticipation.NoValue) {
            filterQueryString += '&'
            filterQueryString += `participation=${encodeURIComponent(participationFilter)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(false)

        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customerGroups' + paginationQueryString + searchQueryString + filterQueryString

        return new Promise<CustomerGroupPage>((resolve, reject) => {
            this.axiosInstance.get(url)
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }

    exportCustomerGroupsAsCsv (subsidiary: string, participationFilter: ECustomerGroupParticipation, search: string, paginationQuery: PaginationQuery) : Promise<Blob> {
        let searchQueryString = ''
        if (search !== null && search.length > 0) {
            const likeSearch = search + '%'
            searchQueryString += '&'
            searchQueryString += `search=${encodeURIComponent(likeSearch)}`
        }

        let filterQueryString = ''
        if (participationFilter !== null && participationFilter !== ECustomerGroupParticipation.NoValue) {
            filterQueryString += '&'
            filterQueryString += `participation=${encodeURIComponent(participationFilter)}`
        }

        const paginationQueryString = paginationQuery.asQueryString(true)

        const url = this.contextPath + encodeURIComponent(subsidiary) + '/customerGroups/export' + paginationQueryString + searchQueryString + filterQueryString
        return new Promise<Blob>((resolve, reject) => {
            this.axiosInstance.get(url, { responseType: 'blob' })
                .then(response => {
                    resolve(new Blob([response.data], { type: 'application/csv' }))
                })
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { CustomerGroupsService }

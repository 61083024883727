const SHOP_LOCATOR_CONTEXT_PATH = '/shoplocator'

const SHOP_LOCATOR_ROUTE_PARAM_SUBSIDIARY = 'subsidiary'
const SHOP_LOCATOR_ROUTE_PARAM_CUSTOMER_GROUP = 'customerGroup'
const SHOP_LOCATOR_ROUTE_PARAM_CUSTOMER = 'customer'

const SHOP_LOCATOR_ASSET_IMAGE_SIZE = 140
const SHOP_LOCATOR_HISTORY_ITEMS_PER_PAGE = 5
const SHOP_LOCATOR_HISTORY_ITEM_AVATAR_SIZE = 32

export {
    SHOP_LOCATOR_CONTEXT_PATH,
    SHOP_LOCATOR_ROUTE_PARAM_SUBSIDIARY,
    SHOP_LOCATOR_ROUTE_PARAM_CUSTOMER_GROUP,
    SHOP_LOCATOR_ROUTE_PARAM_CUSTOMER,
    SHOP_LOCATOR_ASSET_IMAGE_SIZE,
    SHOP_LOCATOR_HISTORY_ITEMS_PER_PAGE,
    SHOP_LOCATOR_HISTORY_ITEM_AVATAR_SIZE
}

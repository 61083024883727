import { DataFooterProperties, DataTablePaginationOptions } from '@/types/VuetifyTypes'
import { DataTableItemsPerPage } from '@/definitions/UiDefinitions'
import { ISlice, ISliceHeader, PaginationQuery } from '@/types/BasicRemoteTypes'

interface SliceSettings {
  serverItemsLength : number
  serverItemsLengthUnknown : boolean
  loading : boolean
  dataTablePaginationOptions : DataTablePaginationOptions
  dataFooterProperties : DataFooterProperties
  itemsPerPage : number
  createPaginationQuery() : PaginationQuery
}

interface SliceSearchSettings extends SliceSettings{
  search : string
}

interface SliceFilterSettings {
  hasFilters : boolean
  isFilterMenuExpanded : boolean
  clearFilters () : void
}

abstract class AbstractSearchableSliceSettingsImpl implements SliceSearchSettings {
  private _search : string

  private _serverItemsLength : number
  private _serverItemsLengthUnknown : boolean
  private _loading : boolean
  private _dataTablePaginationOptions : DataTablePaginationOptions
  private _dataFooterProperties : DataFooterProperties
  private _itemsPerPage : number

  protected constructor (dataTablePaginationOptions : DataTablePaginationOptions, dataFooterProperties : DataFooterProperties = {
      itemsPerPageOptions: [5, 10, 25, 50]
  }) {
      this._search = ''

      this._serverItemsLength = 0
      this._serverItemsLengthUnknown = true
      this._loading = true
      this._dataTablePaginationOptions = dataTablePaginationOptions
      this._dataFooterProperties = dataFooterProperties
      this._itemsPerPage = DataTableItemsPerPage
      this._dataFooterProperties.showFirstLastPage = true
  }

  get search () : string {
      return this._search
  }

  set search (value : string) {
      this._search = value
  }

  get serverItemsLength (): number {
      return this._serverItemsLength
  }

  set serverItemsLength (value: number) {
      this._serverItemsLength = value
  }

  get serverItemsLengthUnknown (): boolean {
      return this._serverItemsLengthUnknown
  }

  set serverItemsLengthUnknown (value: boolean) {
      this._serverItemsLengthUnknown = value
  }

  get loading (): boolean {
      return this._loading
  }

  set loading (value: boolean) {
      this._loading = value
  }

  get dataTablePaginationOptions (): DataTablePaginationOptions {
      return this._dataTablePaginationOptions
  }

  set dataTablePaginationOptions (value: DataTablePaginationOptions) {
      this._dataTablePaginationOptions = value
  }

  get dataFooterProperties (): DataFooterProperties {
      return this._dataFooterProperties
  }

  set dataFooterProperties (value: DataFooterProperties) {
      this._dataFooterProperties = value
  }

  get itemsPerPage (): number {
      return this._itemsPerPage
  }

  set itemsPerPage (value: number) {
      this._itemsPerPage = value
  }

  createPaginationQuery () : PaginationQuery {
      return new PaginationQuery(
          this.dataTablePaginationOptions.page,
          this.dataTablePaginationOptions.itemsPerPage,
          this.dataTablePaginationOptions.sortBy,
          this.dataTablePaginationOptions.sortDesc
      )
  }

  updateData (articleSlice: ISlice<any>) {
      this.loading = false

      if (articleSlice.content) {
          let serverItemsLengthAddition = 0
          if (articleSlice.next) {
              serverItemsLengthAddition = 1
          }
          const uniqueNumberOfElements = new Set(articleSlice.items.map(article => article.itemNo)).size

          const newServerItemsLength = (articleSlice.number * articleSlice.size) + uniqueNumberOfElements + serverItemsLengthAddition

          if (this.serverItemsLengthUnknown && newServerItemsLength > this.serverItemsLength) {
              this.serverItemsLength = newServerItemsLength
          }
      } else {
          this.serverItemsLength = 0
          this.serverItemsLengthUnknown = true
      }
  }
}

abstract class AbstractSliceSettingsImpl implements SliceSettings {
  private _serverItemsLength : number
  private _serverItemsLengthUnknown : boolean
  private _loading : boolean
  private _dataTablePaginationOptions : DataTablePaginationOptions
  private _dataFooterProperties : DataFooterProperties
  private _itemsPerPage : number

  protected constructor (dataTablePaginationOptions : DataTablePaginationOptions) {
      this._serverItemsLength = 0
      this._serverItemsLengthUnknown = true
      this._loading = true
      this._dataTablePaginationOptions = dataTablePaginationOptions
      this._dataFooterProperties = {
          itemsPerPageOptions: [5, 10, 25, 50]
      }
      this._itemsPerPage = DataTableItemsPerPage
  }

  get serverItemsLength (): number {
      return this._serverItemsLength
  }

  set serverItemsLength (value: number) {
      this._serverItemsLength = value
  }

  get serverItemsLengthUnknown (): boolean {
      return this._serverItemsLengthUnknown
  }

  set serverItemsLengthUnknown (value: boolean) {
      this._serverItemsLengthUnknown = value
  }

  get loading (): boolean {
      return this._loading
  }

  set loading (value: boolean) {
      this._loading = value
  }

  get dataTablePaginationOptions (): DataTablePaginationOptions {
      return this._dataTablePaginationOptions
  }

  set dataTablePaginationOptions (value: DataTablePaginationOptions) {
      this._dataTablePaginationOptions = value
  }

  get dataFooterProperties (): DataFooterProperties {
      return this._dataFooterProperties
  }

  set dataFooterProperties (value: DataFooterProperties) {
      this._dataFooterProperties = value
  }

  get itemsPerPage (): number {
      return this._itemsPerPage
  }

  set itemsPerPage (value: number) {
      this._itemsPerPage = value
  }

  createPaginationQuery () : PaginationQuery {
      return new PaginationQuery(
          this.dataTablePaginationOptions.page,
          this.dataTablePaginationOptions.itemsPerPage,
          this.dataTablePaginationOptions.sortBy,
          this.dataTablePaginationOptions.sortDesc
      )
  }
}

abstract class AbstractSearchAndFilterableSliceSettingsImpl extends AbstractSearchableSliceSettingsImpl implements SliceFilterSettings {
  private _isFilterMenuExpanded : boolean

  protected constructor (dataTablePaginationOptions : DataTablePaginationOptions) {
      super(dataTablePaginationOptions)

      this._isFilterMenuExpanded = false
  }

  get isFilterMenuExpanded (): boolean {
      return this._isFilterMenuExpanded
  }

  public toggleFilerMenuExpand (): void {
      this._isFilterMenuExpanded = !this._isFilterMenuExpanded
  }

  set isFilterMenuExpanded (value: boolean) {
      this._isFilterMenuExpanded = value
  }

  abstract get hasFilters() : boolean
  abstract clearFilters(): void;
}

export { SliceSearchSettings, SliceFilterSettings, AbstractSearchableSliceSettingsImpl, AbstractSearchAndFilterableSliceSettingsImpl, AbstractSliceSettingsImpl }

import {
    Customer,
    CustomerAddress,
    CustomerGroup,
    UpdateCustomerGroupModel,
    UpdateCustomerModel
} from '@/types/shoplocator/ShopLocatorTypes'
import { addAll, clearArray } from '@/utils/ArrayUtil'
import { AbstractEditModeState, EditModeState } from '@/types/EditModeTypes'
import { Subsidiary } from '@/types/subsidiaries/SubsidiariesTypes'

class CustomerGroupEditModeState extends AbstractEditModeState implements EditModeState {
  private _subsidiary : Subsidiary | null
  private _customerGroup : CustomerGroup | null
  private _updateCustomerGroupModel : UpdateCustomerGroupModel | null

  constructor () {
      super()
      this._subsidiary = null
      this._customerGroup = null
      this._updateCustomerGroupModel = null
  }

  get subsidiary (): Subsidiary | null {
      return this._subsidiary
  }

  get customerGroup (): CustomerGroup | null {
      return this._customerGroup
  }

  get updateCustomerGroupModel () : UpdateCustomerGroupModel | null {
      return this._updateCustomerGroupModel
  }

  initialize (subsidiary : Subsidiary, customerGroup : CustomerGroup | null) {
      this._subsidiary = subsidiary
      this._customerGroup = customerGroup
      this._updateCustomerGroupModel = null

      if (this._customerGroup !== null) {
          this._updateCustomerGroupModel = {
              customerGroupId: this._customerGroup.id,
              description: this._customerGroup.description,
              displayName: this._customerGroup.displayName,
              assetUrl: this._customerGroup.assetUrl,
              participation: this._customerGroup.participation
          }
      }

      this.originalItemState = this.getCurrentItemState()
  }

  clearState () {
      super.clearState()
      this._subsidiary = null
      this._customerGroup = null
      this._updateCustomerGroupModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._updateCustomerGroupModel === null ? null : JSON.stringify(this._updateCustomerGroupModel)
  }
}

class CustomerEditModeState extends AbstractEditModeState implements EditModeState {
  private _subsidiary : Subsidiary | null
  private _customerGroup : CustomerGroup | null
  private _customer : Customer | null
  private _updateCustomerModel : UpdateCustomerModel | null

  private readonly _addresses : CustomerAddress[]

  constructor () {
      super()
      this._subsidiary = null
      this._customerGroup = null
      this._customer = null
      this._updateCustomerModel = null

      this._addresses = []
  }

  get subsidiary (): Subsidiary | null {
      return this._subsidiary
  }

  get customerGroup (): CustomerGroup | null {
      return this._customerGroup
  }

  get customer (): Customer | null {
      return this._customer
  }

  get updateCustomerModel (): UpdateCustomerModel | null {
      return this._updateCustomerModel
  }

  get addresses (): CustomerAddress[] {
      return this._addresses
  }

  initialize (subsidiary : Subsidiary, customerGroup : CustomerGroup, customer : Customer | null, customerAddresses : CustomerAddress[] | null) {
      this._subsidiary = subsidiary
      this._customerGroup = customerGroup
      this._customer = customer
      this._updateCustomerModel = null

      if (this._customer !== null) {
          this._updateCustomerModel = {
              customerGroupId: this._customerGroup.id,
              customerId: this._customer.id,
              name: this._customer.name,
              displayName: this._customer.displayName,
              assetUrl: this._customer.assetUrl,
              participation: this._customer.participation,
              blockStatus: this._customer.blockStatus,
              category: this._customer.category,
              ignoreBlockStatus: this._customer.ignoreBlockStatus
          }

          clearArray(this._addresses)
          if (customerAddresses !== null) {
              addAll(this._addresses, customerAddresses)
          }
      }

      this.originalItemState = this.getCurrentItemState()
  }

  clearState () {
      super.clearState()

      this._subsidiary = null
      this._customerGroup = null
      this._customer = null
      this._updateCustomerModel = null

      clearArray(this._addresses)
  }

  protected getCurrentItemState (): string | null {
      return this._updateCustomerModel === null ? null : (JSON.stringify(this._updateCustomerModel) + JSON.stringify(this._addresses))
  }
}

export { CustomerGroupEditModeState, CustomerEditModeState }














import Vue from 'vue'
import Component from 'vue-class-component'

import { AlertType } from '@/types/VuetifyTypes'
import { EventService } from '@/services/EventService'
import { UserInformation } from '@/types/CustomVuetifyTypes'
import { USER_INFORMATION_EVENT, USER_INFORMATION_RESET_EVENT } from '@/definitions/EventDefinitions'

@Component({})
export default class UserInformationComponent extends Vue {
  registered : boolean = false

  dismissible : boolean = true
  prominent : boolean = false
  type : AlertType = 'success'
  isVisible : boolean = false
  message : string | null = null

  onUserInformation (userInformation : UserInformation) {
      if (userInformation === undefined || userInformation === null) return

      this.message = userInformation.message
      this.type = userInformation.type

      this.$vuetify.goTo(0)

      this.isVisible = true
  }

  onUserInformationReset () {
      this.message = null
      this.isVisible = false
  }

  mounted () {
      if (this.registered) return

      EventService.getInstance().addListener(USER_INFORMATION_EVENT, (userInformation) => { this.onUserInformation(userInformation) })
      EventService.getInstance().addListener(USER_INFORMATION_RESET_EVENT, () => { this.onUserInformationReset() })

      this.registered = true
  }
}

import { AbstractBackendService } from '@/services/remote/AbstractBackendService'
import { AxiosError, AxiosInstance } from 'axios'
import { PaginationQuery } from '@/types/BasicRemoteTypes'
import { Subsidiary, SubsidiaryPage } from '@/types/subsidiaries/SubsidiariesTypes'

class BulkUpdateService extends AbstractBackendService {
    constructor (axiosInstance: AxiosInstance) {
        super('/api/rest/v1/bulkUpdate', axiosInstance)
    }

    update (file : File) : Promise<any> {
        const url = this.contextPath + '/'
        return new Promise<any>((resolve, reject) => {
            const formData = new FormData()
            formData.append('file', file)

            this.axiosInstance.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => resolve(response.data))
                .catch((axiosError: AxiosError) => this.transformAndRejectAxiosError(axiosError, reject))
        })
    }
}

export { BulkUpdateService }

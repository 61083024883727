import { ShopLocatorServiceFactory } from '@/services/remote/shoplocator/ShopLocatorServiceFactory'
import { Customer, CustomerGroup } from '@/types/shoplocator/ShopLocatorTypes'
import { transFormAndRejectAxiosError } from '@/utils/AxiosUtil'
import { AxiosError } from 'axios'
import { Subsidiary } from '@/types/subsidiaries/SubsidiariesTypes'

class ShopLocatorVirtualService {
  private readonly _shopLocatorServiceFactory: ShopLocatorServiceFactory

  constructor (shopLocatorServiceFactory: ShopLocatorServiceFactory) {
      this._shopLocatorServiceFactory = shopLocatorServiceFactory
  }

  getSubsidiaryAndCustomerGroup (subsidiaryId: string, customerGroupId: string): Promise<[Subsidiary, CustomerGroup]> {
      return new Promise<[Subsidiary, CustomerGroup]>((resolve, reject) => {
          let ou: Subsidiary
          let cg: CustomerGroup
          this._shopLocatorServiceFactory.subsidiariesService.getById(subsidiaryId)
              .then(data => { ou = data })
              .then(() => {
                  this._shopLocatorServiceFactory.customerGroupsService.getById(ou.name, customerGroupId)
                      .then(data => { cg = data })
                      .then(() => resolve([ou, cg]))
              })
              .catch((axiosError: AxiosError) => transFormAndRejectAxiosError(axiosError, reject))
      })
  }

  getSubsidiaryAndCustomerGroupAndCustomer (subsidiaryId: string, customerGroupId: string, customerId: string): Promise<[Subsidiary, CustomerGroup, Customer]> {
      return new Promise<[Subsidiary, CustomerGroup, Customer]>((resolve, reject) => {
          let ou: Subsidiary
          let cg: CustomerGroup
          let c: Customer

          this._shopLocatorServiceFactory.subsidiariesService.getById(subsidiaryId)
              .then(data => { ou = data })
              .then(() => {
                  this._shopLocatorServiceFactory.customerGroupsService.getById(ou.name, customerGroupId)
                      .then(data => { cg = data })
                      .then(() => {
                          this._shopLocatorServiceFactory.customersService.getById(ou.name, customerId)
                              .then(data => { c = data })
                              .then(() => resolve([ou, cg, c]))
                      })
              })
              .catch((axiosError: AxiosError) => transFormAndRejectAxiosError(axiosError, reject))
      })
  }

  getSubsidiaryAndCustomer (subsidiaryId: string, customerId: string): Promise<[Subsidiary, Customer]> {
      return new Promise<[Subsidiary, Customer]>((resolve, reject) => {
          let ou: Subsidiary
          let c: Customer
          this._shopLocatorServiceFactory.subsidiariesService.getById(subsidiaryId)
              .then(data => { ou = data })
              .then(() => {
                  this._shopLocatorServiceFactory.customersService.getById(ou.name, customerId)
                      .then(data => { c = data })
                      .then(() => resolve([ou, c]))
              })
              .catch((axiosError: AxiosError) => transFormAndRejectAxiosError(axiosError, reject))
      })
  }
}

export { ShopLocatorVirtualService }

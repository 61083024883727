import {
    ApprovementListEntryProcessStatus,
    PersistenceProcessTeoModel,
    PersistenceProcessTeoData,
    ProcessTeoModel,
    TeoApprovementListEntry,
    TeoProcess,
    PersistenceCreateTeoModel,
    ProcessTeoApprovementListModel
} from '@/types/awms/approvement_list/teo/TeoTypes'
import { AbstractEditModeState, EditModeState } from '@/types/EditModeTypes'

class TeoProcessModeState extends AbstractEditModeState implements EditModeState {
  private _processTeoModel : ProcessTeoModel | null

  constructor () {
      super()
      this._processTeoModel = null
  }

  get processTeoModel (): ProcessTeoModel | null {
      return this._processTeoModel
  }

  initialize (approvementLists: TeoProcess[]) {
      this._processTeoModel = {
          approvementLists: [],
          approvementListEntries: []
      }

      for (const approvementList of approvementLists) {
        this._processTeoModel!.approvementLists.push({
            approvementListId: approvementList.id,
            status: approvementList.status
        })

        this._processTeoModel!.approvementListEntries.push(...approvementList.approvementListEntries)
      }
      this.originalItemState = this.getCurrentItemState()
  }

  get processTeoModelForPersistence (): PersistenceProcessTeoModel[] | null {
      if (this._processTeoModel === null) return null

      const approvementLists: PersistenceProcessTeoModel[] = []

      for (const approvementList of this.getApprovementLists()) {
          const approvementListEntryIdsWithData: Map<string, PersistenceProcessTeoData> = new Map<string, PersistenceProcessTeoData>()

          for (const approvementListEntry of this.getApprovementListEntriesForApprovementListId(approvementList.approvementListId)) {
              approvementListEntryIdsWithData.set(approvementListEntry.id, this.getApprovementListEntryData(approvementListEntry))
          }
          approvementLists.push({
              approvementListId: approvementList.approvementListId,
              approvementListEntries: approvementListEntryIdsWithData
          })
      }

      return approvementLists
  }

  getApprovementLists () : ProcessTeoApprovementListModel[] {
      return this._processTeoModel!.approvementLists
  }

  getApprovementListEntriesForApprovementListId (approvementListId: string) : TeoApprovementListEntry[] {
      return this._processTeoModel!.approvementListEntries.filter(approvementList => approvementList.approvementListId === approvementListId)
  }

  getApprovementListEntryData (approvementListEntry: TeoApprovementListEntry) : PersistenceProcessTeoData {
      return {
          status: approvementListEntry.status,
          assortmentManagerComment: approvementListEntry.assortmentManagerComment,
          teoReceiverComment: approvementListEntry.teoReceiverComment
      }
  }

  clearState () {
      super.clearState()
      this._processTeoModel = null
  }

  protected getCurrentItemState (): string | null {
      return this._processTeoModel === null ? null : JSON.stringify(this._processTeoModel)
  }
}

export { TeoProcessModeState }

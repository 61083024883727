const AUTHENTICATED_EVENT = 'authenticated'
const AUTHENTICATION_INVALIDATED_EVENT = 'authenticationInvalidated'
const AUTHENTICATION_TOKEN_EXPIRED_EVENT = 'authenticationTokenExpired'

const LOCALE_CHANGED_EVENT = 'localeChanged'

const LOADING_INFORMATION_EVENT = 'loadingInformation'
const LOADING_INFORMATION_RESET_EVENT = 'loadingInformationReset'

const USER_INFORMATION_EVENT = 'userInformation'
const USER_INFORMATION_RESET_EVENT = 'userInformationReset'

const REQUEST_LOAD_USERS_EVENT = 'requestLoadUsersEvent'
const REQUEST_LOAD_ARTICLES_EVENT = 'requestLoadArticlesEvent'

const SHOPLOCATOR_BULK_UPDATE_PERFORMED_EVENT = 'shopLocatorBulkUpdatePerformedEvent'

export {
    AUTHENTICATED_EVENT,
    AUTHENTICATION_INVALIDATED_EVENT,
    AUTHENTICATION_TOKEN_EXPIRED_EVENT,
    LOCALE_CHANGED_EVENT,
    LOADING_INFORMATION_EVENT,
    LOADING_INFORMATION_RESET_EVENT,
    USER_INFORMATION_EVENT,
    USER_INFORMATION_RESET_EVENT,
    REQUEST_LOAD_USERS_EVENT,
    SHOPLOCATOR_BULK_UPDATE_PERFORMED_EVENT,
    REQUEST_LOAD_ARTICLES_EVENT
}

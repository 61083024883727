import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../pages/HomePage.vue'
import { AuthenticationState } from '@/states/AuthenticationState'
import { RouteMetaData } from '@/router/Types'
import { roleToOrdinal } from '@/types/core/CoreTypes'
import { AdminRole, AssortmentManagerRole, AwmsModule, ShoplocatorModule } from '@/definitions/EwmsCoreDefinitions'
import { SHOP_LOCATOR_CONTEXT_PATH } from '@/definitions/ShopLocatorDefinitions'
import { SUBSIDIARIES_CONTEXT_PATH } from '@/definitions/SubsidiariesDefinitions'
import { AWMS_CONTEXT_PATH } from '@/definitions/AwmsDefinitions'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/permissions',
        name: 'permissions',
        component: () => import(/* webpackChunkName: "permissions" */ '../pages/core/PermissionsPage.vue'),
        meta: {
            authenticationRequirements: {
                role: AdminRole
            }
        }
    },
    {
        path: SUBSIDIARIES_CONTEXT_PATH,
        name: 'SubsidiariesOverviewPage',
        component: () => import(/* webpackChunkName: "subsidiariesOverviewPage" */ '../pages/subsidiaries/SubsidiariesOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                role: AdminRole
            }
        }
    },
    {
        path: SUBSIDIARIES_CONTEXT_PATH + '/create',
        name: 'SubsidiariesCreatePage',
        component: () => import(/* webpackChunkName: "subsidiariesCreatePage" */ '../pages/subsidiaries/SubsidiariesCreatePage.vue'),
        meta: {
            authenticationRequirements: {
                role: AdminRole
            }
        }
    },
    {
        path: SUBSIDIARIES_CONTEXT_PATH + '/:subsidiary',
        name: 'SubsidiariesEditPage',
        component: () => import(/* webpackChunkName: "subsidiariesEditPage" */ '../pages/subsidiaries/SubsidiariesEditPage.vue'),
        meta: {
            authenticationRequirements: {
                role: AdminRole
            }
        }
    },
    {
        path: SHOP_LOCATOR_CONTEXT_PATH,
        name: 'ShopLocatorOverviewPage',
        component: () => import(/* webpackChunkName: "ShopLocatorOverviewPage" */ '../pages/shoplocator/ShopLocatorOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: ShoplocatorModule
            }
        }
    },
    {
        path: SHOP_LOCATOR_CONTEXT_PATH + '/subsidiary/:subsidiary/customerGroup/create',
        name: 'ShopLocatorCustomerGroupCreatePage',
        component: () => import(/* webpackChunkName: "ShopLocatorCustomerGroupCreatePage" */ '../pages/shoplocator/CustomerGroupCreatePage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: ShoplocatorModule
            }
        }
    },
    {
        path: SHOP_LOCATOR_CONTEXT_PATH + '/subsidiary/:subsidiary/customerGroup/:customerGroup/edit',
        name: 'ShopLocatorCustomerGroupEditPage',
        component: () => import(/* webpackChunkName: "ShopLocatorCustomerGroupEditPage" */ '../pages/shoplocator/CustomerGroupEditPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: ShoplocatorModule
            }
        }
    },
    {
        path: SHOP_LOCATOR_CONTEXT_PATH + '/subsidiary/:subsidiary/customerGroup/:customerGroup',
        name: 'ShopLocatorCustomerGroupViewPage',
        component: () => import(/* webpackChunkName: "ShopLocatorCustomerGroupViewPage" */ '../pages/shoplocator/CustomerGroupViewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: ShoplocatorModule
            }
        }
    },
    {
        path: SHOP_LOCATOR_CONTEXT_PATH + '/subsidiary/:subsidiary/customerGroup/:customerGroup/customer/create',
        name: 'ShopLocatorCustomerCreatePage',
        component: () => import(/* webpackChunkName: "ShopLocatorCustomerCreatePage" */ '../pages/shoplocator/CustomerCreatePage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: ShoplocatorModule
            }
        }
    },
    {
        path: SHOP_LOCATOR_CONTEXT_PATH + '/subsidiary/:subsidiary/customerGroup/:customerGroup/customer/:customer/edit',
        name: 'ShopLocatorCustomerGroupCustomerEditPage',
        component: () => import(/* webpackChunkName: "ShopLocatorCustomerGroupCustomerEditPage" */ '../pages/shoplocator/CustomerGroupCustomerEditPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: ShoplocatorModule
            }
        }
    },
    {
        path: SHOP_LOCATOR_CONTEXT_PATH + '/subsidiary/:subsidiary/customer/:customer/edit',
        name: 'ShopLocatorCustomerEditPage',
        component: () => import(/* webpackChunkName: "ShopLocatorCustomerEditPage" */ '../pages/shoplocator/CustomerEditPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: ShoplocatorModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH,
        name: 'AwmsOverviewPage',
        component: () => import(/* webpackChunkName: "AwmsOverviewPage" */ '../pages/awms/AwmsOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/channel',
        name: 'AwmsChannelOverviewPage',
        component: () => import(/* webpackChunkName: "AwmsChannelOverviewPage" */ '../pages/awms/channel/ChannelOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/channel/create',
        name: 'AwmsChannelCreatePage',
        component: () => import(/* webpackChunkName: "AwmsChannelCreatePage" */ '../pages/awms/channel/ChannelCreatePage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/channel/:channel/edit',
        name: 'AwmsChannelEditPage',
        component: () => import(/* webpackChunkName: "AwmsChannelEditPage" */ '../pages/awms/channel/ChannelEditPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo',
        name: 'AwmsTeoOverviewPage',
        component: () => import(/* webpackChunkName: "AwmsTeoOverviewPage" */ '../pages/awms/teo/TeoOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo/create',
        name: 'AwmsTeoCreatePage',
        component: () => import(/* webpackChunkName: "AwmsTeoCreatePage" */ '../pages/awms/teo/TeoCreatePage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo/:approvementListId/edit',
        name: 'AwmsTeoEditPage',
        component: () => import(/* webpackChunkName: "AwmsTeoEditPage" */ '../pages/awms/teo/TeoEditPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo/edit',
        name: 'AwmsTeoMultiEditPage',
        component: () => import(/* webpackChunkName: "AwmsTeoMultiEditPage" */ '../pages/awms/teo/TeoMultiEditPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo/:approvementListId/process',
        name: 'AwmsTeoProcessPage',
        component: () => import(/* webpackChunkName: "AwmsTeoProcessPage" */ '../pages/awms/teo/TeoProcessPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo/process',
        name: 'AwmsTeoMultiProcessPage',
        component: () => import(/* webpackChunkName: "AwmsTeoMultiProcessPage" */ '../pages/awms/teo/TeoMultiProcessPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo/:approvementListId/change-receiver',
        name: 'AwmsTeoChangeReceiverPage',
        component: () => import(/* webpackChunkName: "AwmsTeoChangeReceiverPage" */ '../pages/awms/teo/TeoChangeReceiverPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo/:approvementListId/review',
        name: 'AwmsTeoReviewPage',
        component: () => import(/* webpackChunkName: "AwmsTeoReviewPage" */ '../pages/awms/teo/TeoReviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo/review',
        name: 'AwmsTeoMultiReviewPage',
        component: () => import(/* webpackChunkName: "review" */ '../pages/awms/teo/TeoMultiReviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/teo/:approvementListId',
        name: 'AwmsTeoDetailPage',
        component: () => import(/* webpackChunkName: "AwmsTeoDetailPage" */ '../pages/awms/teo/TeoDetailPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/assortment/candidates',
        name: 'AssortmentCandidateOverviewPage',
        component: () => import(/* webpackChunkName: "AssortmentCandidateOverviewPage" */ '../pages/awms/assortment_candidate/AssortmentCandidateOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/assortment/candidates/:channel',
        name: 'AssortmentCandidateDetailPage',
        component: () => import(/* webpackChunkName: "AssortmentCandidateDetailPage" */ '../pages/awms/assortment_candidate/AssortmentCandidateDetailPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/assortment/planned',
        name: 'PlannedAssortmentOverviewPage',
        component: () => import(/* webpackChunkName: "PlannedAssortmentOverviewPage" */ '../pages/awms/planned_assortment/PlannedAssortmentOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/assortment/planned/:plannedAssortmentId',
        name: 'PlannedAssortmentDetailPage',
        component: () => import(/* webpackChunkName: "PlannedAssortmentDetailPage" */ '../pages/awms/planned_assortment/PlannedAssortmentDetailPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/assortment/current',
        name: 'CurrentAssortmentOverviewPage',
        component: () => import(/* webpackChunkName: "CurrentAssortmentOverviewPage" */ '../pages/awms/assortment/CurrentAssortmentOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/assortment/history',
        name: 'AssortmentHistoryOverviewPage',
        component: () => import(/* webpackChunkName: "AssortmentHistoryOverviewPage" */ '../pages/awms/assortment/AssortmentHistoryOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/assortment/:assortmentId',
        name: 'AssortmentDetailPage',
        component: () => import(/* webpackChunkName: "AssortmentDetailPage" */ '../pages/awms/assortment/AssortmentDetailPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/assortment/history/:assortmentId',
        name: 'AssortmentHistoryDetailPage',
        component: () => import(/* webpackChunkName: "AssortmentHistoryDetailPage" */ '../pages/awms/assortment/AssortmentHistoryDetailPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/quick-update',
        name: 'QuickUpdateOverviewPage',
        component: () => import(/* webpackChunkName: "QuickUpdateOverviewPage" */ '../pages/awms/quick_update/QuickUpdateOverviewPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/quick-update/create',
        name: 'QuickUpdateCreatePage',
        component: () => import(/* webpackChunkName: "QuickUpdateCreatePage" */ '../pages/awms/quick_update/QuickUpdateCreatePage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: AWMS_CONTEXT_PATH + '/quick-update/:approvementListId',
        name: 'QuickUpdateDetailPage',
        component: () => import(/* webpackChunkName: "QuickUpdateDetailPage" */ '../pages/awms/quick_update/QuickUpdateDetailPage.vue'),
        meta: {
            authenticationRequirements: {
                moduleAccess: AwmsModule,
                role: AssortmentManagerRole
            }
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import(/* webpackChunkName: "logout" */ '../pages/LogoutPage.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../pages/core/UserProfilePage.vue')
    },
    {
        path: '/error/*',
        name: 'error',
        component: () => import(/* webpackChunkName: "error" */ '../pages/ErrorPage.vue')
    },
    {
        path: '*',
        name: 'page-not-found',
        component: () => import(/* webpackChunkName: "page-not-found" */ '../pages/PageNotFoundPage.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let redirect: string | null = null

    for (const record of to.matched) {
        if (!satisfiesRequirements(record.meta as RouteMetaData)) {
            redirect = '/error/403'
        }
    }

    redirect ? next(redirect) : next()
})

function satisfiesRequirements (routeMetadata: RouteMetaData): boolean {
    if (routeMetadata === undefined || routeMetadata === null) return true

    const authRequirements = routeMetadata.authenticationRequirements
    if (authRequirements === undefined || authRequirements === null) return true
    if (!authRequirements.role && !authRequirements.moduleAccess) return true

    const userProfile = AuthenticationState.getInstance().userProfile
    if (userProfile == null) {
        return false
    }

    return userRoleSatisfiesRequirements(userProfile.role, authRequirements.role) &&
      (userProfile.isAdmin || userModuleAccessesSatisfiesRequirements(userProfile.moduleAccesses, authRequirements.moduleAccess))
}

function userRoleSatisfiesRequirements (userRole: string | null, requiredRole? : string) {
    if (requiredRole === undefined) return true
    if (userRole === null) return false

    return roleToOrdinal(userRole) >= roleToOrdinal(requiredRole)
}

function userModuleAccessesSatisfiesRequirements (userModuleAccesses: string[] | null, requiredModuleAccess? : string): boolean {
    if (requiredModuleAccess === undefined || requiredModuleAccess === null) return true
    if (userModuleAccesses === undefined || userModuleAccesses === null || userModuleAccesses.length === 0) return false

    return userModuleAccesses.indexOf(requiredModuleAccess) >= 0
}

export default router

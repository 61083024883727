import { AbstractSearchAndFilterableListSettingsImpl, ListFilterSettings } from '@/types/ListSettingsTypes'
import { EPlannedAssortmentType, PlannedAssortmentOverview } from '@/types/awms/planned_assortments/PlannedAssortmentTypes'
import { ChannelName } from '@/types/awms/channel/ChannelTypes'

class PlannedAssortmentOverviewReleasedListSettings extends AbstractSearchAndFilterableListSettingsImpl {
  private _plannedAssortmentFilter :PlannedAssortmentOverviewReleasedFilter
  private _channelFilterValues :ChannelName[]
  private _releasedPlannedAssortments: PlannedAssortmentOverview[]

  constructor () {
      super({
          sortBy: ['executeAt'],
          sortDesc: [true]
      })

      this._channelFilterValues = []
      this._releasedPlannedAssortments = []

      this._plannedAssortmentFilter = {
          channelFilter: null,
          typeFilter: null
      }
  }

  get channelFilterValues (): ChannelName[] {
      return this._channelFilterValues
  }

  set channelFilterValues (value: ChannelName[]) {
      this._channelFilterValues = value
  }

  get releasedPlannedAssortments (): PlannedAssortmentOverview[] {
      return this._releasedPlannedAssortments
  }

  set releasedPlannedAssortments (value: PlannedAssortmentOverview[]) {
      this._releasedPlannedAssortments = value
  }

  get plannedAssortmentFilter () : PlannedAssortmentOverviewReleasedFilter {
      return this._plannedAssortmentFilter
  }

  get hasFilters () : boolean {
      return !this.hasNoTypeFilterSet || !this.hasNoChannelFilterSet
  }

  get hasNoTypeFilterSet () : boolean {
      return this._plannedAssortmentFilter.typeFilter === null
  }

  get hasNoChannelFilterSet () : boolean {
      return this._plannedAssortmentFilter.channelFilter === null
  }

  clearFilters (): void {
      this._plannedAssortmentFilter = {
          channelFilter: null,
          typeFilter: null
      }
  }
}

class PlannedAssortmentOverviewOutstandingListSettings extends AbstractSearchAndFilterableListSettingsImpl {
  private _plannedAssortmentFilter :PlannedAssortmentOverviewOutstandingFilter
  private _channelFilterValues :ChannelName[]
  private _outstandingPlannedAssortments: PlannedAssortmentOverview[]
  constructor () {
      super({
          sortBy: ['createdAt'],
          sortDesc: [true]
      })

      this._channelFilterValues = []
      this._outstandingPlannedAssortments = []

      this._plannedAssortmentFilter = {
          channelFilter: null
      }
  }

  get channelFilterValues (): ChannelName[] {
      return this._channelFilterValues
  }

  set channelFilterValues (value: ChannelName[]) {
      this._channelFilterValues = value
  }

  get outstandingPlannedAssortments (): PlannedAssortmentOverview[] {
      return this._outstandingPlannedAssortments
  }

  set outstandingPlannedAssortments (value: PlannedAssortmentOverview[]) {
      this._outstandingPlannedAssortments = value
  }

  get plannedAssortmentFilter () : PlannedAssortmentOverviewOutstandingFilter {
      return this._plannedAssortmentFilter
  }

  get hasFilters () : boolean {
      return !this.hasNoChannelFilterSet
  }

  get hasNoChannelFilterSet () : boolean {
      return this._plannedAssortmentFilter.channelFilter === null
  }

  clearFilters (): void {
      this._plannedAssortmentFilter = {
          channelFilter: null
      }
  }
}

interface PlannedAssortmentOverviewReleasedFilter {
  channelFilter :string| null
  typeFilter: EPlannedAssortmentType| null
}

interface PlannedAssortmentOverviewOutstandingFilter {
  channelFilter :string| null
}

export { PlannedAssortmentOverviewReleasedListSettings, PlannedAssortmentOverviewReleasedFilter, PlannedAssortmentOverviewOutstandingFilter, PlannedAssortmentOverviewOutstandingListSettings }

import { AbstractCreateModeState, CreateModeState } from '@/types/CreateModeTypes'
import { ChannelWithAssignedUsers } from '@/types/awms/channel/ChannelTypes'
import { TeoEventService } from '@/services/TeoEventService'
import { CreateTeoModel, PersistenceCreateTeoModel, PersistencePotentialListEntryWithComment, TeoRelevantArticle } from '@/types/awms/approvement_list/teo/TeoTypes'
import { TeoRelevantArticleListSettings } from '@/types/awms/approvement_list/teo/TeoListSettingsTypes'
import { User } from '@/types/awms/UserTypes'
import TeoCreateWizardSelectReceiverComponent from '@/components/awms/teo/create/TeoCreateWizardSelectReceiverComponent.vue'

class TeoCreateModeState extends AbstractCreateModeState implements CreateModeState {
  private _createTeoModel : CreateTeoModel | null
  private _channels : ChannelWithAssignedUsers[]
  private readonly _teoEventService: TeoEventService
  private _listSettings: TeoRelevantArticleListSettings | null

  constructor () {
      super()
      this._createTeoModel = null
      this._channels = []
      this._teoEventService = TeoEventService.getInstance()
      this._listSettings = null
  }

  get createTeoModel (): CreateTeoModel | null {
      return this._createTeoModel
  }

  initialize () {
      this._createTeoModel = {
          selectedChannels: [{
              channel: null,
              teoReceivers: [],
              assortmentManagers: [],
              errorChannel: false,
              errorChannelMessage: null,
              errorAssortmentManager: false,
              errorAssortmentManagerMessage: null,
              errorTeoReceiver: false,
              errorTeoReceiverMessage: null
          }],
          articles: [],
          relevantArticles: [],
          notRelevantArticles: []
      }
      this._listSettings = new TeoRelevantArticleListSettings()
      this.originalItemState = this.getCurrentItemState()
  }

  get createTeoModelForPersistence (): PersistenceCreateTeoModel[] | null {
      if (this._createTeoModel === null) return null

      const approvementLists: PersistenceCreateTeoModel[] = []

      for (const channel of this._createTeoModel.selectedChannels) {
          const approvementList = {
              channelId: channel!.channel!.id,
              potentialListEntries: this._createTeoModel.relevantArticles
                  .filter(article => article.channelId === channel.channel!.id)
                  .map(article => this.createPersistencePotentialListEntryWithComment(article)),
              ignoredPotentialListEntries: this._createTeoModel.notRelevantArticles
                  .filter(article => article.channelId === channel.channel!.id)
                  .map(article => this.createPersistencePotentialListEntryWithComment(article)),
              assortmentManagerIds: channel.assortmentManagers.map(value => value.id),
              teoReceiverIds: channel.teoReceivers.map(value => value.id)
          }

          approvementLists.push(approvementList)
      }

      return approvementLists
  }

  createPersistencePotentialListEntryWithComment (article: TeoRelevantArticle) : PersistencePotentialListEntryWithComment {
      return {
          id: article.id,
          comment: article.comment
      }
  }

  set channels (value: ChannelWithAssignedUsers[]) {
      this._channels = value
  }

  get channels () : ChannelWithAssignedUsers[] {
      return this._channels
  }

  get teoEventService () {
      return this._teoEventService
  }

  clearState () {
      super.clearState()
      this._createTeoModel = null
      this._channels = []
      this._listSettings = null
  }

  get listSettings (): TeoRelevantArticleListSettings | null {
      return this._listSettings
  }

  protected getCurrentItemState (): string | null {
      return this._createTeoModel === null ? null : JSON.stringify(this._createTeoModel)
  }

  resetArticles () {
    this.createTeoModel!.articles = []
    this.listSettings!.serverItemsLengthUnknown = true
    this.listSettings!.serverItemsLength = 0
    this.listSettings!.dataTablePaginationOptions.page = 1
  }
}

export { TeoCreateModeState }

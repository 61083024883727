import Component, { mixins } from 'vue-class-component'

import ApplicationStateMixin from '@/components/mixins/ApplicationStateMixin'
import ApplicationServiceMixin from '@/components/mixins/ApplicationServiceMixin'
import RemoteServiceMixin from '@/components/mixins/RemoteServiceMixin'
import DisplayMixin from '@/components/mixins/DisplayMixin'

@Component
export default class BaseComponentMixin extends mixins(
    ApplicationStateMixin,
    ApplicationServiceMixin,
    RemoteServiceMixin,
    DisplayMixin
) {
}

import { EventService } from '@/services/EventService'
import { NavigationTypes } from '@/types/NavigationTypes'
import { NavigationState } from '@/states/NavigationState'
import { AuthenticationState } from '@/states/AuthenticationState'

import { clearArray } from '@/utils/ArrayUtil'
import {
    AUTHENTICATED_EVENT,
    AUTHENTICATION_INVALIDATED_EVENT
} from '@/definitions/EventDefinitions'
import {
    HomeNavigationItem,
    HelpNavigationItem,
    LogoutNavigationItem,
    PermissionsNavigationItem,
    ShopLocatorNavigationItem,
    SubsidiariesNavigationItem,
    AwmsNavigationItem, AwmsChannelItem, AwmsAssortmentItem, AwmsTeoItem, AwmsQuickUpdateItem, AwmsAssortmentCandidateItem, AwmsPlannedAssortmentItem
} from '@/definitions/NavigationItemDefinitions'

class NavigationItemProvider {
  private static _instance: NavigationItemProvider;

  private constructor () {
      EventService.getInstance().addListener(AUTHENTICATED_EVENT, () => {
          this.onAuthenticated()
      })
      EventService.getInstance().addListener(
          AUTHENTICATION_INVALIDATED_EVENT,
          () => {
              this.onAuthenticationInvalidated()
          }
      )
  }

  static getInstance (): NavigationItemProvider {
      if (!NavigationItemProvider._instance) {
          NavigationItemProvider._instance = new NavigationItemProvider()
      }

      return NavigationItemProvider._instance
  }

  initialize (): Promise<any> {
      return new Promise<any>(resolve => resolve())
  }

  private get menuNavigationItems (): NavigationTypes[] {
      return NavigationState.getInstance().menuNavigationItems
  }

  private get dashboardNavigationItems (): NavigationTypes[] {
      return NavigationState.getInstance().dashboardNavigationItems
  }

  private get awmsNavigationItems (): NavigationTypes[] {
      return NavigationState.getInstance().awmsNavigationItems
  }

  private onAuthenticated (): void {
      this.updateNavigationItems()
  }

  private onAuthenticationInvalidated (): void {
      this.updateNavigationItems()
  }

  private get authenticationState (): AuthenticationState {
      return AuthenticationState.getInstance()
  }

  private updateNavigationItems (): void {
      const isAuthenticated = this.authenticationState.isAuthenticated()

      const menuNavigationItems = this.menuNavigationItems
      const dashboardNavigationItems = this.dashboardNavigationItems
      const awmsNavigationItems = this.awmsNavigationItems

      clearArray(menuNavigationItems)
      clearArray(dashboardNavigationItems)

      menuNavigationItems.push(HomeNavigationItem)

      if (isAuthenticated) {
          const userProfile = this.authenticationState.userProfile
          if (userProfile) {
              if (userProfile.canViewPermissions) {
                  menuNavigationItems.push(PermissionsNavigationItem)
                  dashboardNavigationItems.push(PermissionsNavigationItem)

                  menuNavigationItems.push(SubsidiariesNavigationItem)
                  dashboardNavigationItems.push(SubsidiariesNavigationItem)
              }

              if (userProfile.hasShopLocatorAccess) {
                  menuNavigationItems.push(ShopLocatorNavigationItem)
                  dashboardNavigationItems.push(ShopLocatorNavigationItem)
              }

              if (userProfile.hasAwmsAccess) {
                  menuNavigationItems.push(AwmsNavigationItem)
                  dashboardNavigationItems.push(AwmsNavigationItem)

                  awmsNavigationItems.push(HomeNavigationItem)
                  if (userProfile.isAssortmentManager || userProfile.isAdmin) {
                      awmsNavigationItems.push(AwmsChannelItem)
                  }
                  awmsNavigationItems.push(AwmsTeoItem)
                  if (userProfile.isAssortmentManager || userProfile.isAdmin) {
                      awmsNavigationItems.push(AwmsQuickUpdateItem)
                      awmsNavigationItems.push(AwmsAssortmentCandidateItem)
                      awmsNavigationItems.push(AwmsPlannedAssortmentItem)
                      awmsNavigationItems.push(AwmsAssortmentItem)
                  }
              }
          }
      }

      menuNavigationItems.push(HelpNavigationItem)
      dashboardNavigationItems.push(HelpNavigationItem)

      if (isAuthenticated) {
          menuNavigationItems.push(LogoutNavigationItem)
          dashboardNavigationItems.push(LogoutNavigationItem)
      }
  }
}

export { NavigationItemProvider }
